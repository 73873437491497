import {useMutation, useSubscription} from "@apollo/client";
import gql from "graphql-tag";
import React from 'react';
import * as Yup from 'yup';

import {
  useField,
} from 'formik';
import { Option, CommonProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';

export type TagSelectProps = CommonProps & {
  name: string;
}

export const tagSchema = Yup.string()
  .trim()
  .min(3, 'Dieser Tag ist zu kurz')
  .max(30, 'Dieser Tag ist zu lang')
  .matches(/^\w+$/, 'Es sind nur Buchstaben und Zahlen erlaubt')
;

const TagSelect: React.FC<TagSelectProps> = ({label, name}) => {
  const { data, loading, error } = useSubscription<{ tags: { id: number, name: string }[] }>(
    gql`
      subscription designTagsSubscription {
        tags: design_tag {
          id
          name
        }
      }
    `,
    { variables: {} }
  );
  const [insertTag] = useMutation<any>(gql`
    mutation insertTagMutation($name: String!) {
      tag: insert_design_tag_one(object: {name: $name}) {
        id
        name
      }
    }
  `);
  const [field, meta, helpers] = useField<number[]>(name);

  if (error) {
    return (
      <div>Ein Fehler ist aufgetreten :-(</div>
    );
  }
  if (loading || !data) {
    return (
      <div>Wird geladen...</div>
    );
  }

  const options = data.tags.map(({id, name}) => ({ value: id, label: name }));

  const handleCreate = async (name: string) => {
    const valid = await tagSchema.isValid(name);
    if (valid) {
      const { data: {tag} } = await insertTag({variables: {name}});
      console.log(tag, field.value);
      helpers.setValue([...field.value, tag.id]);
    }
  };

  return (
    <>
      <label>
        <div className='font-medium mb-2'>{label}</div>
        <CreatableSelect
          options={options}
          // getOptionLabel={({label}) => `#${label}`}
          name={field.name}
          value={options ? options.filter(option => field.value.indexOf(option.value) >= 0) : []}
          onChange={(options: Option[]): void => helpers.setValue(options ? options.map((item: Option) => item.value) : [])}
          onBlur={field.onBlur}
          isMulti={true}
          closeMenuOnSelect={false}
          onCreateOption={handleCreate}
        />
      </label>
      {meta.touched && meta.error ? (
        <div className="error text-red-500 text-sm">{meta.error}</div>
      ) : null}
    </>
  );
};

export default TagSelect;
