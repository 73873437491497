import React, {MouseEvent} from 'react';
// import {useLockBodyScroll} from 'react-use';
import {useSubscription} from "@apollo/client";
import {useToggle} from "react-use";
import {DesignPropertiesFormSchema} from "../../components/DesignPropertiesForm/DesignPropertiesForm";
import {DESIGN_SUBSCRIPTION, DesignSubscription} from "./subscriptions";
import ProductListItem from "../../components/ProductListItem/ProductListItem";
import {pxToCm} from "@oyoyo/shared/src/utils/conversions";
import OyButton from "@oyoyo/ui/src/components/OyButton/OyButton";
import {ReactComponent as IconSwapDesign} from "line-awesome/svg/exchange-alt-solid.svg";
import {ReactComponent as IconProperties} from "line-awesome/svg/tags-solid.svg";
import {ReactComponent as IconNote} from "line-awesome/svg/lightbulb.svg";
import {ReactComponent as IconTop} from "line-awesome/svg/angle-up-solid.svg";
import {ReactComponent as IconPreview} from "line-awesome/svg/eye.svg";
import UploadsOverlayContainer from "../Upload/UploadsOverlayContainer";
import {useDispatch} from "react-redux";
import {
  addSeatCushions,
  publishDesign,
  replaceDesignImage,
  requestPublishDesign,
  toggleDesign
} from "../../store/reducer/design";
import ProductCategoryJumpLinks from "../../components/ProductCategoryJumpLinks/ProductCategoryJumpLinks";
import {Link, useNavigation} from "react-navi";
import RequireRole, {DESIGN_MANAGER, DESIGNER} from "../../components/RequireRole/RequireRole";
import { useToasts } from 'react-toast-notifications';

// export const groupDesignConfigurations = (arr: any[]) => Object.assign({}, ...arr.map(item => ({[item.productVariant.product.category.name]: item})));

export interface DesignContainerProps {
  id: number,
}

export interface DesignConfigurationsProps {
  designId: number,
  designUuid: string,
  designConfigurations: any,
}

const DesignConfigurations: React.FC<DesignConfigurationsProps> = ({designId, designUuid, designConfigurations}) => {
  const navigation = useNavigation();
  if (navigation.getCurrentValue().url.hash && navigation.getCurrentValue().url.hash.indexOf('#dc') === 0) {
    setTimeout(() => {
      const item = document.getElementById(navigation.getCurrentValue().url.hash.replace('#', ''))
      if (item) {
        item.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
    }, 250)
  }
  const categoryIds = {};
  const grouped = designConfigurations.reduce((result: any, item: any) => {
    const categoryName = item.productVariant.product.category.name;
    if (typeof result[categoryName] === 'undefined') {
      result[categoryName] = [];
      categoryIds[categoryName] = item.productVariant.product.category.id;
    }
    result[categoryName].push(item);
    return result;
  }, {});
  return (
    <>
      <ProductCategoryJumpLinks categories={categoryIds}/>
      {Object.keys(grouped).map((categoryName) => {
        const items = grouped[categoryName];
        return (
          <div key={categoryName}>
            <h1 id={`c${categoryIds[categoryName]}`} className='text-2xl font-medium border-b border-gray-400 my-4 pb-2 flex items-center'>
              {categoryName}
              <a href='#top' className='hover:text-red-500 flex flex-1 justify-end'>
                <IconTop className='w-6 h-6 ml-2 fill-current' />
              </a>
            </h1>
            <div className='grid sm:grid-columns-2 md:grid-columns-3 lg:grid-columns-4 xl:grid-columns-5 grid-gap-4'>
            {items.map((designConfiguration: any, i: number) => (
              <ProductListItem key={i} designId={designId} designUuid={designUuid} designConfiguration={designConfiguration}/>
            ))}
            </div>
          </div>
        );
      })}
    </>
  );
};

const DesignContainer: React.FC<DesignContainerProps> = ({id}) => {
  const { data, loading, error } = useSubscription<DesignSubscription>(
    DESIGN_SUBSCRIPTION,
    { variables: { id } }
  );
  const [collapsed, toggleCollapsed] = useToggle(true);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  // useLockBodyScroll(!loading);

  if (error) {
    return (
      <div>Ein Fehler ist aufgetreten :-(</div>
    );
  }
  if (loading || !data) {
    return (
      <div>Wird geladen...</div>
    );
  }

  const toggleProduct = (id: number, active: boolean) => (event: MouseEvent) => {
    dispatch(toggleDesign(id, active))
  };

  const replaceImage = (imageId: number) => {
    dispatch(replaceDesignImage(id, imageId));
    toggleCollapsed();
  };

  const requestPublish = (id: number) => (event: MouseEvent) => {
    dispatch(requestPublishDesign(id));
    addToast('Wir werden deine eingereichten Produkte nun prüfen.', { appearance: 'success', autoDismiss: true });
  };
  const publish = (id: number) => (event: MouseEvent) => {
    dispatch(publishDesign(id))
    addToast('Die Produkte werden nun veröffentlicht.', { appearance: 'success', autoDismiss: true });
  };

  const seatCushionIds = data.design.designConfigurations.reduce((variantIds, dc) => {
    if (dc.productVariantId >= 36 && dc.productVariantId <= 42) {
      variantIds.push(dc.productVariantId);
    }
    return variantIds
  }, []);
  const addSeatCushionsDisabled = seatCushionIds.length === 7;
  const addSeatCushionsHandler = (id: number) => (event: MouseEvent) => {
    dispatch(addSeatCushions(id))
    addToast('Die Sitzkissen werden nun hinzugefügt.', { appearance: 'success', autoDismiss: true });
  };

  const designIsInvalid = !DesignPropertiesFormSchema.isValidSync(data?.design);
  const canSubmit = !!data?.design?.declineReason || !data?.design?.approvalRequestedAt
  const approvalDisabled = designIsInvalid || !canSubmit;

  return (
    <>
      <div className='w-2/3 m-auto border-2 border-gray-500 border-dashed p-4 grid grid-columns-2 grid-gap-4 items-center my-8'>
        <div className='flex flex-col items-center justify-center text-center fill-current mx-4'>
          <RequireRole role={DESIGN_MANAGER}>
            <h3 className='text-3xl font-medium'>{data?.design.user.name}</h3>
          </RequireRole>
          <RequireRole role={DESIGNER}>
            <h3 className='text-3xl font-medium'>Dein Design</h3>
          </RequireRole>
          <h3 className='text-lg leading-none'>"{data.design.name}"</h3>
          <div className='text-xs my-1'>{pxToCm(data.design.image.width).toLocaleString()} x {pxToCm(data.design.image.height).toLocaleString()} cm</div>
          {/*<div className='text-sm mb-4'>Doch nicht? Wähle einfach ein anderes Bild aus.</div>*/}
          <Link className='my-1' href={`/design/products/${id}/properties`}>
            <OyButton size='sm' color='blue' label={'Produkteigenschaften'} icon={<IconProperties className={'w-6 h-6 fill-current mr-2'} />}/>
          </Link>
          <Link className='my-1' href={`/design/products/${id}/product-preview`}>
            <OyButton size='sm' color='blue' label={'Produktvorschau'} icon={<IconPreview className={'w-6 h-6 fill-current mr-2'} />}/>
          </Link>
          <OyButton size='sm' color='blue' label={`Produkt ${data.design.isActive ? 'de' : ''}aktivieren`} icon={<IconPreview className={'w-6 h-6 fill-current mr-2'} />} onClick={toggleProduct(data.design.id, !data.design.isActive)} />
          <RequireRole role={DESIGNER}>
            <OyButton size='sm' className='my-1' label={approvalDisabled && !designIsInvalid ? 'Zur Prüfung eingereicht' : !!data?.design?.declineReason ? 'Erneut zur Prüfung einreichen': 'Zur Prüfung einreichen'} onClick={requestPublish(id)} disabled={approvalDisabled} />
          </RequireRole>
          <RequireRole role={DESIGN_MANAGER}>
            <OyButton size='sm' className='mt-1' color='purple' label={'Produkte veröffentlichen'} onClick={publish(id)} disabled={!approvalDisabled} />
            {!addSeatCushionsDisabled && (
              <OyButton size='sm' className='mt-1' color='purple' label={'Sitzkissen hinzufügen'} onClick={addSeatCushionsHandler(id)} disabled={addSeatCushionsDisabled} />
            )}
          </RequireRole>
          <div className='border-t-2 border-b-2 border-dotted py-2 border-green-500 text-gray-500 flex mt-6 items-center'>
            <div className='mr-2'>
              <IconNote className={'w-12 h-12'}/>
            </div>
            <div className='text-sm text-left flex-shrink'>
              Dein Design auf unseren Produkten! Unter Produktvorschau kannst du jedes Produkt noch einzeln bearbeiten.
            </div>
          </div>
        </div>
        <div className='relative menu'>
          <img className='w-full' src={`${process.env.REACT_APP_STORAGE_PREFIX}/designs/${data.design.image.path}/thumbnails/1024.square.jpg`} alt={data.design.image.filename}/>
          <div className='opacity-0 pointer-events-none submenu absolute bottom-0 w-full flex justify-center mb-2'>
            <OyButton color='red' label={'Bild ersetzen'} icon={<IconSwapDesign className={'w-4 h-4 fill-current mr-2'} />} onClick={toggleCollapsed}/>
          </div>
        </div>
      </div>
      <div id='top' className='text-4xl font-medium border-t-2 border-dotted border-gray-500 pt-8 mt-24'>Produktvorschau</div>
      {/*<div>*/}
      {/*  {data.design.designConfigurations.map((designConfiguration, i) => (*/}
      {/*    <div key={i}>*/}
      {/*      {designConfiguration.active ? 'active' : 'inactive'}*/}
      {/*      {designConfiguration.productVariant.product.name}*/}
      {/*      {designConfiguration.productVariant.name}*/}
      {/*      {designConfiguration.productVariant.width}*/}
      {/*      {designConfiguration.productVariant.height}*/}
      {/*      /!*{JSON.stringify(designConfiguration, null, 2)}*!/*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}
      <DesignConfigurations designId={id} designUuid={data.design.uuid} designConfigurations={data.design.designConfigurations}/>
      {/*<div>*/}
      {/*  {data.design.designConfigurations.map((designConfiguration, i) => (*/}
      {/*    <div key={i}>*/}
      {/*      {designConfiguration.active ? 'active' : 'inactive'}*/}
      {/*      {designConfiguration.productVariant.product.name}*/}
      {/*      {designConfiguration.productVariant.name}*/}
      {/*      {designConfiguration.productVariant.width}*/}
      {/*      {designConfiguration.productVariant.height}*/}
      {/*      /!*{JSON.stringify(designConfiguration, null, 2)}*!/*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}
      {collapsed || <UploadsOverlayContainer onClick={replaceImage}/>}
    </>
  );
};

// DesignContainer.defaultProps = {
// };

export default DesignContainer;
