import React, {MouseEvent} from 'react';
import {useSubscription} from "@apollo/client";
import {USERS_SUBSCRIPTION, UsersSubscription} from './subscriptions';
import {Link, useNavigation} from "react-navi";
import { format, parseISO } from 'date-fns';
import {ReactComponent as IconActions} from "line-awesome/svg/ellipsis-v-solid.svg";
import {useDispatch} from "react-redux";
import { motion } from "framer-motion";
import RequireRole, {DESIGN_MANAGER} from "../../components/RequireRole/RequireRole";
import clsx from "clsx";

const container = {
  // hidden: { opacity: 1 },
  visible: {
    // opacity: 1,
    transition: {
      // delay: 0.2,
      when: "beforeChildren",
      staggerChildren: 0.05
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

export interface UsersContainerProps {
}

const UsersContainer: React.FC<UsersContainerProps> = () => {
  const { data, loading, error } = useSubscription<UsersSubscription>(
    USERS_SUBSCRIPTION,
    { variables: { limit: 1000, offset: 0 } }
  );
  const dispatch = useDispatch();
  const navigation = useNavigation();

  if (error) {
    return (
      <div>Ein Fehler ist aufgetreten :-(</div>
    );
  }
  if (loading || !data) {
    return (
      <div>Wird geladen...</div>
    );
  }

  const impersonate = (email: string) => (event: MouseEvent) => {
    fetch(`${process.env.REACT_APP_API_NESTJS}/auth/impersonate`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Impersonate failed')
        }
        return response.text()
      })
      .then(token => {
        window.localStorage.actAccessToken = window.localStorage.getItem('accessToken');
        window.localStorage.accessToken = token;
        window.location.href = '/design/products';
      })
      .catch(e => console.error('Impersonate failed', e))
  };

  return (
    <RequireRole role={DESIGN_MANAGER}>
      <motion.div variants={container} initial="hidden" animate="visible" className='flex flex-col'>
        {data.users.map(({id, name, email, updatedAt, isActive}) => (
          <motion.div variants={item} className='break-all' key={id}>
            <div className={clsx('grid grid-columns-12 grid-gap-4 items-center hover:bg-gray-100 p-4', {'text-red-500': !isActive})}>
              <div className='col-span-9'>{name}</div>
              <div className='col-span-3 flex justify-between items-center'>
                {format(parseISO(updatedAt), 'yyyy-MM-dd HH:mm')}
                <div className='hover:bg-gray-200 p-1 rounded-full relative menu transition duration-200'>
                  <IconActions className='w-6 h-6'/>
                  <ul className='absolute right-0 flex flex-col z-50 bg-white opacity-0 pointer-events-none submenu break-normal whitespace-no-wrap shadow-lg border border-gray-200 text-black'>
                    <li className='hover:bg-gray-200 transition duration-200'><button className='w-full p-2 text-left' onClick={impersonate(email)}>Als dieser Benutzer anmelden</button></li>
                  </ul>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </RequireRole>
  );
};

// UsersContainer.defaultProps = {
// };

export default UsersContainer;
