import {ApolloClient, FetchPolicy, ApolloLink, HttpLink, split, from} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import {WebSocketLink} from "@apollo/client/link/ws";
import store from 'store/store/main';
import {logout} from "store/reducer/user";

const cache = new InMemoryCache({});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({ headers: {
      authorization: `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
      ...headers
    }}));
  return forward(operation);
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_GRAPHQL_HTTP,
  credentials: 'same-origin',
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_API_GRAPHQL_WEBSOCKET as string,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      console.warn('TODO can this be changed after login? maybe reconnect then?');
      return {
        headers: {
          'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
          // 'x-hasura-role': `designer`,
        },
      };
    },
  }
});

// console.log(wsLink);

const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
        if (message.indexOf('JWTExpired') !== -1) {
          store.dispatch(logout());
        }
      }
    );
  }

  if (networkError) {
    console.log(`[Network error]`, networkError);
    store.dispatch(logout());
  }
});

const link = from([
  errorLink,
  authLink,
  splitLink,
]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache' as FetchPolicy,
  },
  query: {
    fetchPolicy: 'no-cache' as FetchPolicy,
  },
};

export const client = new ApolloClient({
  assumeImmutableResults: true,
  link,
  cache,
  defaultOptions,
});
