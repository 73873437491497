import React from 'react';
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";
import PublishingUpdatedContainer from "../containers/Design/PublishingUpdatedContainer";

export interface PublishingUpdatedProps {
}

const PublishingUpdated: React.FC<PublishingUpdatedProps> = () => {
  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-01 bg-cover bg-fixed'}>
      <PublishingUpdatedContainer/>
    </OyContainer>
  );
};

// Publishing.defaultProps = {
// };

export default PublishingUpdated;
