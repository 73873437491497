import {Margins} from "@oyoyo/shared/src/types/DesignProduct";
import DesignProductSettings from "@oyoyo/shared/src/types/DesignProductSettings";
import {pxToMm} from "@oyoyo/shared/src/utils/conversions";
import {DesignConfig} from "./interfaces";

export const getShapeProps = (width: number, height: number, margins: Margins, design: DesignConfig, settings: DesignProductSettings) => {
  const shapeScale = settings.scale / 100;
  return {
    x: margins.left + (settings.left * width),
    y: margins.top + (settings.top * height),
    width: pxToMm(design.widthPx),
    height: pxToMm(design.heightPx),
    scaleX: shapeScale,
    scaleY: shapeScale,
    rotation: settings.rotation,
  };
};

export const getDetailAreaProps = (width: number, height: number, margins: Margins, detail: { left: number, top: number }) => {
  return {
    x: margins.left + (detail.left * width),
    y: margins.top + (detail.top * height),
    width: 200,
    height: 200,
  };
};

export const getCustomAreaProps = (width: number, height: number, margins: Margins, custom: { left: number, top: number, width: number, height: number }) => {
  return {
    x: margins.left + (custom.left * width),
    y: margins.top + (custom.top * height),
    width: custom.width,
    height: custom.height,
  };
};

export const arrowHandler = (direction: 'up' | 'down' | 'left' | 'right', settings: DesignProductSettings, onChange: any) => (event: KeyboardEvent) => {
  event.preventDefault();
  let field: 'top' | 'left' = 'top';
  let multiplier: number = 1;
  switch (direction) {
    case 'up':
      field = 'top';
      multiplier = -1;
      break;
    case 'down':
      field = 'top';
      multiplier = 1;
      break;
    case 'left':
      field = 'left';
      multiplier = -1;
      break;
    case 'right':
      field = 'left';
      multiplier = 1;
      break;
  }
  onChange({
    [field]: settings[field] + ((event.shiftKey ? 0.1 : 0.01) * multiplier),
  });
};
