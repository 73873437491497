import gql from "graphql-tag";

interface Design {
  id: number,
  uuid: string,
  name: string,
  isActive: boolean,
  user: {
    name: string,
  },
  image: {
    path: string,
    filename: string,
    width: number,
    height: number,
  },
  updatedAt: string,
  approvalRequestedAt: string,
  declineReason?: string,
  designConfigurations: any[],
  approved?: {
    aggregate: {
      count: number,
    }
  }
  unapproved?: {
    aggregate: {
      count: number,
    }
  }
}

export interface DesignsSubscription {
  designs: Design[],
}

export const DESIGNS_SUBSCRIPTION = gql`
  subscription designs($limit: Int, $offset: Int) {
    designs: design(limit: $limit, offset: $offset, where: {deletedAt: {_is_null: true}}, order_by: {userId: asc, updated_at: desc}) {
      id
      uuid
      isActive
      name
      image {
        path
        isPattern
      }
      user {
        name
      }
      approved: designConfigurations_aggregate(where: {is_active: {_eq: true}, approvedAt: {_is_null: false}}) {
        aggregate {
          count
        }
      }
      unapproved: designConfigurations_aggregate(where: {is_active: {_eq: true}, approvedAt: {_is_null: true}}) {
        aggregate {
          count
        }
      }
      updatedAt: updated_at
    }
  }
`;

export interface DesignSubscription {
  design: Design,
}

export const DESIGN_SUBSCRIPTION = gql`
  subscription design($id: Int!) {
    design: design_by_pk(id: $id) {
      id
      uuid
      name
      description
      themes {
        theme {
          name
        }
      }
      styles {
        style {
          name
        }
      }
      colors {
        color {
          name
        }
      }
      tags {
        tag {
          name
        }
      }
      isActive
      approvalRequestedAt
      declineReason
      image {
        path
        filename
        width
        height
        isPattern
      }
      user {
        name
      }
      designConfigurations(order_by: {productVariant: {product: {categoryId: asc}, id: asc}}) {
        id
        designId
        active: is_active
        renderedAt: rendered_at
        frontSettings {
          image {
            path
            width
            height
            isPattern
          }
        }
        productVariantId
        productVariant {
          name
          width
          height
          marginTop
          marginRight
          marginBottom
          marginLeft
          product {
            name
            category {
              id
              name
            }
          }
          productScenes(where: {productScene: {isDesignerPreview: {_eq: true}}}) {
            productScene {
              name
            }
          }
        }
      }
    }
  }
`;
