export const UPDATE_DESIGN_FILTER = 'UPDATE_DESIGN_FILTER';
export const RESET_DESIGN_FILTER = 'RESET_DESIGN_FILTER';

export interface DesignFilterState {
  active?: boolean,
  published?: boolean,
  declined?: boolean,
  name?: string,
  themes?: number[],
  styles?: number[],
  colors?: number[],
  designer?: string,
  uuid?: string,
}

interface UpdateAction {
  type: typeof UPDATE_DESIGN_FILTER,
  payload: DesignFilterState
}

interface ResetAction {
  type: typeof RESET_DESIGN_FILTER
}

export type DesignFilterActionTypes = UpdateAction | ResetAction
