export function mmToPt(mm: number): number {
  return mm * 2.8346456693;
}

export function cmToPt (cm: number): number {
  return cm * 28.346456693;
}

export function pxToCm (px: number): number {
  return px / 150 * 2.54;
}

export function pxToMm (px: number): number {
  return px / 15 * 2.54;
}

export function mmToPx (mm: number): number {
  return Math.ceil(mm * 15 / 2.54);
}

export function rgbToHex(r: number, g: number, b: number) {
  return ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}
