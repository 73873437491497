import React from 'react';
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";
import DesignPreviewContainer from "../containers/Design/DesignPreviewContainer";

export interface ProductPreviewProps {
  id: number,
}

const ProductPreview: React.FC<ProductPreviewProps> = ({id}) => {
  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-01 bg-cover bg-fixed'}>
      <DesignPreviewContainer id={id}/>
    </OyContainer>
  );
};

// ProductPreview.defaultProps = {
// };

export default ProductPreview;
