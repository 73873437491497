import React from 'react';
import {ReactComponent as IconProductPillow} from "../../images/iconset/product_pillow.svg";
import {ReactComponent as IconProductDecoCurtain} from "../../images/iconset/product_deco-curtain.svg";
import {ReactComponent as IconProductSurfaceCurtain} from "../../images/iconset/product_surface-curtain.svg";
import {ReactComponent as IconProductRolls} from "../../images/iconset/product_rolls.svg";
import {ReactComponent as IconProductTableCloth} from "../../images/iconset/product_tablecloth.svg";
import {ReactComponent as IconProductTableRunner} from "../../images/iconset/product_table-runner.svg";
import {ReactComponent as IconProductPlacemat} from "../../images/iconset/product_placemat.svg";
import {ReactComponent as IconProductNapkin} from "../../images/iconset/product_napkin.svg";
import {ReactComponent as IconProductDishTowels} from "../../images/iconset/product_dishtowels.svg";
import {ReactComponent as IconProductBedLinen} from "../../images/iconset/product_bed-linen.svg";
import {ReactComponent as IconProductBedRunner} from "../../images/iconset/product_bed-runner.svg";
import {ReactComponent as IconProductTextilePoster} from "../../images/iconset/textilposter.svg";
import {ReactComponent as IconProductFabric} from "../../images/iconset/meterware.svg";
import {ReactComponent as IconProductSeatCushion} from "../../images/iconset/product_sitzkissen_icon.svg";
import {ReactComponent as IconProductOutdoor} from "../../images/iconset/eco.svg";
import {ReactComponent as IconProductUnknown} from "feather-icons/dist/icons/x.svg";

export interface ProductCategoryJumpLinksProps {
  categories: {
    [key: string]: string
  },
}

const IconMap: {[key: string]: any} = {
  '1': IconProductPillow,
  '2': IconProductDecoCurtain,
  '3': IconProductSurfaceCurtain,
  '4': IconProductRolls,
  '5': IconProductTableCloth,
  '6': IconProductTableRunner,
  '7': IconProductPlacemat,
  '8': IconProductNapkin,
  '9': IconProductDishTowels,
  '10': IconProductBedLinen,
  '11': IconProductBedRunner,
  '12': IconProductTextilePoster,
  '13': IconProductFabric,
  '14': IconProductSeatCushion,
  '15': IconProductOutdoor,
};

const CategoryIcon: React.FC<{categoryId: string}> = ({categoryId}) => {
  const Icon = IconMap[categoryId] || IconProductUnknown;
  return (
    <Icon className='w-6 h-6 mr-2 fill-current'/>
  );
};

const ProductCategoryJumpLinks: React.FC<ProductCategoryJumpLinksProps> = ({categories}) => {
  return (
    <ul className='grid grid-columns-4 grid-gap-4 mt-4 mb-8'>
      {Object.keys(categories).map((name) => (
        <li key={`c${categories[name]}`}>
          <a href={`#c${categories[name]}`} className='flex items-center hover:text-red-500'>
            <CategoryIcon categoryId={categories[name]} />
            {name}
          </a>
        </li>
      ))}
    </ul>
  );
};

ProductCategoryJumpLinks.defaultProps = {
};

export default ProductCategoryJumpLinks;
