const isBrowser = typeof window !== `undefined`;

interface User {
  email: string,
}

const getUser = () =>
  window.localStorage.currentUser
    ? JSON.parse(window.localStorage.currentUser)
    : {};

const setUser = (user: User) => (window.localStorage.currentUser = JSON.stringify(user));

// @ts-ignore
export const handleLogin = async ({ username, password }) => {
  if (!isBrowser) return false;

  return fetch(`${process.env.REACT_APP_API_NESTJS}/auth/login`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({username, password})
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Login failed')
      }
      return response.text()
    })
    .then(token => {
      window.localStorage.accessToken = token;
      return token;
      // TODO fetch user
      // TODO call react hook?!
    })
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;

  return !!window.localStorage.accessToken; // TODO

  const user = getUser();

  return !!user.email
};

export const getCurrentUser = () => isBrowser && getUser();

export const logout = (callback: () => void) => {
  if (!isBrowser) return;

  console.log(`Ensuring the \`currentUser\` property exists.`);
  setUser({email: ''});
  callback()
};
