import React from 'react';
import DesignContainer from "../containers/Design/DesignContainer";
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";

export interface ProductDetailsProps {
  id: number,
}

const ProductDetails: React.FC<ProductDetailsProps> = ({id}) => {
  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-01 bg-cover bg-fixed'}>
      <DesignContainer id={id}/>
    </OyContainer>
  );
};

// ProductDetails.defaultProps = {
// };

export default ProductDetails;
