export interface LoginPayload {
  username: string,
  password: string,
}

export interface UserState {
  accessToken: string,
  id: number,
  email: string,
  name: string,
}

const initialState: UserState = {
  accessToken: '',
  id: 0,
  email: '',
  name: '',
};

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const REQUEST_PROFILE = 'REQUEST_PROFILE';
export const RECEIVE_PROFILE = 'RECEIVE_PROFILE';

export interface LoginAction {
  type: typeof LOGIN,
  payload: LoginPayload,
}

export interface LogoutAction {
  type: typeof LOGOUT,
  payload: undefined,
}

export interface SetAccessTokenAction {
  type: typeof SET_ACCESS_TOKEN,
  payload: string,
}

export interface RequestProfileAction {
  type: typeof REQUEST_PROFILE,
  payload: undefined,
}

export interface ReceiveProfileAction {
  type: typeof RECEIVE_PROFILE,
  payload: UserState,
}

export type UserActionTypes = LoginAction | LogoutAction | SetAccessTokenAction | RequestProfileAction | ReceiveProfileAction;

export const login = (username: string, password: string): UserActionTypes => {
  return {
    type: LOGIN,
    payload: {
      username,
      password
    },
  }
};

export const logout = (): UserActionTypes => {
  return {
    type: LOGOUT,
    payload: undefined,
  }
};

export const setAccessToken = (token: string): UserActionTypes => {
  return {
    type: SET_ACCESS_TOKEN,
    payload: token,
  }
};

export const requestProfile = (): UserActionTypes => {
  return {
    type: REQUEST_PROFILE,
    payload: undefined,
  }
};

export const receiveProfile = (profile: UserState): UserActionTypes => {
  return {
    type: RECEIVE_PROFILE,
    payload: profile,
  }
};

const user = (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case LOGIN:
      return {
        ...initialState,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case REQUEST_PROFILE:
      return {
        ...state,
      };
    case RECEIVE_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state
  }
};

export default user
