import React from 'react';
import {compose, mount, route, withView} from 'navi';
import { View } from 'react-navi';
import Homepage from "./pages/homepage";
import Login from "./pages/login";
import Logout from "./pages/logout";
import ForgotPassword from "./pages/forgotpassword";
import Publishing from "./pages/publishing";
import PublishingUpdated from "./pages/publishingupdated";
import ResetPassword from "./pages/resetpassword";
import Uploads from "./pages/uploads";
// import UploadDetails from "./pages/uploaddetails";
import Products from "./pages/products";
import ProductDetails from "./pages/productdetails";
import ProductVariant from "./pages/productvariant";
import UploadDashboard from "./components/Upload/UploadDashboard";
import ProductProperties from "./pages/productproperties";
import ProductPreview from "./pages/productpreview";
import Faq from "./pages/faq";
import Users from "./pages/users";

export const routes =
  mount({
    '/': route({
      title: 'textilwerk.io designer',
      view: <Homepage/>,
    }),
    '/login': route({
      title: 'login | textilwerk.io designer',
      view: <Login/>,
    }),
    '/logout': route({
      title: 'logout | textilwerk.io designer',
      view: <Logout/>,
    }),
    '/forgot-password': route({
      title: 'forgot password | textilwerk.io designer',
      view: <ForgotPassword/>,
    }),
    '/reset-password/:token': route({
      title: 'reset password | textilwerk.io designer',
      async getView(request) {
        return <ResetPassword token={request.params.token}/>
      },
    }),
    '/design': mount({
      '/uploads': compose(
        withView(async (req, context) => {
          return (
            <Uploads>
              <section>
                <View />
              </section>
            </Uploads>
          );
        }),
        mount({
          '/': route({
            title: 'uploads | textilwerk.io designer',
            view: <div/>,
          }),
          '/new': route({
            title: 'new upload | textilwerk.io designer',
            view: <UploadDashboard/>,
          }),
          // '/:uploadId': route({
          //   async getView(request) {
          //     return <UploadDetails id={parseInt(request.params.uploadId, 10)}/>
          //   }
          // }),
        })
      ),
      '/products': mount({
        '/': route({
          title: 'products | textilwerk.io designer',
          view: <Products/>,
        }),
        // '/new': route({
        //   title: 'new products | oyoyo designer',
        //   view: <div>new prod</div>,
        // }),
        '/:productId': mount({
          '/overview': route({
            async getView(request) {
              return <ProductDetails id={parseInt(request.params.productId, 10)}/>
            }
          }),
          '/properties': route({
            async getView(request) {
              return <ProductProperties id={parseInt(request.params.productId, 10)}/>
            }
          }),
          '/product-preview': route({
            async getView(request) {
              return <ProductPreview id={parseInt(request.params.productId, 10)}/>
            }
          }),
          '/edit/:variantId': route({
            async getView(request) {
              return <ProductVariant id={parseInt(request.params.productId, 10)} variantId={parseInt(request.params.variantId, 10)}/>
            }
          }),
        }),
      }),
      '/faq': route({
        title: 'FAQ | textilwerk.io designer',
        view: <Faq/>,
      }),
      '/users': route({
        title: 'Designerverwaltung | textilwerk.io designer',
        view: <Users/>,
      }),
      '/publish': route({
        title: 'Freigabe | textilwerk.io designer',
        view: <Publishing/>,
      }),
      '/publish/updated': route({
        title: 'Freigabe geänderter Designs | textilwerk.io designer',
        view: <PublishingUpdated/>,
      }),
    }),
  })
;
