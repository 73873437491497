import {AnyAction, Dispatch, Middleware, MiddlewareAPI} from "redux";
import gql from 'graphql-tag';
import { client } from "api/hasura";
import {
  CREATE_DESIGN,
  RENDER_DESIGN,
  DesignActionTypes,
  RECEIVE_DESIGN_CONFIGURATION_SETTINGS,
  receiveConfigurationSettings,
  REQUEST_DESIGN_CONFIGURATION_SETTINGS,
  requestConfigurationSettings,
  REVERT_DESIGN_CONFIGURATION,
  SAVE_DESIGN_CONFIGURATION,
  SET_DESIGN_IMAGE,
  SET_DESIGN_CONFIGURATION_IMAGE,
  TOGGLE_DESIGN_CONFIGURATION,
  APPROVE_DESIGN_CONFIGURATION,
  PUBLISH_DESIGN,
  REQUEST_PUBLISH_DESIGN,
  TOGGLE_DESIGN,
  DELETE_DESIGN, DECLINE_DESIGN, TOGGLE_DESIGN_IMAGE_PATTERN, DELETE_DESIGN_IMAGE, ADD_SEAT_CUSHIONS
} from "../reducer/design";
import {reset, setSide} from "../../containers/DesignCanvas/actions";
import DesignProductSettings from "@oyoyo/shared/src/types/DesignProductSettings";

// const createDesignMutation = gql`
//   mutation createDesign($name: String!, $imageId: Int!) {
//     design: insert_design(objects: {name: $name, imageId: $imageId}) {
//       returning {
//         id
//         uuid
//       }
//     }
//   }
// `;

const toggleDesignImagePatternMutation = gql`
  mutation toggleDesignImagePatternMutation($id: Int!, $isPattern: Boolean!) {
    designImage: update_design_image_by_pk(pk_columns: {id: $id}, _set: {isPattern: $isPattern, updated_at: "now()"}) {
      isPattern
      updated_at
    }
  }
`;

const toggleDesignConfigurationMutation = gql`
  mutation toggleDesignConfiguration($id: Int!, $active: Boolean!, $designId: Int!) {
    designConfiguration: update_design_configuration(where: {id: {_eq: $id}}, _set: {is_active: $active, updated_at: "now()"}) {
      returning {
        is_active
        updated_at
      }
    }
    design: update_design(where: {id: {_eq: $designId}}, _set: {updated_at: "now()"}) {
      returning {
        updated_at
      }
    }
  }
`;

const getDesignConfigurationSettings = gql`
  query designConfigurationSettings($id: Int!) {
    designConfiguration: design_configuration_by_pk(id: $id) {
      id
      detailLeft
      detailTop
      customizableLeft
      customizableTop
      customizableWidth
      customizableHeight
      design {
        uuid
        isCustomizable
        groupName
        defaultText
      }
      productVariant {
        id
        name
        width
        height
        marginTop
        marginRight
        marginBottom
        marginLeft
        product {
          hasBackside: has_backside
        }
        productScenes(where: {productScene: {isDesignerPreview: {_eq: true}}}) {
          productScene {
            name
          }
        }
      }
      frontSettings {
        id
        left
        top
        rotation
        scale
        backgroundColor: background_color
        image {
          path
          filename
          width
          height
          isPattern
        }
      }
      backSettings {
        id
        left
        top
        rotation
        scale
        backgroundColor: background_color
        image {
          path
          filename
          width
          height
          isPattern
        }
      }
    }
  }
`;

const copyDesignSettings = ({ design }: any) => {
  return reset(design.configuration.frontSettings, design.configuration.backSettings, { left: design.configuration.detailLeft, top: design.configuration.detailTop }, { left: design.configuration.customizableLeft, top: design.configuration.customizableTop, width: design.configuration.customizableWidth, height: design.configuration.customizableHeight });
};

const designMiddleware: Middleware<Dispatch> = ({ getState, dispatch }: MiddlewareAPI) => (next: Dispatch) => async (action: AnyAction | DesignActionTypes) => {
  const result = next(action);
  switch (action.type) {
    case TOGGLE_DESIGN_IMAGE_PATTERN:
      console.log('TOGGLE_DESIGN_IMAGE_PATTERN', action.payload);
      await (async () => {
        const { data: { designImage: {isPattern, updated_at}} } = await client.mutate<any>({
          mutation: toggleDesignImagePatternMutation,
          variables: {
            id: action.payload.id,
            isPattern: action.payload.isPattern,
          }
        });
        console.log(isPattern, updated_at);
      })();
      break;
    case DELETE_DESIGN_IMAGE:
      console.log('DELETE_DESIGN_IMAGE', action.payload);
      await (async () => {
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/image/${action.payload}`, {
          method: 'DELETE',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Delete design image failed')
            }
            return response.json()
          })
          .then((json) => {
            console.log(json);
          })
          .catch(e => console.error('Delete design image failed', e))
        ;
      })();
      break;
    case CREATE_DESIGN:
      console.log('CREATE_DESIGN', action.payload);
      (() => {
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/image/${action.payload.id}/design`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({name: action.payload.name})
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Create design failed')
            }
            return response.json()
          })
          .then(({id, uuid}) => {
            console.log(id, uuid);
            action.payload.navigation.navigate(`/design/products/${id}/overview`);
          })
          .catch(e => console.error('Create design failed', e))
        ;
      })();
      // TODO move to nestjs API request
      // // @ts-ignore
      // const { data: { design: {returning: [{id, uuid}]}} } = await client.mutate({
      //   mutation: createDesignMutation,
      //   variables: {
      //     name: action.payload.name,
      //     imageId: action.payload.id,
      //   }
      // });
      // console.log(id, uuid);
      break;
    case RENDER_DESIGN:
      console.log('RENDER_DESIGN', action.payload);
      (() => {
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/design/${action.payload}/render`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            productVariants: []
          })
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Render design failed')
            }
            return response.json()
          })
          .then((json) => {
            console.log(json);
          })
          .catch(e => console.error('Render design failed', e))
        ;
      })();
      break;
    case REQUEST_PUBLISH_DESIGN:
      console.log('REQUEST_PUBLISH_DESIGN', action.payload);
      (() => {
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/design/${action.payload}/request-approval`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Request approval failed')
            }
            return response.json()
          })
          .then((json) => {
            console.log(json);
          })
          .catch(e => console.error('Request approval failed', e))
        ;
      })();
      break;
    case PUBLISH_DESIGN:
      console.log('PUBLISH_DESIGN', action.payload);
      (() => {
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/design/${action.payload}/publish`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Publish design failed')
            }
            return response.json()
          })
          .then((json) => {
            console.log(json);
          })
          .catch(e => console.error('Publish design failed', e))
        ;
      })();
      break;
    case ADD_SEAT_CUSHIONS:
      console.log('ADD_SEAT_CUSHIONS', action.payload);
      (() => {
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/design/${action.payload}/add-seat-cushions`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Adding seat cushions to design failed')
            }
            return response.json()
          })
          .then((json) => {
            console.log(json);
          })
          .catch(e => console.error('Adding seat cushions to design failed', e))
        ;
      })();
      break;
    case DECLINE_DESIGN:
      console.log('DECLINE_DESIGN', action.payload);
      (() => {
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/design/${action.payload.id}/decline`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            declineReason: action.payload.declineReason,
          })
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Decline design failed')
            }
            return response.json()
          })
          .then((json) => {
            console.log(json);
          })
          .catch(e => console.error('Decline design failed', e))
        ;
      })();
      break;
    case DELETE_DESIGN:
      console.log('DELETE_DESIGN', action.payload);
      (() => {
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/design/${action.payload.id}`, {
          method: 'DELETE',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Delete design failed')
            }
            return response.json()
          })
          .then((json) => {
            console.log(json);
          })
          .catch(e => console.error('Delete design failed', e))
        ;
      })();
      break;
    case TOGGLE_DESIGN:
      console.log('TOGGLE_DESIGN', action.payload);
      (() => {
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/design/${action.payload.id}/toggle`, {
          method: 'PATCH',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            active: action.payload.active,
          })
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Toggle design failed')
            }
            return response.json()
          })
          .then((json) => {
            console.log(json);
          })
          .catch(e => console.error('Toggle design failed', e))
        ;
      })();
      break;
    case TOGGLE_DESIGN_CONFIGURATION:
      console.log('TOGGLE_DESIGN_CONFIGURATION', action.payload);
      const { data: { designConfiguration: {returning: [{is_active, updated_at}]}} } = await client.mutate<any>({
        mutation: toggleDesignConfigurationMutation,
        variables: {
          id: action.payload.id,
          active: action.payload.active,
          designId: action.payload.designId,
        }
      });
      console.log(is_active, updated_at);
      break;
    case APPROVE_DESIGN_CONFIGURATION:
      console.log('APPROVE_DESIGN_CONFIGURATION', action.payload);
      (() => {
        const { id, approved } = action.payload;
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/configuration/${id}/approve`, {
          method: 'PATCH',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({approved})
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Approve configuration failed')
            }
            return response.json()
          })
          .then(({id}) => {
            console.log(id);
          })
          .catch(e => console.error('Approve configuration failed', e))
        ;
      })();
      break;
    case REQUEST_DESIGN_CONFIGURATION_SETTINGS:
      const { data: { designConfiguration: { id, detailLeft, detailTop, customizableLeft, customizableTop, customizableWidth, customizableHeight, design, productVariant, frontSettings, backSettings } }} = await client.query<any>({
        query: getDesignConfigurationSettings,
        variables: {
          id: action.payload,
        }
      });
      dispatch(receiveConfigurationSettings({
        id,
        detailLeft,
        detailTop,
        customizableLeft,
        customizableTop,
        customizableWidth,
        customizableHeight,
        design,
        productVariant,
        frontSettings,
        backSettings,
      }));
      break;
    case RECEIVE_DESIGN_CONFIGURATION_SETTINGS:
      dispatch(setSide('front'));
      dispatch(copyDesignSettings(getState()));
      break;
    case REVERT_DESIGN_CONFIGURATION:
      dispatch(copyDesignSettings(getState()));
      break;
    case SAVE_DESIGN_CONFIGURATION:
      console.log(SAVE_DESIGN_CONFIGURATION, action.payload);
      (() => {
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/configuration/${action.payload.designConfigurationId}`, {
          method: 'PATCH',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(action.payload)
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Update configuration failed')
            }
            return response.json()
          })
          .then(({updatedAt}) => {
            console.log(updatedAt);
          })
          .catch(e => console.error('Update configuration failed', e))
        ;
      })();
      break;
    case SET_DESIGN_IMAGE:
      (() => {
        const { designId, imageId } = action.payload;
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/design/${designId}/image/${imageId}`, {
          method: 'PATCH',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({imageId})
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Set image failed')
            }
            return response.json()
          })
          .then(({id, updatedAt}) => {
            console.log(id, updatedAt);
          })
          .catch(e => console.error('Set image failed', e))
        ;
      })();
      break;
    case SET_DESIGN_CONFIGURATION_IMAGE:
      (() => {
        const { design: { configuration: { id: configurationId } } } = getState();
        const { settingsId, imageId } = action.payload;
        fetch(`${process.env.REACT_APP_API_NESTJS}/designer/configuration-settings/${settingsId}/image/${imageId}`, {
          method: 'PATCH',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({imageId})
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Set image failed')
            }
            return response.json()
          })
          .then(({id, updatedAt}) => {
            console.log(id, updatedAt);
            dispatch(requestConfigurationSettings(configurationId)); // TODO this should NOT trigger setSide, maybe check if current setting id is same?
          })
          .catch(e => console.error('Set image failed', e))
        ;
      })();
      break;
  }
  return Promise.resolve(result);
};

export default designMiddleware;
