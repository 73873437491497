import React from 'react';
import { Link } from 'react-navi';
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";
import TwLogo from "@oyoyo/ui/src/components/TwLogo/TwLogo";
import UserContainer from "../User/UserContainer";
import NotificationsContainer from "../Notifications/NotificationsContainer";
import {UserState} from "../../store/reducer/user";
import {shallowEqual, useSelector} from "react-redux";
import {AppState} from "../../store/reducer/root";
import RequireRole, {DESIGN_MANAGER, DESIGNER} from "../../components/RequireRole/RequireRole";

export interface LayoutContainerProps {
}

// https://www.elastic.co/de/community/ menu style

const LayoutContainer: React.FC<LayoutContainerProps> = ({children}) => {
  const { email }: UserState = useSelector((state: AppState) => state.user, shallowEqual);
  return (
    <div>
      <OyContainer wrapperClassName='bg-white fixed top-0 w-full z-1500 shadow-lg'>
        <header className='flex items-center justify-between h-12'>
          <Link href='/' exact>
            <TwLogo className='w-20' />
          </Link>
          {email && (
            <>
              <ul className='flex flex-1 mx-8'>
                {/*<li><Link className='hover:bg-gray-200 p-2' activeClassName='bg-gray-200' href='/' exact>home</Link></li>*/}
                {/*<li><Link className='hover:bg-gray-200 p-2' href='/design'>design</Link></li>*/}
                <RequireRole role={DESIGNER}>
                  <li className='mx-2'><Link className='hover:bg-gray-200 rounded p-2 transition duration-200' activeClassName='bg-gray-200' href='/design/uploads'>Designs</Link></li>
                </RequireRole>
                {/*<li className='mx-2'><Link className='hover:bg-gray-200 rounded p-2 transition duration-200' activeClassName='bg-gray-200' href='/design/uploads/new' exact>Design hochladen</Link></li>*/}
                {/*<li><Link className='hover:bg-gray-200 p-2' activeClassName='bg-gray-200' href='/design/uploads/123' exact>up #123</Link></li>*/}
                <li className='mx-2'><Link className='hover:bg-gray-200 rounded p-2 transition duration-200' activeClassName='bg-gray-200' href='/design/products' exact>Produkte</Link></li>
                <RequireRole role={DESIGN_MANAGER}>
                  <li className='mx-2'><Link className='hover:bg-gray-200 rounded p-2 transition duration-200' activeClassName='bg-gray-200' href='/design/publish' exact>Freigabe</Link></li>
                  <li className='mx-2'><Link className='hover:bg-gray-200 rounded p-2 transition duration-200' activeClassName='bg-gray-200' href='/design/publish/updated' exact>Geänderte Designs</Link></li>
                  <li className='mx-2'><Link className='hover:bg-gray-200 rounded p-2 transition duration-200' activeClassName='bg-gray-200' href='/design/users' exact>Designerverwaltung</Link></li>
                </RequireRole>
                <li className='mx-2'><Link className='hover:bg-gray-200 rounded p-2 transition duration-200' activeClassName='bg-gray-200' href='/design/faq' exact>FAQ</Link></li>
                {/* TODO add latest 5 (inactive, draft) design products */}
                {/*<li><Link className='hover:bg-gray-200 p-2' activeClassName='bg-gray-200' href='/design/products/new' exact>new p</Link></li>*/}
                {/*<li><Link className='hover:bg-gray-200 p-2' activeClassName='bg-gray-200' href='/design/products/456/overview' exact>p #456 o</Link></li>*/}
                {/*<li><Link className='hover:bg-gray-200 p-2' activeClassName='bg-gray-200' href='/design/products/456/edit/789' exact>pd #789 edit</Link></li>*/}
              </ul>
              <div className='flex items-center text-sm'>
                <NotificationsContainer/>
                <UserContainer/>
              </div>
            </>
          )}
        </header>
      </OyContainer>
      <div className='pt-20 min-h-screen'>
        {children}
      </div>
      {/*<footer>oyoyo foot</footer>*/}
    </div>
  );
};

export default LayoutContainer;
