import { applyMiddleware, createStore, compose } from 'redux'
import rootReducer from 'store/reducer/root'
import userMiddleware from "../middleware/user";
import designMiddleware from "../middleware/design";
import {setAccessToken} from "../reducer/user";

const middlewares = [userMiddleware, designMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer];

// @ts-ignore
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  // @ts-ignore
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
}

const composedEnhancers = compose(...enhancers);

// @ts-ignore
const store = createStore(rootReducer, composedEnhancers);

// load last access token from local storage
// TODO use redux or global variable to read accessToken
if (window.localStorage.accessToken) {
  store.dispatch(setAccessToken(window.localStorage.accessToken));
}

export default store;
