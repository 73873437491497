import {DesignFilterActionTypes, DesignFilterState, RESET_DESIGN_FILTER, UPDATE_DESIGN_FILTER} from "./types";

export const updateFilter = (filter: DesignFilterState): DesignFilterActionTypes => {
  return {
    type: UPDATE_DESIGN_FILTER,
    payload: filter,
  }
};

export const resetFilter = (): DesignFilterActionTypes => {
  return {
    type: RESET_DESIGN_FILTER,
  }
};
