import isEqual from 'lodash-es/isEqual';

import {
  COPY_FROM,
  DEFAULTS,
  DesignCanvasActionTypes,
  DesignCanvasState,
  RESET,
  SET_DESIGN_SIDE,
  TOGGLE_LOCKED, UNDO_SIDE,
  UPDATE, UPDATE_DETAILS, UPDATE_CUSTOM
} from "./types";
import {getCoverRatio, getScaleFromRatio} from "@oyoyo/shared/src/utils/design";
import DesignProductSettings from "@oyoyo/shared/src/types/DesignProductSettings";

const initialState: DesignCanvasState = {
  side: 'front',
  detail: {
    left: 0.5,
    top: 0.5,
  },
  custom: {
    left: 0.5,
    top: 0.5,
    width: 300,
    height: 300,
  },
  front: {
    scale: 100,
    rotation: 0,
    top: 0.5,
    left: 0.5,
    backgroundColor: undefined,
  },
  back: {
    scale: 100,
    rotation: 0,
    top: 0.5,
    left: 0.5,
    backgroundColor: undefined,
  },
  locked: true,
  history: {
    front: [],
    back: [],
  }
};

// TODO locked does not respect the design image?!?!

const designCanvasReducer = (state = initialState, action: DesignCanvasActionTypes): DesignCanvasState => {
  switch (action.type) {
    case UPDATE:
      return (() => {
        const locked = state.locked;
        const { scale, ...payload} = action.payload;
        const settings = state[state.side];

        const newState = {
          ...state,
          [state.side]: {
            ...settings,
            ...payload,
          },
        };

        if (scale) {
          newState[state.side].scale = Math.min(Math.max(1, scale), 120);
        }

        if (locked) {
          newState['back'] = {...newState.front};
        }

        newState.history[state.side].push(newState[state.side]);

        return newState;
      })();
    case UPDATE_DETAILS:
      return (() => {
        return {
          ...state,
          detail: {
            ...action.payload
          },
        };
      })();
    case UPDATE_CUSTOM:
      return (() => {
        console.log(action)
        return {
          ...state,
          custom: {
            ...action.payload
          },
        };
      })();
    case DEFAULTS:
      // TODO should we reset the backside image, too?
      return (() => {
        const { product, image } = action.payload;
        const settings = state[state.side];
        const coverRatio = getCoverRatio(product, product.margins, image);
        if (coverRatio > 1) {
          console.error('Dieses Design ist zu klein für dieses Produkt.');
        }
        return {
          ...state,
          [state.side]: {
            ...settings,
            top: 0.5,
            left: 0.5,
            rotation: 0,
            scale: getScaleFromRatio(coverRatio),
            backgroundColor: undefined,
          },
        }
      })();
    case RESET:
      return {
        ...state,
        ...action.payload,
        locked: isEqual(action.payload.front, action.payload.back),
        history: {
          front: [action.payload.front],
          back: [action.payload.back as DesignProductSettings],
        },
      };
    case SET_DESIGN_SIDE:
      return {
        ...state,
        side: action.payload,
      };
    case UNDO_SIDE:
      const newState = {
        ...state,
      };
      newState.history[action.payload].pop();
      const [settings] = newState.history[action.payload].slice(-1);
      newState[action.payload] = {...settings};
      // const history = state.history[action.payload].slice(0, -1);
      // console.log(settings, history, state.history[action.payload]);
      return newState;
    case TOGGLE_LOCKED:
      return (() => {
        const locked = !state.locked;
        return {
          ...state,
          locked,
          side: locked ? 'front' : state.side,
          back: locked ? {...state.front} : {...state.back}
        };
      })();
    case COPY_FROM:
      return (() => {
        return {
          ...state,
          [state.side]: state.side === 'front' ? {...state.back} : {...state.front},
        };
      })();
    default:
      return state
  }
};

export default designCanvasReducer
