import gql from "graphql-tag";
import React, {MouseEvent, useRef, useState} from 'react';
import {useQuery, useSubscription} from "@apollo/client";
import {UPLOADS_QUERY, UPLOADS_SUBSCRIPTION, UploadsQuery, UploadsSubscription} from "./subscriptions";
import {Link} from "react-navi"
import {SimpleImg} from 'react-simple-img';
import {AnimatePresence, motion} from "framer-motion";
import {ReactComponent as IconDownload} from "line-awesome/svg/file-download-solid.svg";
import {useClickAway} from "react-use";
import {mmToPx, pxToMm} from "@oyoyo/shared/src/utils/conversions";
import UploadDetails from "../../pages/uploaddetails";
import UploadContainer from "./UploadContainer";
import UploadDashboard from "../../components/Upload/UploadDashboard";
import ClipLoader from "react-spinners/ClipLoader";
import clsx from "clsx";

export interface UploadsContainerProps {
}

const UploadsContainer: React.FC<UploadsContainerProps> = () => {
  // TODO pattern filter
  // const limit = 5000;
  const {data, error, loading} = useSubscription<UploadsSubscription>(
    UPLOADS_SUBSCRIPTION,
    {
      // variables: {limit, after: null},
    }
  );
  // const {data, error, loading, fetchMore} = useQuery<UploadsQuery>(
  //   UPLOADS_QUERY,
  //   {
  //     variables: {limit, after: null},
  //     notifyOnNetworkStatusChange: true,
  //   }
  // );
  const {data: productVariantSizes} = useQuery<any>(
    gql`
      query getProductVariantSizes {
        productVariants: product_variant {
          id
          width
          height
          marginTop
          marginRight
          marginBottom
          marginLeft
        }
      }
    `,
    {
      fetchPolicy: "cache-first",
    }
  );
  const productVariants = productVariantSizes?.productVariants;
  let maxSize;
  if (productVariants) {
    maxSize = productVariants.map(({width, height, marginTop, marginRight, marginBottom, marginLeft}) => {
      return {
        totalWidth: width + marginRight + marginLeft,
        totalHeight: height + marginTop + marginBottom,
      }
    }).reduce((result: any, item: any) => {
      if (item.totalWidth > result.maxWidth) {
        result.maxWidth = item.totalWidth;
      }
      if (item.totalHeight > result.maxHeight) {
        result.maxHeight = item.totalHeight;
      }
      return result;
    }, {maxWidth: 0, maxHeight: 0});
  }
  // console.log(maxSize, mmToPx(maxSize?.maxWidth), mmToPx(maxSize?.maxHeight), pxToMm(8622), pxToMm(19252));
  const [imageId, setImageId] = useState<number>(0);
  // const modalRef = useRef<HTMLDivElement | null>(null);
  // useClickAway(modalRef, () => setImageId(0));
  if (error) {
    return (
      <div>Ein Fehler ist aufgetreten :-(</div>
    );
  }
  if (loading || !data) {
    return (
      <div>Wird geladen...</div>
    );
  }
  // const hasMore = true;
  // const loadMore = () => {
  //   // TODO https://github.com/apollographql/react-apollo/issues/3333
  //   const [lastItem] = data.uploads.slice(-1);
  //   console.log(data.uploadsInfo.aggregate.count, lastItem);
  //   fetchMore({
  //     variables: {limit, after: lastItem.id},
  //     updateQuery: (prevResult, {fetchMoreResult}) => {
  //       console.log(prevResult, fetchMoreResult);
  //       if (typeof fetchMoreResult === 'undefined') {
  //         return prevResult;
  //       }
  //       fetchMoreResult.uploads = [
  //         ...prevResult.uploads,
  //         ...fetchMoreResult.uploads,
  //       ];
  //       return fetchMoreResult;
  //     },
  //   });
  // };
  // TODO useQuery with fetchMore https://www.youtube.com/watch?v=lNtQbn7qN-8 https://hasura.io/blog/cursor-offset-pagination-with-hasura-graphql/ https://docs.hasura.io/1.0/graphql/manual/queries/pagination.html
  // console.log('hier', data);
  const handleClick = (id) => (e: MouseEvent) => {
    setImageId(id);
  };
  return (
    <div>
      <div className='flex flex-col items-center'>
        <h1 className='text-xl font-medium text-center mt-4 mb-4'>Lade deine eigenen Designs hoch</h1>
        <div className='max-w-2xl mb-4'>Deine Designs solltest du als JPEG-Datei mit sRGB-Farbraum hochladen. Wir verwenden eine Auflösung von 150 DPI. Damit dein Design auf all unsere Produkte gedruckt werden kann, sollte die Datei mind. {mmToPx(maxSize?.maxWidth)}x{mmToPx(maxSize?.maxHeight)} Pixel groß sein.</div>
        <div className='bg-yellow-100 border border-yellow-300 text-yellow-600 p-2 mb-8'>
          <div className='font-medium'>Hier kannst du dir entsprechende Vorlagen herunterladen:</div>
          <a href={`${process.env.PUBLIC_URL}/templates/textilwerk-Vorlage_Maximale_Aufloesung.psd`} target='_blank' className='flex items-center hover:text-yellow-800'><IconDownload className='w-6 fill-current'/>Vorlage für Adobe Photoshop (.psd)</a>
          <a href={`${process.env.PUBLIC_URL}/templates/textilwerk_Vorlage_maximale_Aufloesung.eps`} target='_blank' className='flex items-center hover:text-yellow-800'><IconDownload className='w-6 fill-current'/>Vorlage für Adobe Illustrator (.eps)</a>
          <a href={`${process.env.PUBLIC_URL}/templates/textilwerk_Vorlage_maximale_Aufloesung.idml`} target='_blank' className='flex items-center hover:text-yellow-800'><IconDownload className='w-6 fill-current'/>Vorlage für Adobe InDesign (.idml)</a>
        </div>
        {/*<h1>{data.uploads.length} Designs</h1>*/}
        <UploadDashboard/>
      </div>
      <h1 className='text-3xl font-medium text-center my-8'>Deine {data.uploads.length} hochgeladenen Designs</h1>
      {/*<h1 className='text-3xl font-medium text-center my-8'>Deine {data.uploadsInfo.aggregate.count} hochgeladenen Designs</h1>*/}
      <div className='grid grid-columns-2 sm:grid-columns-3 md:grid-columns-4 lg:grid-columns-5 xl:grid-columns-6 grid-gap-4'>
        {/*{hasMore && (*/}
        {/*  <div>*/}
        {/*    <div className='aspect-ratio-square relative'>*/}
        {/*      <button onClick={loadMore} className='absolute inset-0 flex w-full items-center justify-center bg-white hover:bg-gray-100'>*/}
        {/*        Mehr...*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {data.uploads.map(({id, path, filename, filesize, mimetype, width, height, designs: { aggregate: {count}}}) => (
          <div className='break-all' key={id}>
            <button className='w-full' onClick={handleClick(id)}>
              {width ? (
                <div className={clsx('aspect-ratio-square relative bg-white hover:shadow-lg border', {'border-red-500': count < 1})}>
                  <SimpleImg className='absolute inset-0'
                             src={`${process.env.REACT_APP_STORAGE_PREFIX}/designs/${path}/thumbnails/320.square.jpg`}
                             placeholder={false}/>
                </div>
              ) : (
                <div className='aspect-ratio-square relative bg-white hover:shadow-lg border border-red-500'>
                  <div className='absolute inset-0 flex items-center justify-center'>
                    <ClipLoader/>
                  </div>
                </div>
              )}
              <div className='text-xs mt-1'>{filename}</div>
            </button>
          </div>
        ))}
      </div>
      <AnimatePresence initial={false}>
        {imageId && (
          <UploadContainer id={imageId} onClickAway={() => setImageId(0)}/>
        )}
      </AnimatePresence>
    </div>
  );
};

// UploadsContainer.defaultProps = {
// };

export default UploadsContainer;
