import {
  DEFAULTS,
  DesignCanvasActionTypes,
  RESET,
  SET_DESIGN_SIDE,
  COPY_FROM,
  UPDATE, UPDATE_DETAILS, UPDATE_CUSTOM, TOGGLE_LOCKED, UNDO_SIDE
} from "./types";
import DesignProduct from "@oyoyo/shared/src/types/DesignProduct";
import DesignImage from "@oyoyo/shared/src/types/DesignImage";
import DesignProductSettings from "@oyoyo/shared/src/types/DesignProductSettings";

export const update = (settings: DesignProductSettings): DesignCanvasActionTypes => {
  return {
    type: UPDATE,
    payload: settings,
  }
};

export const updateDetails = (settings: { left: number, top: number }): DesignCanvasActionTypes => {
  return {
    type: UPDATE_DETAILS,
    payload: settings,
  }
};

export const updateCustom = (settings: { left: number, top: number, width: number, height: number }): DesignCanvasActionTypes => {
  return {
    type: UPDATE_CUSTOM,
    payload: settings,
  }
};

export const reset = (front: DesignProductSettings, back: DesignProductSettings, detail: { left: number, top: number }, custom: { left: number, top: number, width: number, height: number }): DesignCanvasActionTypes => {
  const payload: {front: DesignProductSettings, back?: DesignProductSettings, detail: { left: number, top: number }, custom: { left: number, top: number, width: number, height: number }} = {
    detail: {
      ...detail,
    },
    custom: {
      ...custom,
    },
    front: {
      scale: front.scale,
      rotation: front.rotation,
      top: front.top,
      left: front.left,
      backgroundColor: front.backgroundColor,
    },
  };
  if (back) {
    payload.back = {
      scale: back.scale,
      rotation: back.rotation,
      top: back.top,
      left: back.left,
      backgroundColor: back.backgroundColor,
    };
  }
  return {
    type: RESET,
    payload,
  }
};

export const defaults = (product: DesignProduct, image: DesignImage): DesignCanvasActionTypes => {
  return {
    type: DEFAULTS,
    payload: {
      product,
      image,
    },
  }
};

export const setSide = (side: 'front' | 'back'): DesignCanvasActionTypes => {
  return {
    type: SET_DESIGN_SIDE,
    payload: side,
  }
};

export const undoSide = (side: 'front' | 'back'): DesignCanvasActionTypes => {
  return {
    type: UNDO_SIDE,
    payload: side,
  }
};

export const copyFrom = (side: 'front' | 'back'): DesignCanvasActionTypes => {
  return {
    type: COPY_FROM,
    payload: side,
  }
};

export const toggleLocked = (): DesignCanvasActionTypes => {
  return {
    type: TOGGLE_LOCKED,
  }
};
