import React from 'react';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import OyButton from "@oyoyo/ui/src/components/OyButton/OyButton";
import Welcome from 'images/180817_login_willkommen_highres.png';
import { motion } from "framer-motion";
import {Link, useNavigation} from "react-navi";
import { useToasts } from 'react-toast-notifications';

export interface ForgotPasswordFormValues {
  username: string;
}

export interface ForgotPasswordFormProps {
}

const handleSubmit = async (values: ForgotPasswordFormValues) => {
  return fetch(`${process.env.REACT_APP_API_NESTJS}/auth/forgot-password`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values)
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Forgot password failed')
      }
      return response.json()
    })
};

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = () => {
  const navigation = useNavigation();
  const { addToast } = useToasts();
  const initialValues: ForgotPasswordFormValues = {username: ''};
  return (
    <div className='flex flex-col items-center justify-center'>
      {/*<h1>Login</h1>*/}
      <motion.div animate={{ y: [50, 0], opacity: [0, 1] }} transition={{ ease: "easeIn", duration: 0.5 }} className="w-full max-w-xs">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 my-16">
          <img src={Welcome} alt='Welcome' className='w-full -mt-12 mb-4'/>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, {setSubmitting, setFieldError}) => {
              // console.log({ values, actions });
              try {
                await handleSubmit(values);
                addToast('E-Mail wurde verschickt', { appearance: 'success', autoDismiss: true });
                navigation.navigate(`/login`);
              } catch (e) {
                addToast('Eingabe prüfen', { appearance: 'error', autoDismiss: true });
              }
              setSubmitting(false);
            }}
          >
            {({isSubmitting}) => (
              <Form translate="yes">
                <div className="mb-6">
                  <label className="block">
                    <div className='text-gray-700 text-sm font-medium'>Benutzername</div>
                    <Field name={'username'} type={'input'} placeholder="Username"
                           className='appearance-none border border-gray-400 hover:border-gray-500 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none mt-2'/>
                  </label>
               </div>
                <div className="flex items-center justify-between">
                  <OyButton type={'submit'} disabled={isSubmitting} label={'Passwort zurücksetzen'}/>
                  <Link className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="/login">
                    Zurück
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </motion.div>
    </div>
  );
};

ForgotPasswordForm.defaultProps = {};

export default ForgotPasswordForm;
