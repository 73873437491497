import gql from "graphql-tag";

interface DesignImage {
  id: number,
  path: string,
  filename: string,
  filesize: string,
  mimetype: string,
  width: number,
  height: number,
  interpretation: string,
  isPattern: boolean,
  designs: {
    aggregate: {
      count: number,
    }
  }
  designConfigurationSettings: {
    aggregate: {
      count: number,
    }
  }
}

export interface UploadsSubscription {
  uploads: DesignImage[],
}

export const UPLOADS_SUBSCRIPTION = gql`
  subscription uploads {
    uploads: design_image(order_by: {id: desc}) {
      id
      path
      filename
      filesize
      mimetype
      width
      height
      designs: designs_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export interface UploadsQuery extends UploadsSubscription {
  uploadsInfo: {
    aggregate: {
      count: number;
    }
  }
}

export const UPLOADS_QUERY = gql`
  query uploads($limit: Int, $offset: Int) {
    uploads: design_image(where: {width: {_is_null: false}}, limit: $limit, offset: $offset, order_by: {id: desc}) {
      id
      path
      filename
      filesize
      mimetype
      width
      height
      designs: designs_aggregate {
        aggregate {
          count
        }
      }
    }
    uploadsInfo: design_image_aggregate(where: {width: {_is_null: false}}) {
      aggregate {
        count
      }
    }
  }
`;

export interface UploadSubscription {
  upload: DesignImage,
}

export const UPLOAD_SUBSCRIPTION = gql`
  subscription upload($id: Int!) {
    upload: design_image_by_pk(id: $id) {
      id
      path
      filename
      filesize
      mimetype
      width
      height
      interpretation
      isPattern
      designs: designs_aggregate {
        aggregate {
          count
        }
      }
      designConfigurationSettings: designConfigurationSettings_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
