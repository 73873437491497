import DesignProductSettings from "@oyoyo/shared/src/types/DesignProductSettings";

export interface CreatePayload {
  id: number,
  name: string,
  navigation: any,
}

export interface ToggleConfigurationPayload {
  id: number,
  active: boolean,
  designId: number,
}

export interface ApproveConfigurationPayload {
  id: number,
  approved: boolean,
}

export interface SaveConfigurationPayload {
  designId: number,
  designConfigurationId: number,
  detailLeft: number,
  detailTop: number,
  customizableLeft: number,
  customizableTop: number,
  customizableWidth: number,
  customizableHeight: number,
  frontSettings: DesignProductSettings,
  backSettings?: DesignProductSettings,
}

export interface DesignState {
  // width: number,
  // height: number,
  // url: string,
  // side: 'front' | 'back',
  configuration: any,
}

const initialState: DesignState = {
  // width: 400,
  // height: 400,
  // url: 'https://dev.oyoyo.mitting.io/designs/22a36404-c7ed-4f82-ab0f-418ab386f011/thumbnails/640.jpg',
  // side: 'front',
  configuration: null,
};

export const SET = 'SET';
export const TOGGLE_DESIGN_IMAGE_PATTERN = 'TOGGLE_DESIGN_IMAGE_PATTERN';
export const DELETE_DESIGN_IMAGE = 'DELETE_DESIGN_IMAGE';
export const CREATE_DESIGN = 'CREATE_DESIGN';
export const RENDER_DESIGN = 'RENDER_DESIGN';
export const REQUEST_PUBLISH_DESIGN = 'REQUEST_PUBLISH_DESIGN';
export const PUBLISH_DESIGN = 'PUBLISH_DESIGN';
export const ADD_SEAT_CUSHIONS = 'ADD_SEAT_CUSHIONS';
export const TOGGLE_DESIGN = 'TOGGLE_DESIGN';
export const DECLINE_DESIGN = 'DECLINE_DESIGN';
export const DELETE_DESIGN = 'DELETE_DESIGN';
export const TOGGLE_DESIGN_CONFIGURATION = 'TOGGLE_DESIGN_CONFIGURATION';
export const APPROVE_DESIGN_CONFIGURATION = 'APPROVE_DESIGN_CONFIGURATION';
export const REQUEST_DESIGN_CONFIGURATION_SETTINGS = 'REQUEST_DESIGN_CONFIGURATION_SETTINGS';
export const RECEIVE_DESIGN_CONFIGURATION_SETTINGS = 'RECEIVE_DESIGN_CONFIGURATION_SETTINGS';
export const SAVE_DESIGN_CONFIGURATION = 'SAVE_DESIGN_CONFIGURATION';
export const REVERT_DESIGN_CONFIGURATION = 'REVERT_DESIGN_CONFIGURATION';
export const SET_DESIGN_IMAGE = 'SET_DESIGN_IMAGE';
export const SET_DESIGN_CONFIGURATION_IMAGE = 'SET_DESIGN_CONFIGURATION_IMAGE';

interface SetAction {
  type: typeof SET
  payload: DesignState
}

interface CreateAction {
  type: typeof CREATE_DESIGN
  payload: CreatePayload
}

interface ToggleImagePatternAction {
  type: typeof TOGGLE_DESIGN_IMAGE_PATTERN
  payload: {
    id: number,
    isPattern: boolean,
  }
}

interface DeleteDesignImageAction {
  type: typeof DELETE_DESIGN_IMAGE
  payload: number
}

interface RenderDesignAction {
  type: typeof RENDER_DESIGN
  payload: number
}

interface RequestPublishDesignAction {
  type: typeof REQUEST_PUBLISH_DESIGN
  payload: number
}

interface PublishDesignAction {
  type: typeof PUBLISH_DESIGN
  payload: number
}

interface AddSeatCushionAction {
  type: typeof ADD_SEAT_CUSHIONS
  payload: number
}

interface ToggleDesignAction {
  type: typeof TOGGLE_DESIGN
  payload: {
    id: number,
    active: boolean,
  }
}

interface DeclineDesignAction {
  type: typeof DECLINE_DESIGN
  payload: {
    id: number,
    declineReason: string,
  }
}

interface DeleteDesignAction {
  type: typeof DELETE_DESIGN
  payload: {
    id: number,
  }
}

interface ToggleConfigurationAction {
  type: typeof TOGGLE_DESIGN_CONFIGURATION
  payload: ToggleConfigurationPayload
}

interface ApproveConfigurationAction {
  type: typeof APPROVE_DESIGN_CONFIGURATION
  payload: ApproveConfigurationPayload
}

export interface RequestConfigurationSettingsAction {
  type: typeof REQUEST_DESIGN_CONFIGURATION_SETTINGS,
  payload: number,
}

export interface ReceiveConfigurationSettingsAction {
  type: typeof RECEIVE_DESIGN_CONFIGURATION_SETTINGS,
  payload: any,
}

export interface SaveDesignConfigurationAction {
  type: typeof SAVE_DESIGN_CONFIGURATION,
  payload: SaveConfigurationPayload,
}

export interface RevertDesignConfigurationAction {
  type: typeof REVERT_DESIGN_CONFIGURATION,
  payload: any,
}

export interface SetDesignImageAction {
  type: typeof SET_DESIGN_IMAGE,
  payload: {
    designId: number,
    imageId: number,
  },
}

export interface SetDesignConfigurationImageAction {
  type: typeof SET_DESIGN_CONFIGURATION_IMAGE,
  payload: {
    settingsId: number | undefined,
    imageId: number,
  },
}

export type DesignActionTypes = SetAction | ToggleImagePatternAction | DeleteDesignImageAction | CreateAction | RenderDesignAction | RequestPublishDesignAction | PublishDesignAction | AddSeatCushionAction | ToggleDesignAction | DeclineDesignAction | DeleteDesignAction | ToggleConfigurationAction | ApproveConfigurationAction | RequestConfigurationSettingsAction | ReceiveConfigurationSettingsAction | SaveDesignConfigurationAction | RevertDesignConfigurationAction | SetDesignImageAction | SetDesignConfigurationImageAction;

export const create = (id: number, name: string, navigation: any): DesignActionTypes => {
  return {
    type: CREATE_DESIGN,
    payload: {
      id,
      name,
      navigation,
    },
  }
};

export const togglePattern = (id: number, isPattern: boolean): DesignActionTypes => {
  return {
    type: TOGGLE_DESIGN_IMAGE_PATTERN,
    payload: {id, isPattern},
  }
};

export const deleteDesignImage = (id: number): DesignActionTypes => {
  return {
    type: DELETE_DESIGN_IMAGE,
    payload: id,
  }
};

export const renderDesign = (id: number): DesignActionTypes => {
  return {
    type: RENDER_DESIGN,
    payload: id,
  }
};

export const requestPublishDesign = (id: number): DesignActionTypes => {
  return {
    type: REQUEST_PUBLISH_DESIGN,
    payload: id,
  }
};

export const publishDesign = (id: number): DesignActionTypes => {
  return {
    type: PUBLISH_DESIGN,
    payload: id,
  }
};

export const addSeatCushions = (id: number): DesignActionTypes => {
  return {
    type: ADD_SEAT_CUSHIONS,
    payload: id,
  }
};

export const toggleDesign = (id: number, active: boolean): DesignActionTypes => {
  return {
    type: TOGGLE_DESIGN,
    payload: {
      id,
      active,
    },
  }
};

export const declineDesign = (id: number, declineReason: string): DesignActionTypes => {
  return {
    type: DECLINE_DESIGN,
    payload: {
      id,
      declineReason,
    },
  }
};

export const deleteDesign = (id: number): DesignActionTypes => {
  return {
    type: DELETE_DESIGN,
    payload: {
      id,
    },
  }
};

export const toggleConfiguration = (id: number, active: boolean, designId: number): DesignActionTypes => {
  return {
    type: TOGGLE_DESIGN_CONFIGURATION,
    payload: {
      id,
      active,
      designId
    },
  }
};

export const approveConfiguration = (id: number, approved: boolean): DesignActionTypes => {
  return {
    type: APPROVE_DESIGN_CONFIGURATION,
    payload: {
      id,
      approved
    },
  }
};

export const requestConfigurationSettings = (id: number): DesignActionTypes => {
  return {
    type: REQUEST_DESIGN_CONFIGURATION_SETTINGS,
    payload: id,
  }
};

export const receiveConfigurationSettings = (configuration: any): DesignActionTypes => {
  return {
    type: RECEIVE_DESIGN_CONFIGURATION_SETTINGS,
    payload: configuration,
  }
};

export const saveDesignConfiguration = (designId: number, designConfigurationId: number, detailLeft: number, detailTop: number, customizableLeft: number, customizableTop: number, customizableWidth: number, customizableHeight: number, frontSettings: DesignProductSettings, backSettings?: DesignProductSettings): DesignActionTypes => {
  return {
    type: SAVE_DESIGN_CONFIGURATION,
    payload: {
      designId,
      designConfigurationId,
      frontSettings,
      backSettings,
      detailLeft,
      detailTop,
      customizableLeft,
      customizableTop,
      customizableWidth,
      customizableHeight,
    },
  }
};

export const revertDesignConfiguration = (configuration: any): DesignActionTypes => {
  return {
    type: REVERT_DESIGN_CONFIGURATION,
    payload: configuration,
  }
};

export const replaceDesignImage = (designId: number, imageId: number): DesignActionTypes => {
  return {
    type: SET_DESIGN_IMAGE,
    payload: {
      designId,
      imageId,
    },
  }
};

export const replaceDesignConfigurationImage = (settingsId: number | undefined, imageId: number): DesignActionTypes => {
  return {
    type: SET_DESIGN_CONFIGURATION_IMAGE,
    payload: {
      settingsId,
      imageId,
    },
  }
};

const design = (state = initialState, action: DesignActionTypes): DesignState => {
  switch (action.type) {
    case CREATE_DESIGN:
      return {
        ...initialState,
      };
    case RENDER_DESIGN:
      return {
        ...initialState,
      };
    case TOGGLE_DESIGN_CONFIGURATION:
      return {
        ...initialState,
      };
    case APPROVE_DESIGN_CONFIGURATION:
      return {
        ...initialState,
      };
    case REQUEST_DESIGN_CONFIGURATION_SETTINGS:
      return {
        ...state,
      };
    case RECEIVE_DESIGN_CONFIGURATION_SETTINGS:
      return {
        ...state,
        configuration: action.payload,
      };
    case SAVE_DESIGN_CONFIGURATION:
      return {
        ...state,
      };
    case REVERT_DESIGN_CONFIGURATION:
      return {
        ...state,
      };
    case SET_DESIGN_CONFIGURATION_IMAGE:
      return {
        ...state,
      };
    default:
      return state
  }
};

export default design
