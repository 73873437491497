import React from "react";
import { Dashboard } from '@uppy/react';
import uppy from 'api/uppy';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import {UserState} from "../../store/reducer/user";
import {shallowEqual, useSelector} from "react-redux";
import {AppState} from "../../store/reducer/root";

export interface UploadDashboardProps {

}

const UploadDashboard: React.FC<UploadDashboardProps> = () => {
  // useUserProfile hook to get access token
  // TODO use redux or global variable to read accessToken
  const { id: userId, accessToken }: UserState = useSelector((state: AppState) => state.user, shallowEqual);
  const meta = {
    accessToken,
    userId, // TODO use this? should be used from token
  };
  uppy.setMeta(meta);
  return (
    <Dashboard
      uppy={uppy}
      proudlyDisplayPoweredByUppy={false}
      showLinkToFileUploadResult={false}
      height={300}
    />
  )
};

export default UploadDashboard;
