import {pxToMm} from './conversions';
import {MAX_SCALE} from '..';

export function getCoverRatio(product: { width: number, height: number }, margins: { left: number, right: number, top: number, bottom: number }, image: { widthPx: number, heightPx: number, isPattern: boolean }): number {
  if (image.isPattern) {
    return 1;
  }
  const widthRatio = (product.width + (2 * Math.max(margins.right, margins.left))) / pxToMm(image.widthPx);
  const heightRatio = (product.height + (2 * Math.max(margins.top, margins.bottom))) / pxToMm(image.heightPx);
  return Math.max(widthRatio, heightRatio);
}

export function getScaleFromRatio(ratio: number): number {
  return Math.min(Math.ceil(ratio * 100), MAX_SCALE)
}
