import {combineReducers} from 'redux';
import {reducer as uppyReducer} from '@uppy/store-redux';

import userReducer from './user';
import messagingReducer from './messaging';
import designReducer from './design';
import designCanvasReducer from "containers/DesignCanvas/reducers";
import designFilterReducer from "containers/Design/reducers";

const rootReducer = combineReducers({
  design: designReducer,
  designCanvas: designCanvasReducer,
  designFilter: designFilterReducer,
  messaging: messagingReducer,
  user: userReducer,
  uppy: uppyReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
