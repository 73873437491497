import React, {useRef} from 'react';
// import {useLockBodyScroll} from 'react-use';
import {useClickAway} from 'react-use';
import {useSubscription} from "@apollo/client";
import {UPLOAD_SUBSCRIPTION, UploadSubscription} from "./subscriptions";
// import useLockBodyScroll from "@oyoyo/ui/src/hooks/useLockBodyScroll";
import {useNavigation} from "react-navi";
import OyButton from "@oyoyo/ui/src/components/OyButton/OyButton";
import {useDispatch} from "react-redux";
import {create, deleteDesignImage, togglePattern} from "../../store/reducer/design";
import {motion} from "framer-motion";
import Magnifier from "react-magnifier";

export interface UploadContainerProps {
  id: number,
  onClickAway: () => void,
}

const UploadContainer: React.FC<UploadContainerProps> = ({id, onClickAway}) => {
  const ref = useRef(null);
  const navigation = useNavigation();
  useClickAway(ref, onClickAway);

  const { data, loading, error } = useSubscription<UploadSubscription>(
    UPLOAD_SUBSCRIPTION,
    { variables: { id } }
  );
  // useLockBodyScroll(!loading);
  const dispatch = useDispatch();
  const createDesign = (id: number) => () => {
    if (id && data && id === data.upload.id && data.upload.width && data.upload.height && data.upload.interpretation === 'sRGB') {
      dispatch(create(id, data.upload.filename, navigation));
    }
  };
  const onTogglePattern = (id: number) => () => {
    if (id && data && id === data.upload.id) {
      dispatch(togglePattern(id, !data.upload.isPattern));
    }
  };
  const deleteImage = (id: number) => () => {
    if (id && data && id === data.upload.id) {
      onClickAway();
      dispatch(deleteDesignImage(id));
    }
  };

  if (error) {
    return (
      <div>Ein Fehler ist aufgetreten :-(</div>
    );
  }
  if (loading || !data) {
    return (
      <div>Wird geladen...</div>
    );
  }

  // TODO disable button if no width/height or after clicked...
  return (
    <div className='fixed z-2000 inset-0 overflow-auto flex'>
      <motion.div
        className='fixed w-full h-full bg-gray-900'
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.5 }}
        exit={{ opacity: 0 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
      />
      <motion.div
        className='relative p-4 bg-gray-200 w-full max-w-md m-auto flex-col flex shadow-xl' ref={ref}
        initial={{ scale: 0.1, x: 0, y: 0 }}
        animate={{ scale: 1, x: 0, y: 0 }}
        exit={{ scale: 0.1, x: 0, y: 0 }}
        transition={{ ease: "easeInOut", duration: 0.3 }}
      >
        <div className='text-center'>{data.upload.filename}</div>
        {/*<div onClick={onTogglePattern(id)}>Pattern? {data.upload.isPattern ? 'yes' : 'no'}</div>*/}
        {/*<img className='my-2' src={`${process.env.REACT_APP_STORAGE_PREFIX}/designs/${data.upload.path}/thumbnails/640.jpg`} alt={data.upload.filename}/>*/}
        <Magnifier className='my-2' src={`${process.env.REACT_APP_STORAGE_PREFIX}/designs/${data.upload.path}/thumbnails/640.jpg`} zoomImgSrc={`${process.env.REACT_APP_STORAGE_PREFIX}/designs/${data.upload.path}/thumbnails/1920.jpg`}/>
        <label>
          <input type='checkbox' onChange={onTogglePattern(id)} checked={data.upload.isPattern}/> Aus diesem Design ein Rapport erstellen
        </label>
        <OyButton color={'blue'} label={'Produkte erstellen'} disabled={loading || data.upload.designs.aggregate.count > 0} onClick={createDesign(id)}/>
        <OyButton className={'mt-2'} color={'red'} label={'Design löschen'} disabled={loading || data.upload.designs.aggregate.count > 0 || data.upload.designConfigurationSettings.aggregate.count > 0} onClick={deleteImage(id)}/>
        {/*<div style={{height: '2000px'}} className='bg-gray-100'>space</div>*/}
      </motion.div>
    </div>
  );
};

// UploadContainer.defaultProps = {
// };

export default UploadContainer;
