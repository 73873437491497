import React from 'react';

import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FieldProps, useField, FieldInputProps,
} from 'formik';
import * as Yup from 'yup';
import {DesignFilterQueryResult, DesignPropertyReference} from "../../containers/Design/queries";
import OyButton from "@oyoyo/ui/src/components/OyButton/OyButton";
import { useToasts } from 'react-toast-notifications';
import {useNavigation} from "react-navi";
import {DesignFilterState} from "../../containers/Design/types";
import PropertySelect from "../DesignPropertiesForm/PropertySelect";
import RequireRole, {DESIGN_MANAGER} from "../RequireRole/RequireRole";
import Select, { Option, CommonProps } from 'react-select';

export interface DesignFilterFormValues extends DesignFilterState{
}

export interface DesignFilterFormProps {
  initialValues: DesignFilterState,
  data: DesignFilterQueryResult,
  handler: (values: DesignFilterFormValues) => void,
  reset: () => void,
}

export const DesignFilterFormSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, 'Dieser Titel ist zu kurz'),
  // themes: Yup.array()
  //   .min(1, 'Mindestens ein Thema wählen')
  //   .max(3, 'Maximal zwei Themen wählen')
  //   .required('Bitte mindestens ein Thema wählen'),
  // styles: Yup.array()
  //   // .min(1, 'Mindestens einen Stil wählen')
  //   .max(2, 'Maximal zwei Stile wählen'),
  // // .required('Bitte mindestens einen Stil wählen'),
  // colors: Yup.array()
  //   .min(1, 'Mindestens eine Hauptfarbe wählen')
  //   .max(2, 'Maximal zwei Hauptfarben wählen')
  //   .required('Bitte mindestens eine Hauptfarbe wählen'),
  // tags: Yup.array()
  //   .max(10, 'Maximal 10 Tags wählen'),
});

const DesignerFilterSelect: React.FC<{ designers: any[] }> = ({designers}) => {
  const [field, meta, helpers] = useField<number>('designer');
  return (
    <Select
      options={designers}
      name={field.name}
      value={designers ? designers.find((option) => option.value === field.value) : ''}
      // value={designers ? designers.filter(option => field.value === option.value) : ''}
      onChange={(option: Option): void => helpers.setValue(option.value)}
      // onChange={onChange}
      // onChange={(options: Option[]): void => helpers.setValue(options ? options.map((item: Option) => item.value) : [])}
      onBlur={field.onBlur}
      isMulti={false}
      closeMenuOnSelect={true}
    />
  )
};

const DesignFilterForm: React.FC<DesignFilterFormProps> = ({initialValues, data: {themes, styles, colors, designers}, handler, reset}) => {
  // const { addToast } = useToasts();
  // const navigation = useNavigation();
  // const initialValues = state;
  return (
      <Formik
        initialValues={initialValues}
        validationSchema={DesignFilterFormSchema}
        onSubmit={async (values, {setSubmitting, setFieldError}) => {
          await handler(values);
          // setSubmitting(false);
        }}
        onReset={async (values, {setSubmitting}) => {
          await reset();
          // setSubmitting(false);
        }}
        render={({isSubmitting, errors, touched}) => (
          <Form translate="yes" className='grid grid-cols-1 grid-gap-4 w-full max-w-xl justify-between mb-4'>
            <label>
              <div className='font-medium mb-2'>Titel</div>
              <Field className='appearance-none border border-gray-400 hover:border-gray-500 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none' name={'name'} type={'input'}/>
            </label>
            {touched.name && errors.name ? (
              <div className="error text-red-500 text-sm">{errors.name}</div>
            ) : null}
            <div className='flex'>
              <label>
                <div className='font-medium mb-2'>Aktiv?</div>
                <Field className='focus:outline-none' name={'active'} type={'checkbox'}/>
              </label>
              <label className='ml-4'>
                <div className='font-medium mb-2'>Abgelehnt?</div>
                <Field className='focus:outline-none' name={'declined'} type={'checkbox'}/>
              </label>
              <RequireRole role={DESIGN_MANAGER}>
                <label className='ml-4'>
                  <div className='font-medium mb-2'>Freigegeben?</div>
                  <Field className='focus:outline-none' name={'published'} type={'checkbox'}/>
                </label>
              </RequireRole>
            </div>
            <RequireRole role={DESIGN_MANAGER}>
              <label className="flex-1">
                <div className='font-medium mb-2'>Designer*in</div>
                <DesignerFilterSelect designers={designers.map(({id, name}) => ({ value: id, label: name }))} />
              </label>
            </RequireRole>
            <PropertySelect label={'Thema wählen'} name={'themes'} options={themes.map(({id, name}) => ({ value: id, label: name }))} />
            <PropertySelect label={'Stil wählen'} name={'styles'} options={styles.map(({id, name}) => ({ value: id, label: name }))} />
            <PropertySelect label={'Hauptfarben wählen'} name={'colors'} options={colors.map(({id, name}) => ({ value: id, label: name }))} />
            <div>
              <OyButton type={'submit'} className={'mr-4'} disabled={isSubmitting} label={'Filtern'}/>
              <OyButton type={'reset'} disabled={isSubmitting} label={'Reset'}/>
            </div>
          </Form>
        )}
      />
  );
};

DesignFilterForm.defaultProps = {
};

export default DesignFilterForm;
