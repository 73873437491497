import React, {useEffect, useRef, Fragment, useState} from 'react';
import {Image, Rect, Transformer} from "react-konva/lib/ReactKonvaCore";
import "konva/lib/shapes/Rect";
import "konva/lib/shapes/Image";
import "konva/lib/shapes/Transformer";
import {DetailAreaProps} from "./interfaces";

const DetailArea: React.FC<DetailAreaProps> = ({shapeProps, transform, onChange}) => {
  const [interactive, setInteractive] = useState<boolean>(false);
  const shapeRef = useRef(null);
  const transformerRef = useRef(null);
  const showTransform = interactive || transform;
  useEffect(() => {
    if (showTransform) {
      if (transformerRef.current && shapeRef.current) {
        // @ts-ignore
        transformerRef.current.nodes([shapeRef.current]);
        // @ts-ignore
        transformerRef.current.getLayer().batchDraw();
      }
    }
  }, [showTransform]);
  return (
    <Fragment>
      <Rect
        {...shapeProps}
        offsetX={0.5 * shapeProps.width}
        offsetY={0.5 * shapeProps.height}
        dash={[4, 4]}
        stroke={'blue'}
        strokeWidth={1}
        listening={true}
        draggable={true}
        ref={shapeRef}
        onDragStart={() => {
          setInteractive(true);
        }}
        onDragEnd={(e) => {
          setInteractive(false);
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
      />
      {showTransform &&
      <Transformer
        ref={transformerRef}
        enabledAnchors={[]}
        rotateEnabled={false}
      />
      }
    </Fragment>
  );
};

export default DetailArea;
