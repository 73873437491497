export interface Message {
  id: string,
  ts: number,
  type: 'message' | string,
  new: boolean,
  data: any,
}

export interface MessagingState {
  online: boolean,
  messages: Message[],
}

const initialState: MessagingState = {
  online: false,
  messages: [],
};

export const SET_CONNECTION_STATE = 'SET_CONNECTION_STATE';
export const ADD_MESSAGE = 'ADD_MESSAGE';

interface SetConnectionStateAction {
  type: typeof SET_CONNECTION_STATE,
  payload: boolean,
}

interface AddMessageAction {
  type: typeof ADD_MESSAGE,
  payload: MessageEvent,
}

export type MessagingActionTypes = SetConnectionStateAction | AddMessageAction;

export const setConnectionState = (online: boolean): MessagingActionTypes => {
  return {
    type: SET_CONNECTION_STATE,
    payload: online,
  }
};

export const addMessage = (event: MessageEvent): MessagingActionTypes => {
  return {
    type: ADD_MESSAGE,
    payload: event,
  }
};

const messaging = (state = initialState, action: MessagingActionTypes): MessagingState => {
  switch (action.type) {
    case SET_CONNECTION_STATE:
      return {
        ...state,
        online: action.payload,
      };
    case ADD_MESSAGE:
      const {lastEventId, type, timeStamp, data} = action.payload;
      const message: Message = {
        id: lastEventId,
        ts: timeStamp,
        type,
        data: JSON.parse(data),
        new: true,
      };
      return {
        ...state,
        messages: [...state.messages, message],
      };
    default:
      return state
  }
};

export default messaging
