import React from 'react';
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";
import PublishingContainer from "../containers/Design/PublishingContainer";

export interface PublishingProps {
}

const Publishing: React.FC<PublishingProps> = () => {
  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-01 bg-cover bg-fixed'}>
      <PublishingContainer/>
    </OyContainer>
  );
};

// Publishing.defaultProps = {
// };

export default Publishing;
