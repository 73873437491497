import gql from "graphql-tag";

export const DESIGN_PROPERTIES_MUTATION = gql`
  mutation setDesignProperties($id: Int!, $name: String!, $description: String!, $isCustomizable: Boolean!, $defaultText: String, $groupName: String, $themes: [design_theme_designs_design_insert_input!]!, $subThemes: [design_sub_theme_designs_design_insert_input!]!, $styles: [design_style_designs_design_insert_input!]!, $colors: [design_color_designs_design_insert_input!]!, $tags: [design_tag_designs_design_insert_input!]!) {
    design: update_design(where: {id: {_eq: $id}}, _set: {name: $name, description: $description, isCustomizable: $isCustomizable, defaultText: $defaultText, groupName: $groupName, updated_at: "now()"}) {
      returning {
        updated_at
      }
    }
    delete_design_theme_designs_design(where: {designId: {_eq: $id}}) {
      affected_rows
    }
    insert_design_theme_designs_design(objects: $themes) {
      affected_rows
    }
    delete_design_sub_theme_designs_design(where: {designId: {_eq: $id}}) {
      affected_rows
    }
    insert_design_sub_theme_designs_design(objects: $subThemes) {
      affected_rows
    }
    delete_design_style_designs_design(where: {designId: {_eq: $id}}) {
      affected_rows
    }
    insert_design_style_designs_design(objects: $styles) {
      affected_rows
    }
    delete_design_color_designs_design(where: {designId: {_eq: $id}}) {
      affected_rows
    }
    insert_design_color_designs_design(objects: $colors) {
      affected_rows
    }
    delete_design_tag_designs_design(where: {designId: {_eq: $id}}) {
      affected_rows
    }
    insert_design_tag_designs_design(objects: $tags) {
      affected_rows
    }
  }
`;
