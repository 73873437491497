import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router, View } from 'react-navi'
import { routes } from './routes';
import '@oyoyo/ui/src/styles/fonts.css';
import '@oyoyo/ui/src/postcss.css';
import './index.css';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';

import { ApolloProvider } from '@apollo/client';
import { client } from "api/hasura";

import { ToastProvider } from 'react-toast-notifications';

import store from 'store/store/main';
import LayoutContainer from "./containers/Layout/Layout";

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ToastProvider placement={'bottom-right'}>
        <Router routes={routes}>
          <Suspense fallback={null}>
            <LayoutContainer>
              <View />
            </LayoutContainer>
          </Suspense>
        </Router>
      </ToastProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// TODO send to backend and validate that latest version is running? or push message to show reload toast
console.log(process.env.REACT_APP_VERSION);
