import gql from "graphql-tag";

interface User {
  id: number,
  name: string,
  email: string,
  isActive: boolean,
  updatedAt: string,
}

export interface UsersSubscription {
  users: User[],
}

export const USERS_SUBSCRIPTION = gql`
  subscription users($limit: Int = 100, $offset: Int = 0) {
    users: users(limit: $limit, offset: $offset, order_by: {updated_at: desc}) {
      id
      name
      email
      isActive: is_active
      updatedAt: updated_at
    }
  }
`;
