import React, {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {UserState, login} from "store/reducer/user";
import {AppState} from "../../store/reducer/root";
import LoginForm, {LoginFormValues} from "../../components/LoginForm/LoginForm";

export interface LoginFormContainerProps {
}

const LoginFormContainer: React.FC<LoginFormContainerProps> = () => {
  // const { email }: UserState = useSelector((state: AppState) => state.user, shallowEqual);
  const dispatch = useDispatch();
  const loginHandler = useCallback(
    ({username, password}: LoginFormValues) => dispatch(login(username, password)),
    [dispatch]
  );
  return (
    <LoginForm handler={loginHandler}/>
  );
};

export default LoginFormContainer;
