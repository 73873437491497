import {AnimatePresence, motion} from "framer-motion";
import React, {MouseEvent, useRef, useState} from 'react';
import {useSubscription} from "@apollo/client";
import {useDispatch} from "react-redux";
import {SimpleImg} from "react-simple-img";
import {useClickAway, useToggle} from "react-use";
import OyButton from "@oyoyo/ui/src/components/OyButton/OyButton";
import {declineDesign, publishDesign} from "../../store/reducer/design";
import {PUBLISHING_SUBSCRIPTION, PublishingSubscription} from './queries';
import {Link, useNavigation} from "react-navi";
import { format, parseISO, isAfter, isBefore } from 'date-fns';
// import {ReactComponent as IconActions} from "line-awesome/svg/ellipsis-v-solid.svg";
// import {useDispatch} from "react-redux";
import {ReactComponent as IconActions} from "line-awesome/svg/ellipsis-v-solid.svg";
import RequireRole, {DESIGN_MANAGER} from "../../components/RequireRole/RequireRole";
import clsx from "clsx";
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { useToasts } from 'react-toast-notifications';

export interface DeclineValues {
  reason: string
}

const initialDeclineValues: DeclineValues = {reason: ''}

export interface PublishingContainerProps {
}

const PublishingContainer: React.FC<PublishingContainerProps> = () => {
  const { data, loading, error } = useSubscription<PublishingSubscription>(
    PUBLISHING_SUBSCRIPTION,
    { variables: { limit: 1000, offset: 0 } }
  );
  const dispatch = useDispatch();
  // const navigation = useNavigation();
  const { addToast } = useToasts();

  const [declineId, setDeclineId] = useState<number>(0);
  const ref = useRef(null);
  useClickAway(ref, () => setDeclineId(0));

  if (error) {
    return (
      <div>Ein Fehler ist aufgetreten :-(</div>
    );
  }
  if (loading || !data) {
    return (
      <div>Wird geladen...</div>
    );
  }

  const publish = (id: number) => (event: MouseEvent) => {
    event.preventDefault();
    dispatch(publishDesign(id))
    addToast('Die Produkte werden nun veröffentlicht.', { appearance: 'success', autoDismiss: true });
  };

  const handleDecline = (id: number) => (event: MouseEvent) => {
    event.preventDefault();
    // console.log('handleDecline', id)
    setDeclineId(id);
  }

  const decline = (id: number, reason: string) => {
    dispatch(declineDesign(id, reason))
    setDeclineId(0)
  }

  return (
    <RequireRole role={DESIGN_MANAGER}>
      <div className='flex flex-col'>
        {data.designs.map(({id, uuid, name, image, user, updatedAt, approvalRequestedAt, approvedAt, publishedAt, activeConfigurations, unapprovedConfigurations}) => {
          const approvedConfigurations = (activeConfigurations && unapprovedConfigurations) ? activeConfigurations?.aggregate.count - unapprovedConfigurations?.aggregate.count : 0;
          const changed = (approvalRequestedAt && publishedAt) ? isAfter(parseISO(approvalRequestedAt), parseISO(publishedAt)) : false;
          const up2date = (approvalRequestedAt && publishedAt) ? isBefore(parseISO(approvalRequestedAt), parseISO(publishedAt)) : false;
          return (
            <div className='break-all' key={id}>
              <Link className={clsx('grid grid-columns-12 grid-gap-4 items-center hover:bg-gray-100 p-4', {'bg-yellow-200': changed, 'bg-green-200': up2date})} activeClassName='font-bold' href={`/design/products/${id}/product-preview`} exact>
                <SimpleImg className='col-span-1 w-12' src={`${process.env.REACT_APP_STORAGE_PREFIX}/designs/${image.path}/thumbnails/320.square.jpg`} alt={name} placeholder={false}/>
                <div className='col-span-6 leading-tight'><span className='font-medium'>{name}</span> <RequireRole role={DESIGN_MANAGER}><span className='text-sm'>von {user?.name}</span></RequireRole><br/><span className='text-xs'>{uuid}</span></div>
                <div className={clsx('col-span-1 text-sm font-medium text-center', {'text-red-500': approvedConfigurations !== activeConfigurations?.aggregate.count})}>{approvedConfigurations} / {activeConfigurations?.aggregate.count}</div>
                <div className='col-span-4 flex justify-between items-center'>
                  <div className='leading-tight text-xs text-right'>
                    {updatedAt ? (<div>Letzte Änderung {format(parseISO(updatedAt), 'yyyy-MM-dd HH:mm')}</div>) : null}
                    {approvalRequestedAt ? (<div>Freigabe angefordert {format(parseISO(approvalRequestedAt), 'yyyy-MM-dd HH:mm')}</div>) : null}
                    {approvedAt ? (<div>Freigegeben {format(parseISO(approvedAt), 'yyyy-MM-dd HH:mm')}</div>) : null}
                    {publishedAt ? (<div>Veröffentlicht {format(parseISO(publishedAt), 'yyyy-MM-dd HH:mm')}</div>) : null}
                  </div>
                  <div className='hover:bg-gray-200 p-1 rounded-full relative menu transition duration-200'>
                    <IconActions className='w-6 h-6'/>
                    <ul className='absolute right-0 flex flex-col z-50 bg-white opacity-0 pointer-events-none submenu break-normal whitespace-no-wrap shadow-lg border border-gray-200 text-black'>
                      <li className='hover:bg-gray-200 transition duration-200'><button className='w-full p-2 text-left' onClick={publish(id)}>Produkte veröffentlichen</button></li>
                      <li className='hover:bg-gray-200 transition duration-200 text-red-500'><button className='w-full p-2 text-left' onClick={handleDecline(id)}>Produkte ablehnen</button></li>
                    </ul>
                  </div>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
      <AnimatePresence initial={false}>
        {declineId && (
          <div className='fixed z-2000 inset-0 overflow-auto flex'>
            <motion.div
              className='fixed w-full h-full bg-gray-900'
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeInOut", duration: 0.3 }}
            />
            <motion.div
              className='relative p-4 bg-gray-200 w-full max-w-md m-auto flex-col flex shadow-xl' ref={ref}
              initial={{ scale: 0.1, x: 0, y: 0 }}
              animate={{ scale: 1, x: 0, y: 0 }}
              exit={{ scale: 0.1, x: 0, y: 0 }}
              transition={{ ease: "easeInOut", duration: 0.3 }}
            >
              <Formik initialValues={initialDeclineValues}
                      onSubmit={async (values, {setSubmitting, setFieldError}) => {
                        // console.log('hier', declineId, values)
                        decline(declineId, values.reason)
                        setSubmitting(false);
                      }}
              >
                {({isSubmitting}) => (
                  <Form translate="yes">
                  <div className="mb-4">
                    <label className="block">
                      <div className='text-gray-700 text-sm font-medium'>Grund für die Ablehnung (Inhalt der generierten E-Mail):</div>
                      <Field name={'reason'} component={'textarea'}
                             className='appearance-none border border-gray-400 hover:border-gray-500 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none mt-2'/>
                    </label>
                    <p>Wenn leer, erscheint der bekannte Textblock:</p>
                    <div className="bg-gray-100 text-gray-500 p-2 text-xs">
                      <p>Das kann folgende Gründe haben:</p>
                      <ul className="list-disc list-inside">
                        <li>Das Design wurde nicht optimal an unsere Produkte angepasst</li>
                        <li>Das Design ist nicht besonders geeignet für den Textildruck (z.B. Simulation von Metallic-Farben, zu hoher, dunkler Farbauftrag o.ä.)</li>
                        <li>Das Design entspricht nicht unseren Qualitätsansprüchen</li>
                        <li>Das Design enthält Fehler, z.B. im Rapport</li>
                        <li>Das Design verletzt unsere AGBs</li>
                      </ul>
                    </div>
                  </div>
                  <OyButton type={'submit'} color={'red'} disabled={isSubmitting} label={'Ablehnen'}/>
                  </Form>
                )}
              </Formik>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </RequireRole>
  );
};

// PublishingContainer.defaultProps = {
// };

export default PublishingContainer;
