import React, {useEffect, useRef, Fragment, useState} from 'react';
import {Image, Transformer} from "react-konva/lib/ReactKonvaCore";
import "konva/lib/shapes/Rect";
import "konva/lib/shapes/Image";
import "konva/lib/shapes/Transformer";
// import useImage from 'use-image';
import {DesignImageProps} from "./interfaces";

const DesignImage: React.FC<DesignImageProps> = ({shapeProps, transform, image, isPattern, onChange}) => {
  // const isSelected = true;
  // const scale = 0.5;
  const [interactive, setInteractive] = useState<boolean>(false);
  const shapeRef = useRef(null);
  const transformerRef = useRef(null);
  // const [image] = useImage(url, 'Anonymous');
  // const shape = shapeRef?.current as unknown as Shape;
  // if (shape && image) {
  //   console.log(shape, shape.width(), shape.scaleX(), image?.width, shape.width() / image?.width);
  // }
  // const patternScale = shape && image ? shape.scaleX() * (shape.width() / image?.width) : 1;
  const showTransform = interactive || transform;
  useEffect(() => {
    if (showTransform) {
      // console.log(shapeRef, transformerRef);
      // console.log(shapeRef.current, transformerRef.current);
      if (transformerRef.current && shapeRef.current) {
        // @ts-ignore
        transformerRef.current.nodes([shapeRef.current]);
        // @ts-ignore
        transformerRef.current.getLayer().batchDraw();
      }
    }
  }, [showTransform]);
  // TODO fillPatternImage, use simple checker image as image
  return (
    <Fragment>
      {/*<Rect*/}
      {/*  x={0}*/}
      {/*  y={0}*/}
      {/*  fillPatternImage={image}*/}
      {/*  fillPatternRepeat={'no-repeat'}*/}
      {/*  fillPatternScaleX={patternScale}*/}
      {/*  fillPatternScaleY={patternScale}*/}
      {/*  fillPatternRotation={shapeProps.rotation}*/}
      {/*  width={shapeProps.scaleX * shapeProps.width}*/}
      {/*  height={shapeProps.scaleY * shapeProps.height}*/}
      {/*/>*/}
      <Image
        {...shapeProps}
        // offsetX={-0.5 * shapeProps.width} offsetY={-0.5 * shapeProps.height}
        offsetX={0.5 * shapeProps.width}
        offsetY={0.5 * shapeProps.height}
        // opacity={0.75}
        image={image}
        draggable={transform}
        ref={shapeRef}
        onDragStart={() => {
          setInteractive(true);
        }}
        onDragEnd={(e) => {
          setInteractive(false);
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformStart={() => {
          setInteractive(true);
        }}
        onTransformEnd={(e) => {
          setInteractive(false);
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
            scaleX: e.target.scaleX(),
            scaleY: e.target.scaleY(),
            rotation: e.target.rotation(),
          });
        }}
      />
      {showTransform &&
        <Transformer
            ref={transformerRef}
            centeredScaling={true}
            enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
            rotateEnabled={!isPattern}
            boundBoxFunc={((oldBox, newBox) => {
              if (newBox.width < 20) {
                return oldBox;
              }
              return newBox;
            })}
        />
      }
    </Fragment>
  );
};

export default DesignImage;
