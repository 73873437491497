import React from 'react';
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";
import DesignsContainer from "../containers/Design/DesignsContainer";

export interface ProductsProps {
}

const Products: React.FC<ProductsProps> = () => {
  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-01 bg-cover bg-fixed'}>
      <DesignsContainer/>
    </OyContainer>
  );
};

// Products.defaultProps = {
// };

export default Products;
