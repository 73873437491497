import React from 'react';
import {Message} from "store/reducer/messaging";
import {ReactComponent as IconOnline} from "line-awesome/svg/bell.svg";
import {ReactComponent as IconOffline} from "line-awesome/svg/bell-slash.svg";

export interface NotificationsProps {
  connected: boolean,
  messages: Message[],
}

const Notifications: React.FC<NotificationsProps> = ({connected, messages}) => {
  const IconNotifications = connected ? IconOnline : IconOffline;
  return (
    <div className='hover:bg-gray-200 rounded-full p-1 focus:outline-none relative menu transition duration-200 mx-2'>
      {messages.length > 0 && (
        <div className='absolute bg-red-500 text-white rounded leading-none text-xs flex items-center justify-center p-1 -m-1'>{messages.length}</div>
      )}
      <IconNotifications className='w-8 h-8'/>
      <ul className='absolute right-0 flex flex-col z-50 bg-white opacity-0 pointer-events-none submenu break-normal whitespace-no-wrap shadow-lg border border-gray-200'>
        {messages.length === 0 && (
          <li className='p-1'>Keine neuen Benachrichtigungen</li>
        )}
        {messages.map(({id, type, data}) => (
          <li key={id} className='p-1'>{JSON.stringify({data})}</li>
        ))}
      </ul>
    </div>
  );
};

Notifications.defaultProps = {
  connected: false,
};

export default Notifications;
