import React from 'react';
import {Text, Rect} from "react-konva/lib/ReactKonvaCore";
import "konva/lib/shapes/Text";
import {TextBoxProps} from "./interfaces";

const TextBox: React.FC<TextBoxProps> = ({x, y, width, height, rotation, text, fill}) => {
  return (
    <>
      {/*<Rect*/}
      {/*  x={x + width / 2}*/}
      {/*  y={y + height / 2}*/}
      {/*  width={width}*/}
      {/*  height={height}*/}
      {/*  offsetX={width / 2}*/}
      {/*  offsetY={height / 2}*/}
      {/*  rotation={rotation}*/}
      {/*  fill={fill}*/}
      {/*/>*/}
      <Text
        x={x + width / 2}
        y={y + height / 2}
        width={width}
        height={height}
        offsetX={width / 2}
        offsetY={height / 2}
        rotation={rotation}
        text={text}
        align="center"
        verticalAlign="middle"
        fontSize={14}
        fontFamily={'Apercu, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'}
        fill={fill}
        listening={false}
      />
    </>
  )
};

export default TextBox
