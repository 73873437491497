import React from 'react';
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";
import UsersContainer from "../containers/User/UsersContainer";

export interface UsersProps {
}

const Users: React.FC<UsersProps> = () => {
  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-01 bg-cover bg-fixed'}>
      <UsersContainer/>
    </OyContainer>
  );
};

// Users.defaultProps = {
// };

export default Users;
