import React from 'react';
import {UserState} from "../store/reducer/user";
import {shallowEqual, useSelector} from "react-redux";
import {AppState} from "../store/reducer/root";
import {useNavigation} from "react-navi";
// import UploadGallery from "../components/Upload/UploadGallery";

export interface HomepageProps {
}

const Homepage: React.FC<HomepageProps> = () => {
  const { email }: UserState = useSelector((state: AppState) => state.user, shallowEqual);
  const navigation = useNavigation();

  if (email) {
    navigation.navigate(`/design/products`);
  } else {
    window.location.href = '/login';
  }

  return (
    <div/>
    // <UploadGallery className='w-full aspect-ratio-16/9 relative shadow-xl pointer-events-auto'/>
  );
};

// Homepage.defaultProps = {
// };

export default Homepage;
