import React from 'react';
import LoginFormContainer from "../containers/User/LoginFormContainer";
import {UserState} from "../store/reducer/user";
import {shallowEqual, useSelector} from "react-redux";
import {AppState} from "../store/reducer/root";
import {useNavigation} from "react-navi";
import jwtDecode from "jwt-decode";
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";

export interface LoginProps {
}

const Login: React.FC<LoginProps> = () => {
  const { email, accessToken }: UserState = useSelector((state: AppState) => state.user, shallowEqual);
  const navigation = useNavigation();

  if (email) {
    const token = jwtDecode(accessToken);
    const isDesignManager = token['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].indexOf('designmanager') !== -1;
    if (isDesignManager) {
      navigation.navigate(`/design/publish`)
    } else {
      navigation.navigate(`/design/products`)
    }
  }

  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-04 bg-cover bg-fixed flex items-center justify-center'}>
      <LoginFormContainer/>
    </OyContainer>
  );
};

// Login.defaultProps = {
// };

export default Login;
