import React from 'react';
import UploadsContainer from "../containers/Upload/UploadsContainer";
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";

export interface UploadsProps {
}

const Uploads: React.FC<UploadsProps> = ({children}) => {
  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-02 bg-cover bg-fixed'}>
      <UploadsContainer/>
      {/*<div style={{height: '2000px'}} className='bg-gray-100'>space</div>*/}
      <>
        {children}
      </>
    </OyContainer>
  );
};

// Uploads.defaultProps = {
// };

export default Uploads;
