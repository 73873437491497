import {DesignFilterActionTypes, DesignFilterState, RESET_DESIGN_FILTER, UPDATE_DESIGN_FILTER} from "./types";

const initialState: DesignFilterState = {
  active: true,
  published: false,
  declined: false,
  name: '',
  themes: [],
  styles: [],
  colors: [],
  designer: '',
}

const designFilterReducer = (state = initialState, action: DesignFilterActionTypes): DesignFilterState => {
  switch (action.type) {
    case UPDATE_DESIGN_FILTER:
      return {...action.payload}
    case RESET_DESIGN_FILTER:
      return { ...initialState }
    default:
      return state
  }
};

export default designFilterReducer;
