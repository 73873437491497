import React from 'react';
// import {useLockBodyScroll} from 'react-use';
import {useMutation, useQuery} from "@apollo/client";
import {DESIGN_PROPERTIES_QUERY, DesignPropertiesQueryResult} from "./queries";
import {useDispatch} from "react-redux";
import DesignPropertiesForm, {DesignPropertiesFormValues} from "../../components/DesignPropertiesForm/DesignPropertiesForm";
import {DESIGN_PROPERTIES_MUTATION} from "./mutations";

export interface DesignPropertiesContainerProps {
  id: number,
}

const DesignPropertiesContainer: React.FC<DesignPropertiesContainerProps> = ({id}) => {
  const { data, loading, error } = useQuery<DesignPropertiesQueryResult>(
    DESIGN_PROPERTIES_QUERY,
    { variables: { id } }
  );
  const dispatch = useDispatch();
  const [save] = useMutation(DESIGN_PROPERTIES_MUTATION);
  const handleSubmit = async (values: DesignPropertiesFormValues) => {
    const { name, description, isCustomizable, groupName, defaultText, themes, subThemes, styles, colors, tags } = values;
    return save({variables: {
        id,
        name,
        description,
        isCustomizable,
        groupName,
        defaultText,
        themes: themes.map(designThemeId => ({ designId: id, designThemeId })),
        subThemes: Array.from(Object.entries(subThemes).reduce((acc, [themeId, subThemeIds]) => {
          if (themes.includes(parseInt(themeId, 10))) {
            subThemeIds.forEach(id => acc.add(id))
          }
          return acc
        }, new Set<number>())).map(designSubThemeId => ({ designId: id, designSubThemeId })),
        styles: styles.map(designStyleId => ({ designId: id, designStyleId })),
        colors: colors.map(designColorId => ({ designId: id, designColorId })),
        tags: tags.map(designTagId => ({ designId: id, designTagId })),
    }});
  };

  if (error) {
    return (
      <div>Ein Fehler ist aufgetreten :-(</div>
    );
  }
  if (loading || !data) {
    return (
      <div>Wird geladen...</div>
    );
  }

  return (
    <>
      <div className='max-w-lg mx-auto flex flex-col text-center my-16'>
        <div className='text-4xl leading-none font-medium mb-4'>Gib alle Infos zu deinem Design an und los geht’s!</div>
        <div className='leading-tight'>Bitte überprüfe und wähle alle Optionen und Angaben sorgfältig. Nach Freigabe durch uns steht Dein Produkt bei textilwerk.io zur Verfügung. Dies kann bis zu 24 Stunden dauern.</div>
      </div>
      <div className='grid grid-columns-2 grid-gap-4'>
        <img className='w-full border-4 border-gray-100' src={`${process.env.REACT_APP_STORAGE_PREFIX}/designs/${data.design.image.path}/thumbnails/640.jpg`} alt={data.design.name}/>
        <DesignPropertiesForm data={data} handler={handleSubmit}/>
      </div>
    </>
  );
};

// DesignPropertiesContainer.defaultProps = {
// };

export default DesignPropertiesContainer;
