import React from 'react';
import LogoutContainer from "../containers/User/LogoutContainer";

export interface LogoutProps {
}

const Logout: React.FC<LogoutProps> = () => {
  return (
    <LogoutContainer/>
  );
};

// Logout.defaultProps = {
// };

export default Logout;
