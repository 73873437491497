import gql from "graphql-tag";
import {DesignsSubscription} from "./subscriptions";

export interface DesignPropertyReference {
  id: number,
  name: string,
}

interface Design {
  id: number,
  uuid: string,
  name: string,
  description: string,
  isCustomizable: boolean,
  groupName?: string,
  defaultText?: string,
  image: {
    path: string,
  },
  subThemes: DesignPropertyReference[],
  themes: DesignPropertyReference[],
  styles: DesignPropertyReference[],
  colors: DesignPropertyReference[],
  tags: DesignPropertyReference[],
  updatedAt?: string,
  approvalRequestedAt?: string,
  approvedAt?: string,
  publishedAt?: string,
  user?: {
    name: string,
  },
  activeConfigurations?: {
    aggregate: {
      count: number,
    }
  }
  unapprovedConfigurations?: {
    aggregate: {
      count: number,
    }
  }
}

export const DESIGN_PROPERTIES_QUERY = gql`
  query designProperties($id: Int!) {
    design: design_by_pk(id: $id) {
      id
      uuid
      name
      description
      isCustomizable
      groupName
      defaultText
      image {
        path
        isPattern
      }
      themes {
        id: designThemeId
      }
      subThemes {
        id: designSubThemeId
      }
      styles {
        id: designStyleId
      }
      colors {
        id: designColorId
      }
      tags {
        id: designTagId
      }
    }
    themes: design_theme(order_by: { name: asc } ) {
      id
      name
      subThemes(order_by: { name: asc }) {
        id
        name
      }
    }
    styles: design_style(order_by: { name: asc } ) {
      id
      name
    }
    colors: design_color(order_by: { name: asc } ) {
      id
      name
    }
    tags: design_tag {
      id
      name
    }
  }
`;

export interface DesignPropertiesQueryResult {
  design: Design,
  themes: DesignPropertyReference[],
  styles: DesignPropertyReference[],
  colors: DesignPropertyReference[],
  tags: DesignPropertyReference[],
}

export interface DesignFilterQueryResult {
  themes: DesignPropertyReference[],
  styles: DesignPropertyReference[],
  colors: DesignPropertyReference[],
  designers: DesignPropertyReference[],
}

export const DESIGN_PREVIEW_QUERY = gql`
  query designPreview($id: Int!) {
    design: design_by_pk(id: $id) {
      id
      uuid
      name
      designConfigurations(where: {is_active: {_eq: true}}, order_by: {productVariant: {product: {categoryId: asc}, id: asc}}) {
        id
        approvedAt
        productVariant {
          name
          productScenes {
            productScene {
              name
            }
          }
        }
      }
    }
  }
`;

export const DESIGN_PREVIEW_SUBSCRIPTION = gql`
  subscription designPreview($id: Int!) {
    design: design_by_pk(id: $id) {
      id
      uuid
      name
      description
      themes {
        theme {
          name
        }
      }
      styles {
        style {
          name
        }
      }
      colors {
        color {
          name
        }
      }
      tags {
        tag {
          name
        }
      }
      approvalRequestedAt
      declineReason
      approvedAt
      designConfigurations(where: {is_active: {_eq: true}}, order_by: {productVariant: {product: {categoryId: asc}, id: asc}}) {
        id
        approvedAt
        productVariant {
          name
          productScenes {
            productScene {
              name
            }
          }
        }
      }
    }
  }
`;

export interface DesignPreviewQueryResult {
  design: {
    id: number,
    uuid: number,
    name: string,
    description: string,
    themes: [{
      theme: {
        name: string,
      }
    }],
    styles: [{
      style: {
        name: string,
      }
    }],
    colors: [{
      color: {
        name: string,
      }
    }],
    tags: [{
      tag: {
        name: string,
      }
    }],
    approvalRequestedAt: string,
    declineReason: string,
    approvedAt: string,
    designConfigurations: [{
      id: number,
      approvedAt: string,
      productVariant: {
        name: string,
        productScenes: [{
          productScene: {
            name: string,
          }
        }]
      }
    }]
  },
}

export interface DesignPreviewSubscriptionResult extends DesignPreviewQueryResult {
}


export const PUBLISHING_SUBSCRIPTION = gql`
  subscription designPublishing($limit: Int, $offset: Int) {
    designs: design(limit: $limit, offset: $offset, where: {deletedAt: {_is_null: true}, declineReason: {_is_null: true}, approvalRequestedAt: {_is_null: false}, publishedAfterApprovalRequested: {_eq: false}}, order_by: {approvalRequestedAt: desc, user: {name: asc}}) {
      id
      uuid
      name
      image {
        path
        isPattern
      }
      user {
        name
      }
      updatedAt: updated_at
      approvalRequestedAt
      approvedAt
      publishedAt
      activeConfigurations: designConfigurations_aggregate(where: {is_active: {_eq: true}}) {
        aggregate {
          count
        }
      }
      unapprovedConfigurations: designConfigurations_aggregate(where: {is_active: {_eq: true}, approvedAt: {_is_null: true}}) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const PUBLISHING_UPDATED_SUBSCRIPTION = gql`
  subscription designPublishingUpdated($limit: Int, $offset: Int) {
    designs: design(limit: $limit, offset: $offset, where: {deletedAt: {_is_null: true}, declineReason: {_is_null: true}, designConfigurations: {updatedAfterPublished: {_eq: true}}}, order_by: {updated_at: asc, user: {name: asc}}) {
      id
      uuid
      name
      image {
        path
        isPattern
      }
      user {
        name
      }
      updatedAt: updated_at
      approvalRequestedAt
      approvedAt
      publishedAt
      activeConfigurations: designConfigurations_aggregate(where: {is_active: {_eq: true}}) {
        aggregate {
          count
        }
      }
      unapprovedConfigurations: designConfigurations_aggregate(where: {is_active: {_eq: true}, approvedAt: {_is_null: true}}) {
        aggregate {
          count
        }
      }
    }
  }
`;

export interface PublishingSubscription {
  designs: [Design],
}

export interface DesignsQuery extends DesignsSubscription, DesignFilterQueryResult {
  // designs: Design[],
  designsInfo: {
    aggregate: {
      count: number
    }
  },
}

export const DESIGNS_QUERY = gql`
  query designs($where: design_bool_exp!, $limit: Int, $offset: Int) {
    designs: design(limit: $limit, offset: $offset, where: $where, order_by: {userId: asc, updated_at: desc}) {
      id
      uuid
      isActive
      publishedAt
      declineReason
      name
      image {
        path
        isPattern
      }
      user {
        name
      }
      approved: designConfigurations_aggregate(where: {is_active: {_eq: true}, approvedAt: {_is_null: false}}) {
        aggregate {
          count
        }
      }
      unapproved: designConfigurations_aggregate(where: {is_active: {_eq: true}, approvedAt: {_is_null: true}}) {
        aggregate {
          count
        }
      }
      updatedAt: updated_at
    }
    designsInfo: design_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    themes: design_theme {
      id
      name
    }
    styles: design_style {
      id
      name
    }
    colors: design_color {
      id
      name
    }
    designers: users(order_by: {name: asc}) {
      id
      name
    }
  }
`;
