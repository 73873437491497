import clsx from "clsx";
import React, {MouseEvent} from 'react';
import {useQuery, useSubscription} from "@apollo/client";
import pLimit from 'p-limit';
import {DESIGN_PREVIEW_QUERY, DESIGN_PREVIEW_SUBSCRIPTION, DesignPreviewQueryResult} from "./queries";
import {SimpleImg} from "react-simple-img";
import RequireRole, {DESIGN_MANAGER, DESIGNER} from "../../components/RequireRole/RequireRole";
import OyButton from "@oyoyo/ui/src/components/OyButton/OyButton";
import {useDispatch} from "react-redux";
import {approveConfiguration, publishDesign, requestPublishDesign} from "../../store/reducer/design";
import {Link} from "react-navi";
import {ReactComponent as IconBack} from "line-awesome/svg/angle-left-solid.svg";
import {DesignPropertiesFormSchema} from "../../components/DesignPropertiesForm/DesignPropertiesForm";
import {ReactComponent as IconProperties} from "line-awesome/svg/tags-solid.svg";
import { useToasts } from 'react-toast-notifications';

// import {useLockBodyScroll} from 'react-use';

export interface DesignPreviewContainerProps {
  id: number,
}

// TODO eigenschaften anzeigen

const DesignPreviewContainer: React.FC<DesignPreviewContainerProps> = ({id}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const toggleApproved = (id: number, approved: boolean) => (event: MouseEvent) => {
    dispatch(approveConfiguration(id, approved));
  };
  const approveConfigurations = (ids: number[], approved: boolean) => async (event: MouseEvent) => {
    // TODO single request would ne nicer... improve when time ;-)
    const limit = pLimit(8);
    await Promise.all(ids.map((id) => {
      return limit(() => new Promise(resolve => {
        dispatch(approveConfiguration(id, true));
        setTimeout(resolve, 500);
      }));
    }));
  };
  const requestPublish = (id: number) => (event: MouseEvent) => {
    dispatch(requestPublishDesign(id));
    addToast('Wir werden deine eingereichten Produkte nun prüfen.', { appearance: 'success', autoDismiss: true });
  };
  const publish = (id: number) => (event: MouseEvent) => {
    dispatch(publishDesign(id))
    addToast('Die Produkte werden nun veröffentlicht.', { appearance: 'success', autoDismiss: true });
  };
  const { data, loading } = useSubscription<DesignPreviewQueryResult>(
    DESIGN_PREVIEW_SUBSCRIPTION,
    { variables: { id } }
  );
  const designIsInvalid = !DesignPropertiesFormSchema.isValidSync(data?.design);
  const canSubmit = !!data?.design?.declineReason || !data?.design?.approvalRequestedAt
  const approvalDisabled = designIsInvalid || !canSubmit;
  return (
    <div>
      {loading && 'loading...'}
      {(!loading && data && data.design) && (
        <>
          <Link className='flex items-center mb-4' href={`/design/products/${id}/overview`}>
            <IconBack className='w-4 h-4 mr-1'/>
            zurück zur Übersicht
          </Link>
          <div className='grid grid-columns-12 grid-gap-2 bg-gray-100 rounded border border-gray-300 p-4'>
            <div className='font-medium col-span-3'>Titel</div>
            <div className='col-span-9'>{data.design.name || 'nicht angegeben'}</div>
            <div className='font-medium col-span-3'>Beschreibung</div>
            <div className='col-span-9'>{data.design.description || 'nicht angegeben'}</div>
            <div className='font-medium col-span-3'>Kategorien</div>
            <div className='col-span-9'>{data.design.themes.flatMap(({theme: {name}}) => name).join(', ') || 'nicht angegeben'}</div>
            <div className='font-medium col-span-3'>Stile</div>
            <div className='col-span-9'>{data.design.styles.flatMap(({style: {name}}) => name).join(', ') || 'nicht angegeben'}</div>
            <div className='font-medium col-span-3'>Hauptfarben</div>
            <div className='col-span-9'>{data.design.colors.flatMap(({color: {name}}) => name).join(', ') || 'nicht angegeben'}</div>
            <div className='font-medium col-span-3'>Tags</div>
            <div className='col-span-9'>{data.design.tags.flatMap(({tag: {name}}) => name).join(', ') || 'nicht angegeben'}</div>
            {designIsInvalid ? (
              <div className="col-span-12 mt-4">
                <Link className='my-1' href={`/design/products/${id}/properties`}>
                  <OyButton size='sm' color='red' label={'Die Produkteigenschaften müssen noch berarbeitet werden!'} icon={<IconProperties className={'w-6 h-6 fill-current mr-2'} />}/>
                </Link>
              </div>
            ) : null}
          </div>
          <RequireRole role={DESIGN_MANAGER}>
            <div className='mt-4'>
              {data.design.designConfigurations.map(({id: configurationId, approvedAt, productVariant}, key) => (
                <a key={configurationId} href={`#c${configurationId}`} className={clsx('mr-4', {'text-green-500': approvedAt, 'text-red-500': !approvedAt})}>
                  {productVariant.name}
                </a>
              ))}
            </div>
          </RequireRole>
          {data.design.designConfigurations.map(({id: configurationId, approvedAt, productVariant}, key) => (
            <div key={configurationId} id={`c${configurationId}`}>
              <div className='text-xl font-medium leading-none mt-8 mb-2 flex items-center'>
                <Link href={`/design/products/${id}/edit/${configurationId}`}>{productVariant.name}</Link>
                <RequireRole role={DESIGN_MANAGER}>
                  <OyButton label={approvedAt ? 'Freigabe zurückziehen' : 'Freigeben'} color={approvedAt ? 'red' : 'green'} size={'sm'} className='text-base ml-2' onClick={toggleApproved(configurationId, !approvedAt)} />
                </RequireRole>
              </div>
              <div className='grid grid-columns-4 grid-gap-4'>
              {productVariant.productScenes.map(({productScene: {name}}) => (
                <div className='aspect-ratio-square relative' key={name}>
                  <SimpleImg className='absolute inset-0 rounded border border-gray-300' src={`${process.env.REACT_APP_STORAGE_PREFIX}/products/${data.design.uuid}/rendered/${name}.jpg`} placeholder={false}/>
                </div>
              ))}
              </div>
            </div>
          ))}
          {!designIsInvalid ? (
            <>
              <RequireRole role={DESIGNER}>
                <OyButton className='mt-4' label={approvalDisabled && !designIsInvalid ? 'Zur Prüfung eingereicht' : !!data?.design?.declineReason ? 'Erneut zur Prüfung einreichen': 'Zur Prüfung einreichen'} onClick={requestPublish(id)} disabled={approvalDisabled} />
              </RequireRole>
              <RequireRole role={DESIGN_MANAGER}>
                <OyButton className='mt-4 mr-4' label={'Alle Produkte freigeben'} color='purple' onClick={approveConfigurations(data.design.designConfigurations.filter(({approvedAt}) => !approvedAt).map(({id}) => id), true)} disabled={!approvalDisabled} />
                <OyButton className='mt-4' label={'Produkte veröffentlichen'} color='purple' onClick={publish(id)} disabled={!approvalDisabled} />
              </RequireRole>
            </>
        ) : null}
        </>
      )}
    </div>
  );
};

// DesignPreviewContainer.defaultProps = {
// };

export default DesignPreviewContainer;
