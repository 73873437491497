import React, {useCallback, useEffect, useRef} from 'react';
import {REACT_APP_API_MERCURE_ENDPOINT} from "api/mercure";
import Notifications from "components/Notifications/Notifications";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { useToasts } from 'react-toast-notifications';
import {AppState} from "store/reducer/root";
import {MessagingState, setConnectionState, addMessage} from "store/reducer/messaging";
import {UserState} from "../../store/reducer/user";
import uppy from 'api/uppy';

export interface NotificationsContainerProps {

}

const NotificationsContainer: React.FC<NotificationsContainerProps> = () => {
  // const [connected, setConnected] = useState<boolean>(false);
  const { email }: UserState = useSelector((state: AppState) => state.user, shallowEqual);
  const { online, messages }: MessagingState = useSelector((state: AppState) => state.messaging, shallowEqual);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const setOnline = useCallback(
    (online) => dispatch(setConnectionState(online)),
    [dispatch]
  );
  const onMessage = useCallback(
    (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      console.log(data);
      if (typeof data?.error !== 'undefined') {
        switch (data.error.type) {
          case 'DESIGN_IMAGE_INVALID':
            addToast('Upload verworfen. Das hochgeladene Design muss im sRGB-Format vorliegen.', { appearance: 'error', autoDismiss: false });
            const file = uppy.getFiles().find(file => (file as any).tus.uploadUrl.indexOf(data.error.data.path) !== -1)
            if (file) {
              uppy.removeFile(file.id);
            }
            break;
        }
      } else {
        dispatch(addMessage(event));
      }
    },
    [dispatch]
  );
  const eventSourceRef = useRef<EventSource|null>(null);
  useEffect(() => {
    console.log('messaging effect');
    const url = new URL(REACT_APP_API_MERCURE_ENDPOINT);
    url.searchParams.append('topic', 'https://designer.textilwerk.io/designs/{id}');
    url.searchParams.append('topic', `https://designer.textilwerk.io/users/${email}`);
    eventSourceRef.current = new EventSource(url.toString());
    eventSourceRef.current.onopen = (e) => {
      setOnline(true);
    };
    eventSourceRef.current.onerror = (e) => {
      setOnline(false);
    };
    eventSourceRef.current.onmessage = onMessage;
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, [setOnline, onMessage]);
  return (
    <Notifications connected={online} messages={messages} />
  );
};

export default NotificationsContainer;
