import React, {MouseEvent, useCallback, useEffect, useRef} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useClickAway, useToggle} from "react-use";
import RequireRole, {DESIGN_MANAGER} from "../components/RequireRole/RequireRole";
import {PRODUCT_VARIANT_FLOOR_CUSHION} from "../constants";
import {AppState} from "../store/reducer/root";
import DesignCanvasContainer from "../containers/DesignCanvas/DesignCanvasContainer";
import {
  replaceDesignConfigurationImage,
  requestConfigurationSettings,
  revertDesignConfiguration,
  saveDesignConfiguration
} from "../store/reducer/design";
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";
import OyButton from "@oyoyo/ui/src/components/OyButton/OyButton";
import clsx from "clsx";
import {useNavigation} from "react-navi";
import {setSide, toggleLocked, undoSide, update} from "../containers/DesignCanvas/actions";
import {ReactComponent as IconBack} from "line-awesome/svg/angle-left-solid.svg";
import {ReactComponent as IconUndo} from "line-awesome/svg/undo-alt-solid.svg";
import {ReactComponent as IconBackgroundColor} from "line-awesome/svg/tint-solid.svg";
import {ReactComponent as IconZoom} from "line-awesome/svg/search-plus-solid.svg";
import {ReactComponent as IconSwapDesign} from "line-awesome/svg/exchange-alt-solid.svg";
import {ReactComponent as IconCenterHorizontally} from "../images/icons/ic_centering-horizontally.svg";
import {ReactComponent as IconCenterHorizontallyActive} from "../images/icons/ic_centering-horizontally-ACT.svg";
import {ReactComponent as IconCenterVertically} from "../images/icons/ic_centering-vertically.svg";
import {ReactComponent as IconCenterVerticallyActive} from "../images/icons/ic_centering-vertically-ACT.svg";
// @ts-ignore
import Slider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';
import UploadsOverlayContainer from "../containers/Upload/UploadsOverlayContainer";
import {motion, AnimatePresence} from "framer-motion";
import {useSubscription} from "@apollo/client";
import gql from "graphql-tag";
import { ChromePicker } from 'react-color';
import { useToasts } from 'react-toast-notifications';
import {ReactComponent as IconLock} from "line-awesome/svg/lock-solid.svg";
import {ReactComponent as IconUnlock} from "line-awesome/svg/lock-open-solid.svg";
import Magnifier from "react-magnifier";

export interface ProductVariantProps {
  id: number,
  variantId: number,
}

const ProductVariant: React.FC<ProductVariantProps> = ({id, variantId}) => {
  const { data, loading, error } = useSubscription<{designConfiguration: { renderedAt: string }}>(
    gql`
      subscription designConfigurationRendered($id: Int!) {
        designConfiguration: design_configuration_by_pk(id: $id) {
          renderedAt: rendered_at
        }
      }
    `,
    { variables: { id: variantId } }
  );
  const renderedAt = data?.designConfiguration.renderedAt || '';
  const [collapsed, toggleCollapsed] = useToggle(true);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const navigation = useNavigation();
  useEffect(() => {
    dispatch(requestConfigurationSettings(variantId));
  }, [variantId]);
  const {configuration}: any = useSelector((state: AppState) => state.design, shallowEqual);
  const {side, front, back, detail, custom, locked, history} = useSelector((state: AppState) => state.designCanvas, shallowEqual);
  const settings = side === 'front' ? front : back;
  const image = side === 'front' ? configuration?.frontSettings?.image : configuration?.backSettings?.image;
  const productVariantId = configuration?.productVariant?.id;
  const undoHistory = history[side].length;
  const IconCenterH = settings.left === 0.5 ? IconCenterHorizontallyActive : IconCenterHorizontally;
  const IconCenterV = settings.top === 0.5 ? IconCenterVerticallyActive : IconCenterVertically;
  const frontSide = (event: MouseEvent) => {
    dispatch(setSide('front'));
  };
  const backSide = (event: MouseEvent) => {
    if (productVariantId >= 36 && productVariantId <= 42) {
      return;
    }
    dispatch(setSide('back'));
  };
  const undo = (event: MouseEvent) => {
    dispatch(undoSide(side));
  };
  const replaceImage = (imageId: number) => {
    const settingsId = side === 'front' ? configuration.frontSettings.id : configuration.backSettings?.id;
    dispatch(replaceDesignConfigurationImage(settingsId, imageId));
    if (locked && side === 'front') {
      dispatch(replaceDesignConfigurationImage(configuration.backSettings?.id, imageId)); // TODO test this!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    }
    toggleCollapsed();
  };
  const updateDesignCanvas = useCallback(
    (state) => dispatch(update(state)),
    [dispatch]
  );
  const save = (event: MouseEvent) => {
    const frontSettings = {id: configuration.frontSettings.id, ...front};
    const backSettings = configuration.backSettings && {id: configuration.backSettings?.id, ...back};
    // TODO hier vergleichen und nen hinweis aufzeigen, sobald es unterschiede gibt?!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    dispatch(saveDesignConfiguration(id, variantId, detail.left, detail.top, custom?.left, custom?.top, custom?.width, custom?.height, frontSettings, backSettings));
    addToast('Einstellungen gespeichert', { appearance: 'success', autoDismiss: true });  // TODO this may not be true
  };
  const revert = (event: MouseEvent) => {
    dispatch(revertDesignConfiguration('TODO something?'));
    navigation.navigate(`/design/products/${id}/overview#dc${configuration.id}`);
  };
  const updateLocked = (event: MouseEvent) => {
    if (productVariantId >= 36 && productVariantId <= 42) {
      return;
    }
    dispatch(toggleLocked());
  };
  const pdf = (event: MouseEvent) => {
    // TODO fetch POST with bearer token and then return secret URL to redirect to
    window.open(`${process.env.REACT_APP_API_NESTJS}/designer/configuration/${variantId}/pdf`, '_blank');
  };

  const IconLocked = locked ? IconLock : IconUnlock;

  const [showColorPicker, toggleColorPicker] = useToggle(false);
  const colorPickerRef = useRef<HTMLDivElement>(null);
  useClickAway(colorPickerRef, toggleColorPicker);

  const previewRef = useRef<HTMLImageElement | null>(null);
  const zoomedRef = useRef<HTMLImageElement | null>(null);
  const [zoomed, toggleZoomed] = useToggle(false);
  const zoom = (event: MouseEvent) => {
    toggleZoomed();
    console.log('zoom', zoomed, previewRef);
  };
  useClickAway(zoomedRef, toggleZoomed);

  const fullWidth = false; // configuration?.productVariant?.id === PRODUCT_VARIANT_FLOOR_CUSHION;
  // alert('dont allow backside!!!')

  // const render = (event: MouseEvent) => {
  //   // dispatch(renderDesignConfiguration());
  //   (async () => {
  //     fetch(`${process.env.REACT_APP_API_NESTJS}/designer/design/${id}/render`, {
  //       method: 'POST',
  //       mode: 'cors',
  //       cache: 'no-cache',
  //       headers: {
  //         'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}`, // TODO use redux or global variable to read accessToken
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         productVariants: [
  //           configuration.productVariant.id,
  //           // 24,
  //           // 25,
  //           // 26,
  //           // 27,
  //           // 28,
  //         ]
  //       })
  //     })
  //       .then(response => {
  //         if (!response.ok) {
  //           throw new Error('Render design failed')
  //         }
  //         return response.json()
  //       })
  //       .then((json) => {
  //         console.log(json);
  //       })
  //       .catch(e => console.error('Render design failed', e))
  //     ;
  //   })();
  // };
  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-01 bg-cover bg-fixed'}>
      {/*<div>product #{id} edit #{variantId}</div>*/}
      {/*<div>{JSON.stringify(configuration)}</div>*/}
      <div className={clsx('grid grid-gap-8', {
        'grid-columns-1': fullWidth,
        'grid-columns-2': !fullWidth,
      })}>
        { fullWidth && (<div className='text-3xl font-medium'>{configuration?.productVariant?.name}</div>)}
        <div>
          <div className='flex justify-between text-sm mb-4'>
            <button className='flex items-center' onClick={revert}>
              <IconBack className='w-4 h-4 mr-1'/>
              zurück zur Übersicht
            </button>
            <button className='flex items-center disabled:text-gray-500 disabled:cursor-not-allowed fill-current' disabled={undoHistory < 2} onClick={undo}>
              <IconUndo className='w-4 h-4 mr-1'/>
              Rückgängig
            </button>
          </div>
          <DesignCanvasContainer/>
        </div>
        <div>
          <div className='text-3xl font-medium mb-4'>{configuration?.productVariant?.name}</div>
          <div className='flex items-center'>
            <button onClick={frontSide} className={clsx(
              'uppercase font-medium border-b-2 hover:border-red-500 disabled:cursor-not-allowed border-dotted disabled:text-gray-500 mr-4',
              {
                'border-red-500': side === 'front',
              },
            )}>Vorderseite</button>
            <button onClick={backSide} className={clsx(
              'uppercase font-medium border-b-2 hover:border-red-500 disabled:cursor-not-allowed border-dotted disabled:text-gray-500 mr-4',
              {
                'border-red-500': side === 'back',
              },
            )} disabled={locked || (!configuration?.productVariant?.product?.hasBackside ?? true)}>Rückseite</button>
            {configuration?.productVariant?.product?.hasBackside && (
              <button onClick={updateLocked}><IconLocked className='w-6 h-6'/></button>
            )}
          </div>
          <div className='bg-white shadow-xl p-4 mt-4 mb-12'>
            <div className='uppercase font-medium mb-4'>Produktvorschau</div>
            <div className='flex items-center'>
              <img ref={previewRef} className='w-32 h-32 mr-4' src={`${process.env.REACT_APP_STORAGE_PREFIX}/products/${configuration?.design?.uuid}/rendered/${configuration?.productVariant?.productScenes[0].productScene?.name}.320.jpg?renderedAt=${encodeURIComponent(renderedAt)}`} alt={''}/>
              {/*<motion.img layoutTransition ref={previewRef} className={clsx({'w-32 h-32 mr-4': !zoomed, 'w-64 h-64 absolute': zoomed})} src={`${process.env.REACT_APP_STORAGE_PREFIX}/products/${configuration?.design?.uuid}/rendered/${configuration?.productVariant?.productScenes[0].productScene?.name}.320.jpg`} alt={''}/>*/}
              <div className='w-full fill-current text-sm'>
                <div className='relative'>
                  <button onClick={toggleColorPicker} className='flex items-center my-1'>
                    <IconBackgroundColor className='w-6 h-6 mr-2'/>
                    Hintergrundfarbe wählen
                  </button>
                  {showColorPicker && (<div className='absolute z-10' ref={colorPickerRef}>
                    <ChromePicker  color={settings.backgroundColor || 'transparent'} onChange={(color) => { updateDesignCanvas({
                      backgroundColor: color?.hex,
                    }) }}/>
                  </div>)}
                </div>
                <button onClick={zoom} className='flex items-center my-1'>
                  <IconZoom className='w-6 h-6 mr-2'/>
                  Großes Bild anzeigen
                </button>
                <div className='border-t-2 border-gray-300 border-dotted my-3'/>
                <button onClick={toggleCollapsed} className='flex items-center my-1'>
                  <IconSwapDesign className='w-6 h-6 mr-2'/>
                  Bild ersetzen
                </button>
              </div>
            </div>
          </div>
          <div className='border-b-2 border-gray-400 border-dotted mb-8 px-4'>
            <div className='uppercase font-medium mb-4'>Platzieren und Ausrichten</div>
            <div className='flex my-8'>
              <button onClick={() => { updateDesignCanvas({
                left: 0.5
              }) }}>
                <IconCenterH className='w-8 h-8'/>
              </button>
              <button className='ml-2' onClick={() => { updateDesignCanvas({
                top: 0.5
              }) }}>
                <IconCenterV className='w-8 h-8'/>
              </button>
            </div>
          </div>
          <div className='border-b-2 border-gray-400 border-dotted mb-8 px-4'>
            <div className='uppercase font-medium mb-4'>Position</div>
            <div className='grid grid-gap-4 grid-columns-2 my-8'>
              <div>
                Horizontal: {(settings.left * 100).toLocaleString()}%
              </div>
              <div>
                Vertikal: {(settings.top * 100).toLocaleString()}%
              </div>
            </div>
          </div>
          <div className='border-b-2 border-gray-400 border-dotted mb-8 px-4'>
            <div className='uppercase font-medium mb-4'>
              Bild skalieren
              <input className='ml-4 mr-2 text-sm border border-gray-300 p-1' type={'number'} min={1} max={120} value={settings.scale} onChange={(event) => { updateDesignCanvas({
                scale: parseInt(event.target.value)
              }) }}/>
              <span className='text-sm font-normal'>%</span>
            </div>
            <div className='grid grid-gap-4 grid-columns-1 mt-8 mb-16'>
              {/* https://reacttraining.com/reach-ui/slider */}
              <Slider onChange={(value: string) => {updateDesignCanvas({
                scale: parseInt(value)
              })}} value={settings.scale} min={1} max={120} marks={{'1': '1%', '50': '50%', '100': '100%', '120': '120%'}}/>
            </div>
          </div>
          {image?.isPattern ? (
            <div className='border-b-2 border-gray-400 border-dotted mb-8 px-4'>
              <div className='uppercase font-medium mb-4'>Bild rotieren</div>
              <div className='my-8'>
                Momentan unterstützen wir keine Rotation für Muster.
              </div>
            </div>
          ) : (
            <div className='border-b-2 border-gray-400 border-dotted mb-8 px-4'>
              <div className='uppercase font-medium mb-4'>
                Bild rotieren
                <input className='ml-4 mr-2 text-sm border border-gray-300 p-1' type={'number'} min={0} max={360} value={settings.rotation} onChange={(event) => { updateDesignCanvas({
                  rotation: parseInt(event.target.value)
                }) }}/>
                <span className='text-xl font-normal'>°</span>
              </div>
              <div className='grid grid-gap-4 grid-columns-1 mt-8 mb-16'>
                {/* https://reacttraining.com/reach-ui/slider */}
                <Slider onChange={(value: string) => {updateDesignCanvas({
                  rotation: parseInt(value)
                })}} value={settings.rotation} min={0} max={360} marks={{'0': '0°', '90': '90°', '180': '180°', '270': '270°', '360': '360°'}}/>
              </div>
            </div>
          )}
          <OyButton className='mt-4 mr-4' color={'red'} label={'Änderungen verwerfen'} onClick={revert}/>
          <OyButton className='mt-4 mr-4' label={'Änderungen anwenden'} onClick={save}/>
          <RequireRole role={DESIGN_MANAGER}>
            <OyButton className='mt-4 mr-4' color='purple' label={'PDF anzeigen'} onClick={pdf} />
          </RequireRole>
          {/*<OyButton className='ml-4' label={'Render'} color={'purple'} onClick={render}/>*/}
        </div>
      </div>
      <AnimatePresence initial={false}>
        {zoomed && (
          <div className='fixed z-2000 inset-0 overflow-auto flex items-center justify-center cursor-pointer'>
            <motion.div
              className='fixed w-full h-full bg-gray-900'
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeInOut", duration: 0.3 }}
            />
            <div className='w-3/4'>
              <motion.div
                className='aspect-ratio-square relative'
                initial={{ scale: 0.1, x: 0, y: 0 }}
                animate={{ scale: 1, x: 0, y: 0 }}
                exit={{ scale: 0.1, x: 0, y: 0 }}
                transition={{ ease: "easeInOut", duration: 0.3 }}
              >
                <img className='absolute inset-0' src={`${process.env.REACT_APP_STORAGE_PREFIX}/products/${configuration?.design?.uuid}/rendered/${configuration?.productVariant?.productScenes[0].productScene?.name}.jpg?renderedAt=${encodeURIComponent(renderedAt)}`} alt={''}/>
                {/*<img className='absolute inset-0' src={`${'https://storage.designer.textilwerk.io'}/products/${configuration?.design?.uuid}/rendered/${configuration?.productVariant?.productScenes[0].productScene?.name}.jpg?renderedAt=${encodeURIComponent(renderedAt)}`} alt={''}/>*/}
                {/*<Magnifier className='absolute inset-0' src={`${process.env.REACT_APP_STORAGE_PREFIX}/products/${configuration?.design?.uuid}/rendered/${configuration?.productVariant?.productScenes[0].productScene?.name}.jpg?renderedAt=${encodeURIComponent(renderedAt)}`}/>*/}
                {/*<Magnifier className='absolute inset-0' src={`${'https://storage.designer.textilwerk.io'}/products/${configuration?.design?.uuid}/rendered/${configuration?.productVariant?.productScenes[0].productScene?.name}.jpg?renderedAt=${encodeURIComponent(renderedAt)}`}/>*/}
                <div ref={zoomedRef} />
              </motion.div>
            </div>
          </div>
        )}
      </AnimatePresence>
      {collapsed || <UploadsOverlayContainer onClick={replaceImage}/>}
      {/*<div>{front.left}</div>*/}
      {/*<div>{front.top}</div>*/}
    </OyContainer>
  );
};

// ProductVariant.defaultProps = {
// };

export default ProductVariant;
