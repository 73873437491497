import DesignProductSettings from "@oyoyo/shared/src/types/DesignProductSettings";
import DesignProduct from "@oyoyo/shared/src/types/DesignProduct";
import DesignImage from "@oyoyo/shared/src/types/DesignImage";

export interface DesignCanvasState {
  side: 'front' | 'back',
  detail: {
    left: number,
    top: number,
  },
  custom: {
    left: number,
    top: number,
    width: number,
    height: number,
  },
  front: DesignProductSettings,
  back: DesignProductSettings,
  locked: boolean,
  history: {
    front: DesignProductSettings[],
    back: DesignProductSettings[],
  }
}

export const UPDATE = 'UPDATE';
export const UPDATE_DETAILS = 'UPDATE_DETAILS';
export const UPDATE_CUSTOM = 'UPDATE_CUSTOM';
export const RESET = 'RESET';
export const DEFAULTS = 'DEFAULTS';
export const SET_DESIGN_SIDE = 'SET_DESIGN_SIDE';
export const UNDO_SIDE = 'UNDO_SIDE';
export const COPY_FROM = 'COPY_FROM';
export const TOGGLE_LOCKED = 'TOGGLE_LOCKED';

interface UpdateAction {
  type: typeof UPDATE
  payload: DesignProductSettings
}

interface UpdateDetailsAction {
  type: typeof UPDATE_DETAILS
  payload: { left: number, top: number }
}

interface UpdateCustomAction {
  type: typeof UPDATE_CUSTOM
  payload: { left: number, top: number, width: number, height: number }
}

interface ResetAction {
  type: typeof RESET
  payload: {
    front: DesignProductSettings,
    back?: DesignProductSettings,
  }
}

interface DefaultsAction {
  type: typeof DEFAULTS
  payload: {
    product: DesignProduct,
    image: DesignImage,
  }
}

export interface SetDesignSideAction {
  type: typeof SET_DESIGN_SIDE,
  payload: 'front' | 'back',
}

export interface UndoSideAction {
  type: typeof UNDO_SIDE,
  payload: 'front' | 'back',
}

export interface CopyFromAction {
  type: typeof COPY_FROM,
  payload: 'front' | 'back',
}

export interface ToggleLockedAction {
  type: typeof TOGGLE_LOCKED,
}

export type DesignCanvasActionTypes = UpdateAction | UpdateDetailsAction | UpdateCustomAction | ResetAction | DefaultsAction | SetDesignSideAction | UndoSideAction | CopyFromAction | ToggleLockedAction;
