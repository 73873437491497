import React from 'react';
import jwtDecode from "jwt-decode";
import {UserState} from "../../store/reducer/user";
import {shallowEqual, useSelector} from "react-redux";
import {AppState} from "../../store/reducer/root";

export const DESIGNER = 'designer';
export const DESIGN_MANAGER = 'designmanager';

export interface RequireRoleProps {
  role: string,
}

export function hasRole(accessToken: string, role: string): boolean {
  const token = jwtDecode(accessToken);
  return token['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].indexOf(role) !== -1;
}

const RequireRole: React.FC<RequireRoleProps> = ({role, children}) => {
  const { accessToken }: UserState = useSelector((state: AppState) => state.user, shallowEqual);
  const granted = hasRole(accessToken, role);

  if (!granted) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
};

RequireRole.defaultProps = {
  role: 'designer',
};

export default RequireRole;
