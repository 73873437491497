import React from 'react';
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";
import ForgotPasswordForm from "../components/ForgotPasswordForm/ForgotPasswordForm";

export interface ForgotPasswordProps {
}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-04 bg-cover bg-fixed flex items-center justify-center'}>
      <ForgotPasswordForm/>
    </OyContainer>
  );
};

// ForgotPassword.defaultProps = {
// };

export default ForgotPassword;
