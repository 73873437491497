import {Margins} from "@oyoyo/shared/src/types/DesignProduct";
import React, {useEffect, useRef, useState, useMemo} from 'react';
import { useMeasure, useKey } from "react-use";
import {Stage, Layer, Rect, Group} from "react-konva/lib/ReactKonvaCore";
import "konva/lib/shapes/Rect";
import "konva/lib/shapes/Image";
import "konva/lib/shapes/Transformer";
import {MAX_SCALE} from "@oyoyo/shared/src/constants/design";
import useImage from "use-image";
import {PRODUCT_VARIANT_FABRIC, PRODUCT_VARIANT_FLOOR_CUSHION} from "../../constants";
import CustomArea from "./CustomArea";
import DetailArea from "./DetailArea";
import {DesignCanvasProps, ImageProps, RectProps} from "./interfaces";
import {arrowHandler, getCustomAreaProps, getDetailAreaProps, getShapeProps} from "./functions";
import DesignImage from "./DesignImage";
import {rgbToHex} from "@oyoyo/shared/src/utils/conversions";
import TextBox from "./TextBox";

// const devicePixelRatio = 1; // window.devicePixelRatio || 1;
// const scaledPixel = (px: number): number => devicePixelRatio * px;

const getTotalSize = ({variantId, width, height, margins}: { variantId: number, width: number, height: number, margins: Margins }) => {
  switch (variantId) {
    case PRODUCT_VARIANT_FLOOR_CUSHION: {
      const totalWidth = 750;
      const totalHeight = 1250;
      return { totalWidth, totalHeight }
    }
    default: {
      const totalWidth = width + margins.left + margins.right;
      const totalHeight = height + margins.top + margins.bottom;
      return { totalWidth, totalHeight }
    }
  }
}

const getLocalMargins = ({variantId, margins}: { variantId: number, margins: Margins }): Margins => {
  switch (variantId) {
    case PRODUCT_VARIANT_FLOOR_CUSHION: {
      return {
        top: 625,
        right: 725,
        bottom: 125,
        left: 125,
      }
    }
    default: {
      return { ...margins }
    }
  }
}

const textBoxHeight = 25;
const textBoxHeightHalf = textBoxHeight / 2;

const DesignCanvas: React.FC<DesignCanvasProps> = ({product: {variantId, width, height, margins, isCustomizable}, design, custom, settings, detail, onChange, onDetailChange, onCustomChange}) => {
  // console.log('DesignCanvas FC', settings, design);
  const [ref, { width: w }] = useMeasure();
  // const patternRef = useRef<any>(undefined);
  const [image] = useImage(design.url, 'Anonymous');
  const { totalWidth, totalHeight } = useMemo(() => getTotalSize({variantId, width, height, margins}), [variantId, width, height, margins]);
  const localMargins = useMemo(() => getLocalMargins({variantId, margins}), [variantId, margins]);
  const displayScale = w / totalWidth;
  const stageWidth = totalWidth * displayScale;
  const stageHeight = totalHeight * displayScale;
  const [isOver, setOver] = useState<boolean>(false);
  const [shapeProps, setShapeProps] = useState<ImageProps>(getShapeProps(width, height, localMargins, design, settings));
  const [detailAreaProps, setDetailAreaProps] = useState<RectProps>(getDetailAreaProps(width, height, localMargins, detail));
  const [customAreaProps, setCustomAreaProps] = useState<RectProps>(getCustomAreaProps(width, height, localMargins, custom));
  // console.log(shapeProps.width / image.width);
  const fillPatternScale = image ? shapeProps.scaleX * (shapeProps.width / image.width) : 1;
  const handleOnChange = (shapeProps: ImageProps) => {
    setShapeProps(shapeProps);
    // console.log(shapeProps, );
    onChange({
      left: (shapeProps.x - localMargins.left) / width,
      top: (shapeProps.y - localMargins.top) / height,
      scale: Math.min(Math.round(shapeProps.scaleX * 100), MAX_SCALE),
      rotation: Math.round(shapeProps.rotation),
    });
  };
  const handleOnChangeDetail = (shapeProps: RectProps) => {
    setDetailAreaProps(shapeProps);
    onDetailChange({
      left: (shapeProps.x - localMargins.left) / width,
      top: (shapeProps.y - localMargins.top) / height,
    });
  };
  const handleOnChangeCustom = (shapeProps: RectProps) => {
    setCustomAreaProps(shapeProps);
    onCustomChange({
      left: (shapeProps.x - localMargins.left) / width,
      top: (shapeProps.y - localMargins.top) / height,
      width: shapeProps.width,
      height: shapeProps.height,
    });
  };
  useKey('+', () => {
    onChange({
      scale: settings.scale + 1,
    });
  }, {}, [settings.scale]);
  useKey('-', () => {
    onChange({
      scale: settings.scale - 1,
    });
  }, {}, [settings.scale]);
  useKey('ArrowUp', arrowHandler('up', settings, onChange), {}, [settings.top]);
  useKey('ArrowDown', arrowHandler('down', settings, onChange), {}, [settings.top]);
  useKey('ArrowLeft', arrowHandler('left', settings, onChange), {}, [settings.left]);
  useKey('ArrowRight', arrowHandler('right', settings, onChange), {}, [settings.left]);
  useEffect(() => {
    // console.log('effect', shapeProps);
    setShapeProps(getShapeProps(width, height, localMargins, design, settings));
    setDetailAreaProps(getDetailAreaProps(width, height, localMargins, detail));
    setCustomAreaProps(getCustomAreaProps(width, height, localMargins, custom));
  }, [width, height, localMargins, design, settings, detail, custom]);
  return (
    <div ref={ref} style={{width: '100%', border: '1px solid red'}}>
      <Stage
        width={stageWidth}
        height={stageHeight}
        scaleX={displayScale}
        scaleY={displayScale}
        onContentMouseover={(e) => {
          setOver(true);
        }}
        onContentMouseout={(e) => {
          setOver(false);
        }}
        onDblClick={(e) => {
          const stage = e.target.getStage();
          if (stage) {
            const position = stage.getPointerPosition();
            if (position) {
              const {x, y} = position;
              const [r, g, b] = e.target.getLayer().getContext().getImageData(x, y, 1, 1).data;
              // console.log('%c   ', `background: #${rgbToHex(r, g, b)};`);
              onChange({
                backgroundColor: `#${rgbToHex(r, g, b)}`,
              });
            }
          }
        }}
        // onClick={(e) => {
        //   console.log(e);
        //   e.evt.preventDefault();
        //   e.evt.stopPropagation();
        //   // e.preventDefault();
        // }}
        style={{backgroundColor: settings.backgroundColor}}
      >
        <Layer>
          {(image && design.isPattern) &&
            <Rect
              x={0}
              y={0}
              width={totalWidth}
              height={totalHeight}
              fillPatternImage={image}
              fillPatternRepeat={'repeat'}
              fillPatternX={shapeProps.x}
              fillPatternY={shapeProps.y}
              fillPatternOffsetX={0.5 * image.width}
              fillPatternOffsetY={0.5 * image.height}
              // fillPatternX={shapeProps.x - (0.5 * shapeProps.scaleX * shapeProps.width)}
              // fillPatternY={shapeProps.y - (0.5 * shapeProps.scaleY * shapeProps.height)}
              // fillPatternOffsetX={0}
              // fillPatternOffsetY={0}
              fillPatternScaleX={fillPatternScale}
              fillPatternScaleY={fillPatternScale}
              fillPatternRotation={shapeProps.rotation}
              listening={false}
              opacity={isOver ? 0.5 : 1}
            />
          }
          <DesignImage shapeProps={shapeProps} transform={isOver} image={image} isPattern={design.isPattern} onChange={handleOnChange}/>
          {variantId === PRODUCT_VARIANT_FLOOR_CUSHION && (
            <>
              {/*<Text*/}
              {/*  x={localMargins.left + width / 2}*/}
              {/*  y={25 / 2}*/}
              {/*  width={width}*/}
              {/*  height={25}*/}
              {/*  offsetX={width / 2}*/}
              {/*  offsetY={25 / 2}*/}
              {/*  rotation={270}*/}
              {/*  text={'50cm'}*/}
              {/*  align="center"*/}
              {/*  verticalAlign="middle"*/}
              {/*  fontSize={14}*/}
              {/*  fontFamily={'Apercu, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'}*/}
              {/*  fill={'blue'}*/}
              {/*  listening={false}*/}
              {/*  opacity={isOver ? 1 : 0}*/}
              {/*/>*/}
              <Group opacity={isOver ? 1 : 0}>
                <TextBox
                  x={localMargins.left}
                  y={localMargins.top - textBoxHeight}
                  width={width}
                  height={textBoxHeight}
                  rotation={0}
                  text={'50cm'}
                  fill={'blue'}
                />
                <TextBox
                  x={localMargins.left - 50 - textBoxHeightHalf}
                  y={50 + textBoxHeightHalf + height}
                  width={100}
                  height={textBoxHeight}
                  rotation={270}
                  text={'10cm'}
                  fill={'blue'}
                />
                <TextBox
                  x={localMargins.left + width - 50 + textBoxHeightHalf}
                  y={50 + textBoxHeightHalf + height}
                  width={100}
                  height={25}
                  rotation={90}
                  text={'10cm'}
                  fill={'blue'}
                />
                <TextBox
                  x={localMargins.left - 100}
                  y={localMargins.top - textBoxHeight}
                  width={100}
                  height={25}
                  rotation={0}
                  text={'10cm'}
                  fill={'blue'}
                />
                {/*<TextBox*/}
                {/*  x={localMargins.left}*/}
                {/*  y={localMargins.top - textBoxHeight}*/}
                {/*  width={width}*/}
                {/*  height={25}*/}
                {/*  rotation={0}*/}
                {/*  text={'50cm'}*/}
                {/*  fill={'blue'}*/}
                {/*/>*/}
                <TextBox
                  x={localMargins.left + width}
                  y={localMargins.top - textBoxHeight}
                  width={100}
                  height={25}
                  rotation={0}
                  text={'10cm'}
                  fill={'blue'}
                />
                <TextBox
                  x={localMargins.left + width - 50 + textBoxHeightHalf}
                  y={localMargins.top + 170 + 65}
                  width={170}
                  height={30}
                  rotation={90}
                  text={'Tragegriff 3x20cm'}
                  fill={'purple'}
                />
              </Group>
              <Rect
                x={localMargins.left}
                y={localMargins.top - 100}
                width={width}
                height={100}
                dash={[4, 4]}
                stroke={'blue'}
                strokeWidth={1}
                listening={false}
              />
              <Rect
                x={localMargins.left}
                y={localMargins.top + height}
                width={width}
                height={100}
                dash={[4, 4]}
                stroke={'blue'}
                strokeWidth={1}
                listening={false}
              />
              <Rect
                x={localMargins.left - 100}
                y={localMargins.top}
                width={100}
                height={height}
                dash={[4, 4]}
                stroke={'blue'}
                strokeWidth={1}
                listening={false}
              />
              <Rect
                x={localMargins.left + width}
                y={localMargins.top}
                width={100}
                height={height}
                dash={[4, 4]}
                stroke={'blue'}
                strokeWidth={1}
                listening={false}
              />
              <Rect
                x={localMargins.left}
                y={localMargins.top - height - 100}
                width={width}
                height={height}
                dash={[4, 4]}
                stroke={'green'}
                strokeWidth={1}
                listening={false}
              />
              {/*<Rect*/}
              {/*  x={localMargins.left + width + (100 - 60) / 2}*/}
              {/*  y={localMargins.top + (height - 140) / 2}*/}
              {/*  width={30}*/}
              {/*  height={140}*/}
              {/*  dash={[4, 4]}*/}
              {/*  stroke={'purple'}*/}
              {/*  strokeWidth={1}*/}
              {/*  listening={false}*/}
              {/*/>*/}
              <Rect
                x={localMargins.left + width + 100 - 30 - 35}
                y={localMargins.top + ((height - 200) / 2)}
                width={30}
                height={200}
                dash={[4, 4]}
                stroke={'purple'}
                strokeWidth={1}
                listening={false}
              />
            </>
          )}
          <Rect
            x={localMargins.left}
            y={localMargins.top}
            width={width}
            height={height}
            dash={[4, 4]}
            stroke={'green'}
            strokeWidth={1}
            listening={false}
          />
          {variantId === PRODUCT_VARIANT_FABRIC && (
            <DetailArea shapeProps={detailAreaProps} transform={isOver} onChange={handleOnChangeDetail}/>
          )}
          {isCustomizable && (
            <CustomArea shapeProps={customAreaProps} transform={isOver} onChange={handleOnChangeCustom}/>
          )}
        </Layer>
      </Stage>
    </div>
  );
};

export default DesignCanvas;
