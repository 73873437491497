import {
  LOGIN,
  requestProfile,
  receiveProfile,
  REQUEST_PROFILE,
  RECEIVE_PROFILE,
  UserActionTypes, setAccessToken, SET_ACCESS_TOKEN, logout, LOGOUT
} from "../reducer/user";
import {AnyAction, Dispatch, Middleware, MiddlewareAPI} from "redux";
import gql from 'graphql-tag';
import { client } from "api/hasura";
import {handleLogin} from "api/auth";
import jwtDecode from "jwt-decode";

const GET_PROFILE = gql`
  query getProfile($email: String!) {
    users(where: {email: {_eq: $email}}) {
      id
      email
      name
    }
  }
`;

const userMiddleware: Middleware<Dispatch> = ({ getState, dispatch }: MiddlewareAPI) => (next: Dispatch) => async (action: AnyAction | UserActionTypes) => {
  const result = next(action);
  switch (action.type) {
    case LOGIN:
      const token = await handleLogin(action.payload);
      dispatch(setAccessToken(token || ''));
      break;
    case LOGOUT:
      const actAccessToken = window.localStorage.getItem('actAccessToken');
      if (actAccessToken) {
        window.localStorage.removeItem('actAccessToken');
        window.localStorage.accessToken = actAccessToken;
        window.location.href = '/design/users';
      } else {
        window.localStorage.accessToken = ''; // TODO use redux or global variable to read accessToken
        window.location.href = '/login';
      }
      break;
    case SET_ACCESS_TOKEN:
      // setTimeout(() => {
        dispatch(requestProfile());
      // }, 50);
      break;
    case REQUEST_PROFILE:
      await (async () => {
        const { user } = getState();
        const token = jwtDecode(user.accessToken);
        try {
          const { data: { users: [{id, email, name}]} } = await client.query<any>({
            query: GET_PROFILE,
            variables: {
              email: token.email,
            }
          });
          dispatch(receiveProfile({
            ...user,
            id,
            email,
            name,
          }));
        } catch (e) {
          if (e.message.indexOf('JWTExpired') !== -1) {
            dispatch(logout());
          } else {
            throw e;
          }
        }
      })();
      break;
    case RECEIVE_PROFILE:
      console.log('RECEIVE_PROFILE');
      break;
  }
  return Promise.resolve(result);
};

export default userMiddleware;
