import React from 'react';
import { SimpleImg } from 'react-simple-img';
import overlays from './overlays';

export interface PreviewImageProps {
  overlay: string,
  designImage: string,
  designId: string,
  alt: string,
  rendered: boolean,
  renderedAt: string,
}

const PreviewImage: React.FC<PreviewImageProps> = ({overlay, designImage, designId, alt, rendered, renderedAt}) => {
  // console.log(rendered);
  // console.log(overlays, overlay)
  const overlayImage = (overlays as any)[`${overlay}.png`];
  // return (
  //   <div className='w-full max-w-xs relative'>
  //     <div className='aspect-ratio-square'>
  //       <div className='absolute inset-0 flex items-center justify-center overflow-hidden '>
  //         <SimpleImg
  //           className=''
  //           src={`https://storage.designer.textilwerk.io/products/${designId}/rendered/${overlay}.jpg`}
  //           alt={alt}
  //           importance={'low'}
  //           placeholder={false}
  //           // animationDuration={10}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // );
  if (rendered) {
    return (
      <SimpleImg
        className='bg-cover absolute'
        src={`${process.env.REACT_APP_STORAGE_PREFIX}/products/${designId}/rendered/${overlay}.320.jpg?renderedAt=${encodeURIComponent(renderedAt)}`}
        alt={alt}
        placeholder={false}
      />
    );
  }

  const backgroundStyle = {
    backgroundImage: `url('${process.env.REACT_APP_STORAGE_PREFIX}/designs/${designImage}/thumbnails/320.square.jpg')`,
  };

  return (
      <SimpleImg
        className='bg-cover opacity-25 absolute'
        style={backgroundStyle}
        src={overlayImage}
        alt={alt}
        placeholder={false}
      />
  );
  // return (
  //   <Img className='bg-cover opacity-25' src={overlayImage} alt={alt} style={{background: `url('https://storage.designer.textilwerk.io/designs/${designImage}/thumbnails/320.square.jpg')`}}/>
  // );
};

PreviewImage.defaultProps = {
  rendered: false,
};

export default PreviewImage;
