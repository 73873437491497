import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import ReduxStore from '@uppy/store-redux';
import German from '@uppy/locales/lib/de_DE';
import store from 'store/store/main';

// export const REACT_APP_API_UPPY_TUSD_ENDPOINT = 'https://storage.designer.textilwerk.io/upload';
// export const REACT_APP_API_UPPY_TUSD_ENDPOINT = 'http://localhost:31110/upload';

const uppy = Uppy({
  autoProceed: true,
  debug: true,
  locale: German,
  restrictions: {
    maxFileSize: 536870912,
    allowedFileTypes: ['image/jpeg', 'image/tiff', 'image/png', 'image/svg+xml', 'application/pdf'],
    maxNumberOfFiles: 100,
  },
  meta: {
    collection: 'design',
  },
  store: ReduxStore({
    store,
    id: 'design',
  })
});

uppy.use(Tus, { endpoint: process.env.REACT_APP_API_UPPY_TUSD_ENDPOINT, limit: 4 });

export default uppy;
