import React from 'react';

import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FieldProps, useField, FieldInputProps,
} from 'formik';
import * as Yup from 'yup';
import {DesignPropertiesQueryResult, DesignPropertyReference} from "../../containers/Design/queries";
import RequireRole, {DESIGN_MANAGER} from "../RequireRole/RequireRole";
import PropertySelect from "./PropertySelect";
import OyButton from "@oyoyo/ui/src/components/OyButton/OyButton";
import { useToasts } from 'react-toast-notifications';
import {useNavigation} from "react-navi";
import TagSelect from "./TagSelect";

export interface DesignPropertiesFormValues {
  name: string,
  description: string,
  isCustomizable?: boolean,
  defaultText?: string,
  groupName?: string,
  themes: number[],
  subThemes: Record<string, number[]>,
  styles: number[],
  colors: number[],
  tags: number[],
}

export interface DesignPropertiesFormProps {
  data: DesignPropertiesQueryResult,
  handler: (values: DesignPropertiesFormValues) => void,
}

export const DesignPropertiesFormSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(1, 'Dieser Titel ist zu kurz')
    .max(30, 'Dieser Titel ist zu lang')
    .test('imageExtension', 'Der Titel sollte keine Dateiendungen wie z.B. ".jpg" enthalten', (value) => {
      return ! /\.(jpe?g|png|pdf|tiff|svg)/.test(value.toLowerCase());
    })
    .test('invalidCharacters', 'Der Titel darf die folgenden Zeichen nicht enthalten: _ , .', (value) => {
      return ! /[_,.]/.test(value);
    })
    .required('Bitte einen Titel angeben'),
  description: Yup.string()
    .trim()
    // .min(10, 'Diese Beschreibung ist zu kurz')
    .max(255, 'Diese Beschreibung ist zu lang'),
    // .required('Bitte eine Beschreibung angeben'),
  themes: Yup.array()
    .min(1, 'Mindestens ein Thema wählen')
    .max(3, 'Maximal drei Themen wählen')
    .required('Bitte mindestens ein Thema wählen'),
  subThemes: Yup.object(),
  styles: Yup.array()
    // .min(1, 'Mindestens einen Stil wählen')
    .max(2, 'Maximal zwei Stile wählen'),
    // .required('Bitte mindestens einen Stil wählen'),
  colors: Yup.array()
    .min(1, 'Mindestens eine Hauptfarbe wählen')
    .max(2, 'Maximal zwei Hauptfarben wählen')
    .required('Bitte mindestens eine Hauptfarbe wählen'),
  tags: Yup.array()
    .max(10, 'Maximal 10 Tags wählen'),
  isCustomizable: Yup.boolean(),
  groupName: Yup.string()
    .trim()
    .min(1, 'Dieser Text ist zu kurz')
    .max(30, 'Dieser Text ist zu lang'),
  defaultText: Yup.string()
    .trim()
    .min(1, 'Dieser Text ist zu kurz')
    .max(30, 'Dieser Text ist zu lang'),
});

const DesignPropertiesForm: React.FC<DesignPropertiesFormProps> = ({data: {design, themes, styles, colors, tags}, handler}) => {
  const { addToast } = useToasts();
  const navigation = useNavigation();
  const selectedSubThemeIds = design.subThemes.map(({id}) => id);
  const subThemes = themes.reduce((acc, cur) => {
    const themeSubThemeIds = (cur as DesignPropertyReference & { subThemes: DesignPropertyReference[] }).subThemes.map(({id}) => id)
    acc[cur.id] = selectedSubThemeIds.filter(id => themeSubThemeIds.includes(id))
    return acc
  }, {} as Record<string, number[]>);
  const initialValues: DesignPropertiesFormValues = { name: design.name, description: design.description ?? '', isCustomizable: design.isCustomizable, defaultText: design.defaultText ?? '', groupName: design.groupName ?? '', themes: design.themes.map(item => item.id), subThemes, styles: design.styles.map(item => item.id), colors: design.colors.map(item => item.id), tags: design.tags.map(item => item.id) };
  // console.log({design, initialValues, themes, subThemes})
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={DesignPropertiesFormSchema}
        onSubmit={async (values, {setSubmitting, setFieldError}) => {
          // console.log(values); return
          await handler(values);
          setSubmitting(false);
          addToast('Einstellungen gespeichert', { appearance: 'success', autoDismiss: true });
          navigation.navigate(`/design/products/${design.id}/overview`);
        }}
        render={({isSubmitting, errors, touched, values}) => (
          <Form translate="yes" className='grid grid-columns-1 grid-gap-4'>
            <label>
              <div className='font-medium mb-2'>Titel</div>
              <Field className='appearance-none border border-gray-400 hover:border-gray-500 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none' name={'name'} type={'input'}/>
            </label>
            {touched.name && errors.name ? (
              <div className="error text-red-500 text-sm">{errors.name}</div>
            ) : null}
            <label>
              <div className='font-medium mb-2'>Beschreibung</div>
              <Field className='appearance-none border border-gray-400 hover:border-gray-500 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none' name={'description'} component={'textarea'}/>
            </label>
            {touched.description && errors.description ? (
              <div className="error text-red-500 text-sm">{errors.description}</div>
            ) : null}
            {/*<Field*/}
            {/*  name="description"*/}
            {/*  render={({ field, form, meta }) => (*/}
            {/*    <div>*/}
            {/*      <input type="text" {...field} placeholder="Beschreibung" />*/}
            {/*      {meta.touched && meta.error && meta.error}*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*/>*/}
            <PropertySelect label={'Thema wählen'} name={'themes'} options={themes.map(({id, name}) => ({ value: id, label: name }))} />
            {values.themes.length > 0 && (
              <div className='ml-12'>
                {values.themes.map((themeId) => {
                  const theme = themes.find(({id}) => id === themeId) as DesignPropertyReference & { subThemes: DesignPropertyReference[] };
                  return (
                    <div key={themeId} className='mt-2'>
                      <PropertySelect label={`Unterthemen für ${theme?.name} wählen`} name={`subThemes[${themeId}]`} options={theme.subThemes.map(({id, name}) => ({ value: id, label: name }))} />
                    </div>
                  )
                })}
              </div>
            )}
            <PropertySelect label={'Stil wählen'} name={'styles'} options={styles.map(({id, name}) => ({ value: id, label: name }))} />
            <PropertySelect label={'Hauptfarben wählen'} name={'colors'} options={colors.map(({id, name}) => ({ value: id, label: name }))} />
            <TagSelect label={'Tags wählen'} name={'tags'} options={tags.map(({id, name}) => ({ value: id, label: name }))} />
            <RequireRole role={DESIGN_MANAGER}>
              <div>
                <label>
                  <div className='font-medium mb-2'>Personalisierbar?</div>
                  <Field className='focus:outline-none' name={'isCustomizable'} type={'checkbox'}/>
                </label>
                {values.isCustomizable && (
                  <>
                    <label>
                      <div className='font-medium mb-2'>Gruppen-Name</div>
                      <Field className='appearance-none border border-gray-400 hover:border-gray-500 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none' name={'groupName'} type={'input'}/>
                    </label>
                    <label>
                      <div className='font-medium mb-2'>Standard-Text</div>
                      <Field className='appearance-none border border-gray-400 hover:border-gray-500 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none' name={'defaultText'} type={'input'}/>
                    </label>
                  </>
                )}
              </div>
            </RequireRole>
            <div>
              <OyButton type={'submit'} disabled={isSubmitting} label={'Eigenschaften speichern'}/>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

DesignPropertiesForm.defaultProps = {
};

export default DesignPropertiesForm;
