import React, {useCallback} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import jwtDecode from "jwt-decode";
import {UserState, login, logout} from "store/reducer/user";
import {AppState} from "../../store/reducer/root";
import {Link} from "react-navi";
import {ReactComponent as IconUser} from "line-awesome/svg/user.svg";

import './test.css';
import clsx from "clsx";

export interface UserContainerProps {
}

const UserContainer: React.FC<UserContainerProps> = () => {
  // const [connected, setConnected] = useState<boolean>(false);
  const { accessToken, email }: UserState = useSelector((state: AppState) => state.user, shallowEqual);
  const token = jwtDecode(accessToken);
  console.warn(token);
  // const dispatch = useDispatch();
  // const doLogin = useCallback(
  //   () => dispatch(login('hello@oyoyo.io', 'top-secret-123')),
  //   [dispatch]
  // );
  return (
    <div className='hover:bg-gray-200 rounded-full p-1 focus:outline-none relative menu transition duration-200'>
      <IconUser className={clsx('w-8 h-8 fill-current', { 'text-orange-500': token.act })}/>
      {/**/}
      {/*<button onClick={doLogin}>Login</button>*/}
      <ul className='absolute right-0 flex flex-col z-50 bg-white opacity-0 pointer-events-none submenu break-normal whitespace-no-wrap shadow-lg border border-gray-200'>
        {email !== '' ? (
          <>
            <li className='p-2 border-b border-gray-200'>
              <div>Angemeldet als</div>
              <div className='font-medium'>{email}</div>
            </li>
            <li className='p-2 hover:bg-gray-200 transition duration-200'><Link className='block' activeClassName='text-red-500' href='/logout' exact>{ token.act ? 'Zurück' : 'Abmelden' }</Link></li>
          </>
        ) : (
          <li className='p-2 hover:bg-gray-200 transition duration-200'><Link className='block' activeClassName='text-red-500' href='/login' exact>Anmelden</Link></li>
        )}
      </ul>
    </div>
  );
};

export default UserContainer;
