import React, {useState} from 'react';
import {useQuery, useSubscription} from "@apollo/client";
import {UPLOADS_QUERY, UploadsQuery} from "./subscriptions";
import { SimpleImg } from 'react-simple-img';
import {ReactComponent as IconNext} from "feather-icons/dist/icons/chevron-right.svg"
import {ReactComponent as IconPrev} from "feather-icons/dist/icons/chevron-left.svg"

// TODO https://css-tricks.com/building-an-images-gallery-using-pixijs-and-webgl/ https://github.com/lmgonzalves/images-gallery

export interface UploadsOverlayContainerProps {
  onClick: (imageId: number) => void,
}

const UploadsOverlayContainer: React.FC<UploadsOverlayContainerProps> = ({onClick}) => {
  const pageSize = 6;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {data, error, loading} = useQuery<UploadsQuery>(
    UPLOADS_QUERY,
    {
      variables: {limit: pageSize, offset: (currentPage - 1) * pageSize || 0},
      fetchPolicy: 'cache-and-network',
    }
  );
  const lastPage = Math.ceil((data?.uploadsInfo.aggregate.count || 1) / pageSize)
  const previousPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
  const nextPage = () => setCurrentPage(currentPage < lastPage ? currentPage + 1 : lastPage);
  const handleClick = (imageId: number) => () => {
    onClick(imageId);
  };
  return (
    <div className='fixed bottom-0 left-0 w-full flex items-center justify-center'>
      <div className='bg-gray-300 w-1/2 mx-auto p-4 grid grid-columns-8 grid-gap-4'>
        <button className='flex items-center justify-center' onClick={previousPage}><IconPrev className='w-8 h-8'/></button>
        {(!loading && data) && data.uploads.map((({id, path, filename, filesize, mimetype, width, height}) => (
          <div className='aspect-ratio-square relative bg-white' key={id}>
            <button className='absolute inset-0' onClick={handleClick(id)}>
              <SimpleImg src={`${process.env.REACT_APP_STORAGE_PREFIX}/designs/${path}/thumbnails/320.square.jpg`} placeholder={false}/>
            </button>
          </div>
        )))}
        <button className='flex items-center justify-center' onClick={nextPage}><IconNext className='w-8 h-8'/></button>
      </div>
    </div>
  );
};

// UploadsContainer.defaultProps = {
// };

export default UploadsOverlayContainer;
