import React, {useEffect} from 'react';
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";
import {useNavigation} from "react-navi";
import { useToasts } from 'react-toast-notifications';

export interface ResetPasswordProps {
  token: string,
}

const reset = async (token: string) => {
  return fetch(`${process.env.REACT_APP_API_NESTJS}/auth/reset-password`, {
    method: 'PATCH',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Reset password failed')
      }
      return response.json()
    })
};

const ResetPassword: React.FC<ResetPasswordProps> = React.memo(({token}) => {
  const navigation = useNavigation();
  const { addToast } = useToasts();

  useEffect(() => {
    reset(token)
      .then(() => {
        addToast('Ein neues Passwort wurde via E-Mail verschickt.', { appearance: 'success', autoDismiss: true });
        navigation.navigate(`/login`);
      })
      .catch(() => {
        addToast('Fehler beim Zurücksetzen des Passworts.', { appearance: 'error', autoDismiss: true });
        navigation.navigate(`/forgot-password`);
      })
    ;
  }, [token]);

  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-04 bg-cover bg-fixed flex items-center justify-center'}>
      {/*<ResetPasswordForm/>*/}
    </OyContainer>
  );
});

// ResetPassword.defaultProps = {
// };

export default ResetPassword;
