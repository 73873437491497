import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {logout, UserState} from "../../store/reducer/user";
import {AppState} from "../../store/reducer/root";

export interface LogoutContainerProps {
}

const LogoutContainer: React.FC<LogoutContainerProps> = () => {
  // const { email }: UserState = useSelector((state: AppState) => state.user, shallowEqual);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
  }, []);
  return (
    <div/>
  );
};

export default LogoutContainer;
