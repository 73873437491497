import React from 'react';
import DesignPropertiesContainer from "../containers/Design/DesignPropertiesContainer";
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";

export interface ProductPropertiesProps {
  id: number,
}

const ProductProperties: React.FC<ProductPropertiesProps> = ({id}) => {
  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-01 bg-cover bg-fixed'}>
      <DesignPropertiesContainer id={id}/>
    </OyContainer>
  );
};

// ProductProperties.defaultProps = {
// };

export default ProductProperties;
