import React, {MouseEvent} from 'react';
import {Link, useNavigation} from "react-navi";
import clsx from 'clsx';
import {ReactComponent as IconAlert} from "feather-icons/dist/icons/alert-triangle.svg"
import {ReactComponent as IconEdit} from "line-awesome/svg/pen-solid.svg";
import {ReactComponent as IconDisabled} from "line-awesome/svg/eye-slash.svg";
import {ReactComponent as IconEnabled} from "line-awesome/svg/eye.svg";
import {toggleConfiguration} from "../../store/reducer/design";
import {useDispatch} from "react-redux";
// import overlays from './overlays';
import PreviewImage from "./PreviewImage";
// import OyButton from "@oyoyo/ui/src/components/OyButton/OyButton";
import {getCoverRatio} from "@oyoyo/shared/src/utils/design";
// import {useToggle} from "react-use";

export interface ProductListItemProps {
  designId: number,
  designUuid: string,
  designConfiguration: any,
}

const ProductListItem: React.FC<ProductListItemProps> = ({designId, designUuid, designConfiguration}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const editUrl = `/design/products/${designId}/edit/${designConfiguration.id}`;
  const edit = () => navigation.navigate(editUrl);
  const toggle = (event: MouseEvent) => {
    event.stopPropagation();
    dispatch(toggleConfiguration(designConfiguration.id, !designConfiguration.active, designConfiguration.designId));
  };
  const IconToggle = designConfiguration.active ? IconDisabled : IconEnabled;
  // console.log(designConfiguration.productVariant.productScenes);
  // const overlay = (overlays as any)[`${designConfiguration.productVariant.productScenes[0].productScene.name}.png`];
  // console.log(overlay);
  // const designUuid = '0c8858a0-bf37-4e2a-96f5-8d7a7444c75e'; // 21fa2481-7f0c-414c-897c-e47bd5caa6b4
  const margins = {
    top: designConfiguration.productVariant.marginTop,
    right: designConfiguration.productVariant.marginRight,
    bottom: designConfiguration.productVariant.marginBottom,
    left: designConfiguration.productVariant.marginLeft,
  };
  const image = {
    widthPx: designConfiguration.frontSettings.image.width,
    heightPx: designConfiguration.frontSettings.image.height,
    isPattern: designConfiguration.frontSettings.image.isPattern,
  };
  const designTooSmall = getCoverRatio(designConfiguration.productVariant, margins, image) > 1;
  // const [collapsed, toggleCollapsed] = useToggle(true);
  // const replaceImage = () => (e: MouseEvent) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   console.log('yeah');
  // };
  const locked = designConfiguration.frontSettings.image.isPattern === false && designConfiguration.productVariant.product.category.id === 13; // disable activate button for fabric if image isn't a pattern
  return (
    <div
      id={`dc${designConfiguration.id}`}
      className={clsx('bg-white border-b-4 hover:shadow-lg cursor-pointer', {
        'border-green-500': designConfiguration.active,
        'border-red-500': !designConfiguration.active,
      })}
      style={{scrollMarginTop: '150px'}}
      onClick={edit}
    >
      <div className='p-4 break-all'>
        <div className={clsx('aspect-ratio-square relative', {'opacity-25': !(!!designConfiguration.renderedAt)})}>
          <PreviewImage key={`${designConfiguration.id}-${designConfiguration.renderedAt}`} rendered={!!designConfiguration.renderedAt} renderedAt={designConfiguration.renderedAt} overlay={designConfiguration.productVariant.productScenes[0].productScene.name} designId={designUuid} designImage={designConfiguration.frontSettings.image.path} alt={designConfiguration.productVariant.name}/>
          {designTooSmall && (
            <div className='flex flex-col items-center justify-center inset-0 absolute bg-red-alpha-600 text-white text-sm p-4 text-center break-normal leading-tight'>
              <IconAlert className='w-6 h-6'/>
              <div className='my-2'>Das hochgeladene Bild hat leider eine zu geringe Qualität.</div>
              {/*<OyButton onClick={replaceImage} size={'sm'} color={'transparent'} label={'Bild ersetzen'}/>*/}
            </div>
          )}
        </div>
        {/*<img src={overlay} alt={designConfiguration.productVariant.name} style={{background: `url('https://storage.designer.textilwerk.io/designs/${designConfiguration.frontSettings.image.path}/thumbnails/320.square.jpg')`, backgroundSize: 'cover'}}/>*/}
        {/*<img src={`https://storage.designer.textilwerk.io/designs/${designConfiguration.frontSettings.image.path}/thumbnails/320.square.jpg`} alt={designConfiguration.productVariant.name}/>*/}
        <div className='font-bold text-sm mt-2'>{designConfiguration.productVariant.product.name}</div>
        {/*<div className='font-bold text-sm mt-2'>{designConfiguration.productVariant.name}</div>*/}
        <div className='text-xs'>{designConfiguration.productVariant.width / 10}x{designConfiguration.productVariant.height / 10}cm</div>
      </div>
      {/*<pre>{JSON.stringify(designConfiguration, null, 2)}</pre>*/}
      <div className='border-dotted border-t-2 border-gray-400 flex items-center py-1 text-xs'>
        <Link className='flex flex-1 items-center justify-center' href={editUrl} exact><IconEdit className='w-6 h-6 mr-1'/>Bearbeiten</Link>
        <div className='border-dotted border-r-2 border-gray-400 h-4'/>
        <button className='flex flex-1 items-center justify-center disabled:text-gray-500 disabled:cursor-not-allowed' disabled={locked} onClick={toggle}><IconToggle className='w-6 h-6 mr-1'/>{designConfiguration.active ? 'Deaktivieren' : 'Aktivieren'}</button>
      </div>
    </div>
  );
};

ProductListItem.defaultProps = {
};

export default ProductListItem;
