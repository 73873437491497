import {PrintJobFilter} from "@oyoyo/ui/src/apps/production-service-platform/types/print-job";
import OyPagination from "@oyoyo/ui/src/components/OyPagination/OyPagination";
import React, {MouseEvent, useCallback, useState} from 'react';
import {useQuery, useSubscription} from "@apollo/client";
import DesignFilterForm from "../../components/DesignFilterForm/DesignFilterForm";
import DesignPropertiesForm from "../../components/DesignPropertiesForm/DesignPropertiesForm";
import {AppState} from "../../store/reducer/root";
import {UserState} from "../../store/reducer/user";
import {resetFilter, updateFilter} from "./actions";
import {DESIGNS_QUERY, DesignsQuery} from "./queries";
import {DESIGNS_SUBSCRIPTION, DesignsSubscription} from "./subscriptions";
import {Link, useNavigation} from "react-navi";
import {SimpleImg} from "react-simple-img";
import { format, parseISO } from 'date-fns';
import {ReactComponent as IconActions} from "line-awesome/svg/ellipsis-v-solid.svg";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { motion } from "framer-motion";
import {deleteDesign, publishDesign, renderDesign, toggleDesign} from "../../store/reducer/design";
import RequireRole, {DESIGN_MANAGER, hasRole} from "../../components/RequireRole/RequireRole";
import clsx from "clsx";
import {useConfirm} from "../../components/Confirm/useConfirm";
import {DesignFilterState} from "./types";
import { useToasts } from 'react-toast-notifications';

const container = {
  // hidden: { opacity: 1 },
  visible: {
    // opacity: 1,
    transition: {
      // delay: 0.2,
      when: "beforeChildren",
      staggerChildren: 0.05
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

const download = (url: string) => (event: MouseEvent) => {
  event.preventDefault();
  window.open(url, '_blank');
};

export interface DesignsContainerProps {
}

// export interface DesignsFilter {
//   active?: boolean,
//   name?: string,
//   themes?: number[],
//   styles?: number[],
//   colors?: number[],
//   designer?: string,
//   uuid?: string,
// }

const getQueryWhere = (filter: DesignFilterState, isManager = false) => {
  const where: any = {
    deletedAt: {_is_null: true},
  };
  if (filter.active) {
    where.isActive = {'_eq': true}
  } else {
    where.isActive = {'_eq': false}
  }
  if (filter.name) {
    where.name = {'_ilike': `%${filter.name}%`}
  }
  if ((filter?.themes?.length || 0) > 0) {
    where.themes = {theme: {id: {_in: filter.themes}}}
  }
  if ((filter?.styles?.length || 0) > 0) {
    where.styles = {style: {id: {_in: filter.styles}}}
  }
  if ((filter?.colors?.length || 0) > 0) {
    where.colors = {color: {id: {_in: filter.colors}}}
  }
  if (filter.declined) {
    where.declineReason = {_is_null: false}
  }
  if (isManager) {
    if (filter.published) {
      where.publishedAt = {_is_null: false}
    } else {
      where.publishedAt = {_is_null: true}
    }
    if (filter.designer) {
      where.userId = {'_eq': filter.designer}
    }
  }
  // console.log(filter, where, filter.published, isManager)
  return where;
};

const DesignsContainer: React.FC<DesignsContainerProps> = () => {
  // const { data, loading, error } = useSubscription<DesignsSubscription>(
  //   DESIGNS_SUBSCRIPTION,
  //   { variables: { limit: 50, offset: 0 } }
  // );
  const { accessToken }: UserState = useSelector((state: AppState) => state.user, shallowEqual);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { addToast } = useToasts();

  const designFilter: DesignFilterState = useSelector((state: AppState) => state.designFilter, shallowEqual);
  const updateDesignFilter = useCallback(
    (state) => dispatch(updateFilter(state)),
    [dispatch]
  );
  const resetDesignFilter = useCallback(
    () => dispatch(resetFilter()),
    [dispatch]
  );

  // const [filterState, setFilterState] = useState<DesignsFilter>({
  //   active: true,
  // });
  // console.log(designFilter, getQueryWhere(designFilter))
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 50;
  const { data, loading, error, fetchMore } = useQuery<DesignsQuery>(
    DESIGNS_QUERY,
    { variables: { where: getQueryWhere(designFilter, hasRole(accessToken, DESIGN_MANAGER)), limit: pageSize, offset: (currentPage - 1) * pageSize || 0 }, fetchPolicy: 'cache-and-network' }
  );

  // const loadMore = async () => {
  //   await fetchMore({
  //     variables: {
  //       offset: data?.designs.length || 0
  //     },
  //     updateQuery: (prev, { fetchMoreResult }) => {
  //       if (!fetchMoreResult) return prev;
  //       return Object.assign({}, prev, {
  //         designs: [...prev.designs, ...fetchMoreResult.designs]
  //       });
  //     }
  //   })
  // };

  const lastPage = Math.ceil((data?.designsInfo.aggregate.count || 1) / pageSize)

  if (error) {
    return (
      <div>Ein Fehler ist aufgetreten :-(</div>
    );
  }
  if (loading && !data?.designs) {
    return (
      <div>Wird geladen...</div>
    );
  }

  const ignoreClick = (event: MouseEvent) => {
    event.preventDefault();
  };
  const publish = (id: number) => (event: MouseEvent) => {
    event.preventDefault();
    dispatch(publishDesign(id))
    addToast('Die Produkte werden nun veröffentlicht.', { appearance: 'success', autoDismiss: true });
  };
  const renderAll = (id: number) => (event: MouseEvent) => {
    event.preventDefault();
    dispatch(renderDesign(id))
  };
  const goToProperties = (id: number) => (event: MouseEvent) => {
    event.preventDefault();
    navigation.navigate(`/design/products/${id}/properties`);
  };
  const goToProductPreview = (id: number) => (event: MouseEvent) => {
    event.preventDefault();
    navigation.navigate(`/design/products/${id}/product-preview`);
  };
  const toggleProduct = (id: number, active: boolean) => (event: MouseEvent) => {
    event.preventDefault();
    dispatch(toggleDesign(id, active))
  };
  const confirmDeleteProduct = (id: number) => (event: MouseEvent) => {
    event.preventDefault();
    if (window.confirm(`Soll das Design wirklich gelöscht werden?`)) {
      dispatch(deleteDesign(id))
    }
  };
  // TODO status icon oder farbe ob freigegeben
  return (
    <div className='flex flex-col items-center'>
      <DesignFilterForm initialValues={designFilter} data={{
        themes: data?.themes || [],
        styles: data?.styles || [],
        colors: data?.colors || [],
        designers: data?.designers || [],
      }} handler={updateDesignFilter} reset={resetDesignFilter} />
      <OyPagination currentPage={currentPage} pagesTotal={lastPage} handleChange={({value}) => setCurrentPage(parseInt(value as string, 10))}/>
      <div className='flex flex-col w-full'>
        {data?.designs && data.designs.map(({id, uuid, name, image, updatedAt, isActive, declineReason, user, approved, unapproved}) => (
          <div className='break-all' key={id}>
            <Link className={clsx('grid grid-columns-12 grid-gap-4 items-center hover:bg-gray-100 p-4', {'text-red-500': !isActive}, {'bg-red-200': !!declineReason})} activeClassName='font-bold' href={`/design/products/${id}/overview`} exact>
              <SimpleImg className='col-span-1 w-12' src={`${process.env.REACT_APP_STORAGE_PREFIX}/designs/${image.path}/thumbnails/320.square.jpg`} alt={name} placeholder={false}/>
              <div className='col-span-7 leading-tight'><span className='font-medium'>{name}</span> <RequireRole role={DESIGN_MANAGER}><span className='text-sm'>von {user.name}</span></RequireRole><br/><span className='text-xs'>{uuid}</span></div>
              <div className='col-span-1 text-sm font-medium text-center'><span className='text-green-500'>{approved?.aggregate.count}</span> / <span className='text-red-500'>{unapproved?.aggregate.count}</span></div>
              <div className='col-span-3 flex justify-between items-center'>
                {format(parseISO(updatedAt), 'yyyy-MM-dd HH:mm')}
                <div onClick={ignoreClick} className='hover:bg-gray-200 p-1 rounded-full relative menu transition duration-200'>
                  <IconActions className='w-6 h-6'/>
                  <ul className='absolute right-0 flex flex-col z-50 bg-white opacity-0 pointer-events-none submenu break-normal whitespace-no-wrap shadow-lg border border-gray-200 text-black'>
                    <RequireRole role={DESIGN_MANAGER}>
                      <li className='hover:bg-gray-200 transition duration-200'><button className='w-full p-2 text-left' onClick={download(`${process.env.REACT_APP_STORAGE_PREFIX}/designs/${image.path}/design`)}>Design im Original herunterladen</button></li>
                      <li className='hover:bg-gray-200 transition duration-200'><button className='w-full p-2 text-left' onClick={publish(id)}>Produkte veröffentlichen</button></li>
                      <li className='hover:bg-gray-200 transition duration-200'><button className='w-full p-2 text-left' onClick={renderAll(id)}>Produkte neu rendern</button></li>
                    </RequireRole>
                    <li className='hover:bg-gray-200 transition duration-200'><button className='w-full p-2 text-left' onClick={goToProperties(id)}>Eigenschaften bearbeiten</button></li>
                    <li className='hover:bg-gray-200 transition duration-200'><button className='w-full p-2 text-left' onClick={goToProductPreview(id)}>Produktvorschau</button></li>
                    <li className={clsx('hover:bg-gray-200 transition duration-200', {'text-red-500': isActive}, {'text-green-500': !isActive})}><button className='w-full p-2 text-left' onClick={toggleProduct(id, !isActive)}>Produkt {isActive && 'de'}aktivieren</button></li>
                    <RequireRole role={DESIGN_MANAGER}>
                      <li className='hover:bg-gray-200 transition duration-200 text-red-500'><button className='w-full p-2 text-left' onClick={confirmDeleteProduct(id)}>Produkt löschen</button></li>
                    </RequireRole>
                  </ul>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <OyPagination currentPage={currentPage} pagesTotal={lastPage} handleChange={({value}) => setCurrentPage(parseInt(value as string, 10))}/>
    </div>
  );
};

// DesignsContainer.defaultProps = {
// };

export default DesignsContainer;
