import React from 'react';

import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FieldProps, useField, FieldInputProps,
} from 'formik';
import Select, { Option, CommonProps } from 'react-select';
// import { OptionsType, ValueType } from "react-select/lib/types";

// export interface PropertySelectProps extends Partial<FieldInputProps<string>>{
//   label: string,
//   name: string,
// }

export type PropertySelectProps = CommonProps & {
  name: string;
}

const PropertySelect: React.FC<PropertySelectProps> = ({label, name, options}) => {
  const [field, meta, helpers] = useField<number[]>(name);
  // console.log(name, field.value);
  // const onChange = (options: Option[]) => {
  //   console.log(options, options.map((item: Option) => item.value));
  //   helpers.setValue(options.map((item: Option) => item.value));
  // };
  return (
    <>
      <label className="flex-1">
        <div className='font-medium mb-2'>{label}</div>
        <Select
          options={options}
          name={field.name}
          // value={options ? options.find((option) => option.value === field.value) : ''}
          value={options ? options.filter(option => field.value.indexOf(option.value) >= 0) : []}
          // onChange={(option: Option): void => helpers.setValue(option.value)}
          // onChange={onChange}
          onChange={(options: Option[]): void => helpers.setValue(options ? options.map((item: Option) => item.value) : [])}
          onBlur={field.onBlur}
          isMulti={true}
          closeMenuOnSelect={false}
        />
      </label>
      {meta.touched && meta.error ? (
        <div className="error text-red-500 text-sm">{meta.error}</div>
      ) : null}
    </>
  );
};

export default PropertySelect;
