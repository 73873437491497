import React from 'react';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import OyButton from "@oyoyo/ui/src/components/OyButton/OyButton";
import Welcome from 'images/180817_login_willkommen_highres.png';
import { motion } from "framer-motion";
import {Link} from "react-navi";
import { useToasts } from 'react-toast-notifications';

export interface LoginFormValues {
  username: string;
  password: string;
}

export interface LoginFormProps {
  handler: (values: LoginFormValues) => void,
}

// https://grafana.com/static/img/docs/v66/whitelabeling_1.png

const LoginForm: React.FC<LoginFormProps> = ({handler}) => {
  const { addToast } = useToasts();
  const initialValues: LoginFormValues = {username: '', password: ''};
  return (
    <div className='flex flex-col items-center justify-center'>
      {/*<h1>Login</h1>*/}
      <motion.div animate={{ y: [50, 0], opacity: [0, 1] }} transition={{ ease: "easeIn", duration: 0.5 }} className="w-full max-w-xs">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 my-16">
          <img src={Welcome} alt='Welcome' className='w-full -mt-12 mb-4'/>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, {setSubmitting, setFieldError}) => {
              // console.log({ values, actions });
              try {
                await handler(values);
              } catch (e) {
                addToast('Login fehlgeschlagen', { appearance: 'error', autoDismiss: true });
              }
              // alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }}
          >
            {({isSubmitting}) => (
              <Form translate="yes">
                <div className="mb-4">
                  <label className="block">
                    <div className='text-gray-700 text-sm font-medium'>Benutzername</div>
                    <Field name={'username'} type={'input'} placeholder="Username"
                           className='appearance-none border border-gray-400 hover:border-gray-500 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none mt-2'/>
                  </label>
               </div>
                <div className="mb-6">
                  <label className="block">
                    <div className='text-gray-700 text-sm font-medium'>Passwort</div>
                    <Field name={'password'} type={'password'} placeholder="********"
                           className='appearance-none border border-gray-400 hover:border-gray-500 rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none mt-2'/>
                  </label>
                  {/*<p className="text-red-500 text-xs italic mt-2">Please choose a password.</p>*/}
                </div>
                <div className="flex items-center justify-between">
                  <OyButton type={'submit'} disabled={isSubmitting} label={'Anmelden'}/>
                  <Link className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="/forgot-password">
                    Passwort vergessen?
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </motion.div>
    </div>
  );
};

LoginForm.defaultProps = {};

export default LoginForm;
