import React from 'react';
import OyContainer from "@oyoyo/ui/src/components/OyContainer/OyContainer";

export interface FaqProps {
}

const Faq: React.FC<FaqProps> = () => {
  return (
    <OyContainer className='' wrapperClassName={'bg-gray-200 bg-svg-01 bg-cover bg-fixed'}>
      FAQs
    </OyContainer>
  );
};

// Faq.defaultProps = {
// };

export default Faq;
