import React, {useCallback} from 'react';
import DesignCanvas from "components/DesignCanvas/DesignCanvas";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import RequireRole, {DESIGN_MANAGER, hasRole} from "../../components/RequireRole/RequireRole";
import {UserState} from "../../store/reducer/user";
import {copyFrom, defaults, update, updateCustom, updateDetails} from './actions';
import DesignProduct from "@oyoyo/shared/src/types/DesignProduct";
import DesignImage from "@oyoyo/shared/src/types/DesignImage";
import {AppState} from "../../store/reducer/root";
import {pxToMm} from "@oyoyo/shared/src/utils/conversions";
import {DesignCanvasState} from "./types";
import OyButton from "@oyoyo/ui/src/components/OyButton/OyButton";

export interface DesignCanvasContainerProps {
}

const DesignCanvasContainer: React.FC<DesignCanvasContainerProps> = () => {
  const { accessToken }: UserState = useSelector((state: AppState) => state.user, shallowEqual);
  const isDesignManager = hasRole(accessToken, DESIGN_MANAGER)
  const design: any = useSelector((state: AppState) => state.design, shallowEqual);
  const designCanvas: DesignCanvasState = useSelector((state: AppState) => state.designCanvas, shallowEqual);
  const designSettings = designCanvas[designCanvas.side];
  const settings = designCanvas.side === 'front' ? design?.configuration?.frontSettings : design?.configuration?.backSettings;
  const dispatch = useDispatch();
  const updateDesignCanvas = useCallback(
    (state) => dispatch(update(state)),
    [dispatch]
  );
  const resetDesignCanvas = useCallback(
    (product, designImage) => dispatch(defaults(product, designImage)),
    [dispatch]
  );
  const copySettings = useCallback(
    () => dispatch(copyFrom(designCanvas.side === 'front' ? 'back' : 'front')),
    [dispatch, designCanvas.side]
  );
  const updateDesignCanvasDetails = useCallback(
    (state) => dispatch(updateDetails(state)),
    [dispatch]
  );
  const updateDesignCanvasCustom = useCallback(
    (state) => dispatch(updateCustom(state)),
    [dispatch]
  );
  const product: DesignProduct = {
    variantId: design?.configuration?.productVariant?.id,
    width: design?.configuration?.productVariant?.width ?? 100,
    height: design?.configuration?.productVariant?.height ?? 400,
    margins: {
      top: design?.configuration?.productVariant?.marginTop ?? 15,
      right: design?.configuration?.productVariant?.marginRight ?? 15,
      bottom: design?.configuration?.productVariant?.marginBottom ?? 20,
      left: design?.configuration?.productVariant?.marginLeft ?? 15
    },
    hasBackside: design?.configuration?.productVariant?.product?.hasBackside ?? false,
    isCustomizable: !!design?.configuration?.design?.isCustomizable && isDesignManager,
  };
  // console.warn(design, product);
  const designImage: DesignImage = {
    url: `${process.env.REACT_APP_STORAGE_PREFIX}/designs/${settings?.image?.path}/thumbnails/1920.jpg`,
    widthPx: settings?.image?.width ?? 0,
    heightPx: settings?.image?.height ?? 0,
    isPattern: settings?.image?.isPattern ?? false,
    // thumbWidthPx: 640,
    // thumbHeightPx: 905,
  };
  // const designSettingsX: DesignProductSettings = {
  //   scale: 109,
  //   rotation: 270,
  //   top: 0.30657894736842,
  //   left: 0.51381578947368,
  //   backgroundColor: '#46B072',
  // };
  // designSettings.scale = 90;
  // designSettings.rotation = 0;
  // designSettings.top = 0;
  // designSettings.left = 0;
  return (
    <div>
      <DesignCanvas product={product} design={designImage} settings={designSettings} detail={designCanvas.detail} custom={designCanvas.custom} onChange={updateDesignCanvas} onDetailChange={updateDesignCanvasDetails} onCustomChange={updateDesignCanvasCustom}/>
      {/*<button onClick={() => updateDesignCanvas({*/}
      {/*  left: 0.6,*/}
      {/*  top: 0.61,*/}
      {/*})}>external set</button>*/}
      {/*<button onClick={() => updateDesignCanvas({*/}
      {/*  left: 0,*/}
      {/*  top: 0,*/}
      {/*})}>0,0</button>*/}
      {/*<button onClick={() => updateDesignCanvas({*/}
      {/*  left: 1,*/}
      {/*  top: 1,*/}
      {/*})}>1,1</button>*/}
      <OyButton className='mt-4 mr-4' color='gray' size={'sm'} label={'Auf Werkseinstellungen zurücksetzen'} onClick={() => resetDesignCanvas(product, designImage)}/>
      {product.hasBackside && <OyButton className='mt-4 mr-4' color='green' size={'sm'} label={designCanvas.side === 'front' ? 'Von Rückseite übernehmen' : 'Von Vorderseite übernehmen'} onClick={() => copySettings()} disabled={designCanvas.locked}/>}
      {/*<button onClick={() => resetDesignCanvas(product, designImage)}>Auf Werkseinstellungen zurücksetzen</button>*/}
      {/*<input type={'number'} min={1} max={120} value={designSettings.scale} onChange={(event) => { updateDesignCanvas({*/}
      {/*  scale: parseInt(event.target.value)*/}
      {/*}) }}/>*/}
      {/*<div>*/}
      {/*  <div>{product.width}x{product.height}</div>*/}
      {/*  <div>{designImage.widthPx}x{designImage.heightPx}</div>*/}
      {/*  <div>real size (mm): {pxToMm(designImage.widthPx)}x{pxToMm(designImage.heightPx)}</div>*/}
      {/*  <div>scaled size (mm): {pxToMm(designImage.widthPx / 100 * designSettings.scale)}x{pxToMm(designImage.heightPx / 100 * designSettings.scale)}</div>*/}
      {/*</div>*/}
    </div>
  );
};

export default DesignCanvasContainer;
